/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Case, Switch } from "react-if";
import styled from "styled-components";
import { BrickLoader, theme } from "@nordcloud/gnui";
import { EventAction } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { isEmpty, isNotEmpty } from "~/tools";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { ResourceGroupsStates } from "~/views/events/EventsDetails/types";
import { LoaderWrap } from "../Loader";
import { NoData } from "../NoData";
import { EventResourceGroupItem } from "./EventResourceGroupItem";

type Props = {
  states?: ResourceGroupsStates;
  eventAction: EventAction;
};

export function EventResourceGroupSection({ states, eventAction }: Props) {
  const { event } = useEvent();
  const { id: eventId } = event;

  const resourcesIds = states && Object.keys(states);

  const eventActionEndTime = eventAction?.globalState?.endTime
    ? dayjs(eventAction?.globalState?.endTime).format(
        dateFormat.shortDateWithTime
      )
    : undefined;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Wrap>
      <Switch>
        <Case condition={isNotEmpty(resourcesIds ?? [])}>
          {resourcesIds?.map((resource) => {
            const resourceGroupStatuses =
              states && states[resource].map((group) => group.status);
            return (
              <EventResourceGroupItem
                key={resource}
                resourceGroupStatuses={resourceGroupStatuses}
                states={states ? states[resource] : []}
                eventId={eventId}
                {...{ eventActionEndTime }}
              />
            );
          })}
        </Case>
        <Case condition={eventAction.resourceGroups?.length === 0}>
          <NoData
            hasIcon
            customCss={{ flexDirection: "column" }}
            message="There are no resources available for this action"
          />
        </Case>
        <Case condition={isEmpty(resourcesIds ?? [])}>
          <LoaderWrap
            loading={loading}
            Component={() => <BrickLoader height="14rem" />}
          >
            <NoData
              hasIcon
              customCss={{ flexDirection: "column", minHeight: "18.2rem" }}
              message="Resource statuses will be available when this action begins. Please wait."
            />
          </LoaderWrap>
        </Case>
      </Switch>
    </Wrap>
  );
}

const Wrap = styled.div`
  & > :not(:first-child) {
    margin-top: ${theme.spacing.spacing04};
  }
`;
