/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Unless, When } from "react-if";
import { ExtendedTooltip, FlexContainer } from "@nordcloud/gnui";
import { isDev } from "~/config";
import { AddAction } from "./AddAction";
import { AddActionBatch } from "./AddActionBatch";
import { ExecutionMethod } from "./types";

type Props = {
  severity?: "low";
};

const tooltipCaption = {
  single:
    "Add a single action to your plan. The action will start after the previous one finishes on all resources.",
  batch:
    "Create a batch of multiple actions to be executed simultaneously on each resource. The action in the batch will start after the previous one finishes on a resource.",
};

export function AddActionWrapper({ severity }: Props) {
  return (
    <>
      <When condition={isDev}>
        <FlexContainer>
          <Row justify="center">
            <Col align="center">
              <ExtendedTooltip caption={tooltipCaption.single}>
                <AddActionBatch
                  buttonSeverity={severity}
                  executionMethod={ExecutionMethod.single}
                />
              </ExtendedTooltip>
            </Col>
            <Col align="center">
              <ExtendedTooltip caption={tooltipCaption.batch}>
                <AddActionBatch
                  buttonSeverity={severity}
                  executionMethod={ExecutionMethod.batch}
                />
              </ExtendedTooltip>
            </Col>
          </Row>
        </FlexContainer>
      </When>
      <Unless condition={isDev}>
        <AddAction buttonName="Add First Action" />
      </Unless>
    </>
  );
}
