/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import { ResourceExecutionStatus } from "~/generated/graphql";
import { IconName } from "~/types/IconName";
import { TooltipColor } from "~/types/TooltipColor";

type Status = {
  description: string;
  icon: IconName;
  color: () => string;
  tooltipColor: TooltipColor;
};

const failedResourceStatus: Status = {
  description: "Failed",
  icon: "cancelFilled",
  color: () => `${theme.color.background.error}`,
  tooltipColor: TooltipColor.danger,
};

export function getResourceStatus(states?: ResourceExecutionStatus[]): Status {
  switch (true) {
    case states?.every((i) => i === ResourceExecutionStatus.Error):
      return {
        description: "Error",
        icon: "errorFilled",
        color: () => `${theme.color.background.error}`,
        tooltipColor: TooltipColor.danger,
      };
    case states?.every((i) => i === ResourceExecutionStatus.Failed):
      return failedResourceStatus;
    case states?.includes(ResourceExecutionStatus.InProgress):
      return {
        description: "In Progress",
        icon: "inProgressFilled",
        color: () => `${theme.color.background.info}`,
        tooltipColor: TooltipColor.notification,
      };
    case states?.every((i) => i === ResourceExecutionStatus.NotInTimeWindow):
      return {
        description: "Not in the Window",
        icon: "timeRangeFilled",
        color: () => `${theme.color.background.warning}`,
        tooltipColor: TooltipColor.warning,
      };
    case states?.includes(ResourceExecutionStatus.Pending):
      return {
        description: "Pending",
        icon: "pendingFilled",
        color: () => `${theme.color.background.warning}`,
        tooltipColor: TooltipColor.warning,
      };
    case states?.every((i) => i === ResourceExecutionStatus.Success):
      return {
        description: "Successful",
        icon: "successFilled",
        color: () => `${theme.color.background.success}`,
        tooltipColor: TooltipColor.success,
      };
    case states?.every(
      (i) =>
        i === ResourceExecutionStatus.Success ||
        i === ResourceExecutionStatus.ManualSuccess
    ):
      return {
        description: "Manually Successful",
        icon: "successFilled",
        color: () => `${theme.color.background.success}`,
        tooltipColor: TooltipColor.success,
      };
    case states?.every((i) => i === ResourceExecutionStatus.Timeout):
      return {
        description: "Timeout",
        icon: "timeOutFilled",
        color: () => `${theme.color.background.warning}`,
        tooltipColor: TooltipColor.warning,
      };
    case states?.every((i) =>
      [
        ResourceExecutionStatus.Error,
        ResourceExecutionStatus.Failed,
        ResourceExecutionStatus.NotInTimeWindow,
        ResourceExecutionStatus.Timeout,
      ].includes(i)
    ):
      return failedResourceStatus;
    default:
      return {
        description: "Partially Successful",
        icon: "warningFilled",
        color: () => `${theme.color.background.warning}`,
        tooltipColor: TooltipColor.warning,
      };
  }
}
