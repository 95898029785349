/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { EventAction, EventActionAttempt } from "~/generated/graphql";
import { createCtx } from "~/tools";

type Props = {
  eventAction?: EventAction;
  attempt?: EventActionAttempt;
  setEventAction?: (eventAction: EventAction) => void;
  setAttempt?: (attempt: EventActionAttempt) => void;
};

export const [useEventDetailsTab, EventDetailsTabCtxProvider] =
  createCtx<Props>();
