/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { LazyQueryExecFunction } from "@apollo/client";
import { Row, createColumnHelper } from "@tanstack/react-table";
import { Text, theme } from "@nordcloud/gnui";
import {
  EventActionFragment,
  Exact,
  GetEventQuery,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { MediumIconColumn, SmallIconColumn } from "~/components/Tables/styles";
import { EventActionStatus } from "./EvenActionStatus";
import { getResourceStatusesCount, getTotalResourceCount } from "./utils";

const columnHelper = createColumnHelper<EventActionFragment>();
export function eventActionsColumns({
  refreshEvent,
}: {
  refreshEvent: LazyQueryExecFunction<
    GetEventQuery,
    Exact<{
      id: string;
    }>
  >;
}) {
  const resourcesCount = (row: Row<EventActionFragment>) => {
    return (
      getResourceStatusesCount(row.original.resourceStates ?? [], [
        ...Object.values(ResourceExecutionStatus),
      ]) ??
      getTotalResourceCount(row.original.resourceGroups) ??
      "-"
    );
  };

  return [
    columnHelper.accessor(() => null, {
      header: "",
      id: "dummy",
      meta: SmallIconColumn,
    }),
    columnHelper.accessor(() => null, {
      header: "",
      id: "state",
      cell: ({ row }) => {
        return (
          <EventActionStatus
            eventActionId={row.original.id}
            eventActionStatus={row.original.globalState?.status}
            onEventActionStatusChanged={refreshEvent}
          />
        );
      },
      meta: MediumIconColumn,
    }),
    columnHelper.accessor(() => null, {
      header: "Action Name",
      cell: ({ row }) => {
        return <div>{row.original.name}</div>;
      },
      meta: {
        css: {
          width: "100%",
          minWidth: "20rem",
          padding: theme.spacing.spacing00,
        },
      },
    }),
    columnHelper.accessor(() => null, {
      header: "Number of resources",
      cell: ({ row }) => {
        return <div>{resourcesCount(row)}</div>;
      },
      meta: { css: { minWidth: "8.75rem", padding: theme.spacing.spacing00 } },
    }),
    columnHelper.accessor(() => null, {
      header: "Successful",
      cell: ({ row }) => {
        return (
          <Text color="success" mb={0}>
            {getResourceStatusesCount(row.original.resourceStates, [
              ResourceExecutionStatus.Success,
              ResourceExecutionStatus.ManualSuccess,
            ]) ?? "-"}
          </Text>
        );
      },
      meta: { css: { minWidth: "8.75rem", padding: theme.spacing.spacing00 } },
    }),
    columnHelper.accessor(() => null, {
      header: "Unsuccessful",
      cell: ({ row }) => {
        const isInProgress =
          getResourceStatusesCount(row.original.resourceStates, [
            ResourceExecutionStatus.InProgress,
            ResourceExecutionStatus.Pending,
          ]) ?? 0;
        const unsuccessfulCount = getResourceStatusesCount(
          row.original.resourceStates,
          [
            ResourceExecutionStatus.Failed,
            ResourceExecutionStatus.Error,
            ResourceExecutionStatus.Timeout,
            ResourceExecutionStatus.NotInTimeWindow,
          ]
        );
        if (isInProgress > 0) {
          return (
            <Text color="danger" mb={0}>
              {"0"}
            </Text>
          );
        }
        return (
          <Text color="danger" mb={0}>
            {unsuccessfulCount ?? "-"}
          </Text>
        );
      },
      meta: { css: { minWidth: "8.75rem", padding: theme.spacing.spacing03 } },
    }),
  ] as never;
}
