/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { Else, If, Then, When } from "react-if";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { EventActionFragment } from "~/generated/graphql";
import { useDisclosure } from "~/hooks";
import { inflect, capitalize, isEmpty } from "~/tools";
import { useApproveEvent } from "~/views/events/hooks";
import { RefetchOptions } from "../../helpers";

type Props = {
  eventId?: string;
  actions?: EventActionFragment[];
  hasIcon?: boolean;
  refetchQueries: RefetchOptions;
};

export function ApproveEvent({
  eventId,
  hasIcon,
  actions,
  refetchQueries,
}: Props) {
  const { isOpen, open, close } = useDisclosure();
  const [selectedActions, setSelectedActions] = useState<string[]>([]);

  const actionLength = actions?.length ?? 1;
  const actionLabel = inflect("action")(actions?.length ?? actionLength);

  const handleChange = (actionId: string) => {
    if (selectedActions?.includes(actionId)) {
      const newSelectedAction = selectedActions.filter((id) => id !== actionId);
      setSelectedActions(newSelectedAction);
    } else {
      setSelectedActions((prevState) => [...prevState, actionId]);
    }
  };

  const clean = () => {
    close();
    setSelectedActions([]);
  };

  const { approveEventAction, loading } = useApproveEvent({
    onSuccess: clean,
    refetchQueries,
  });

  const handleApprove = () => {
    selectedActions.forEach((id) => {
      approveEventAction(eventId ?? "", id ?? "");
    });
  };

  useEffect(() => {
    if (actions?.length === 1) {
      setSelectedActions([actions[0].id]);
    }
  }, [isOpen, actions]);

  return (
    <>
      <When condition={!loading}>
        <Button
          icon={hasIcon ? "checkmark" : undefined}
          mr={theme.spacing.spacing02}
          status="accent"
          onClick={open}
          disabled={loading}
        >
          Approve {capitalize(actionLabel)}
        </Button>
        <Modal
          onClose={close}
          isOpen={isOpen}
          contentLabel={`Approve ${capitalize(actionLabel)}`}
          actions={[
            {
              order: 0,
              onAction: close,
              label: "Cancel",
              severity: "low",
            },
            {
              order: 1,
              onAction: handleApprove,
              label: "Approve",
              severity: "high",
              disabled: isEmpty(selectedActions),
            },
          ]}
        >
          <If condition={actionLength > 1}>
            <Then>
              <Text>{`Please select ${actionLabel} to approve:`}</Text>
              {actions?.map((action) => (
                <div
                  key={action.id}
                  css={{ marginTop: theme.spacing.spacing04 }}
                >
                  <Checkbox
                    aria-labelledby={action?.name}
                    labelText={action.name}
                    id={action.id}
                    onChange={() => handleChange(action?.id ?? "")}
                    checked={selectedActions?.includes(action.id)}
                  />
                </div>
              ))}
            </Then>
            <Else>
              <Text tag="div">{`Are you sure you want to approve ${actions?.[0].name}?`}</Text>
            </Else>
          </If>
          <Spacer height={theme.spacing.spacing06} />
          <Box boxStyle="lightGrey">
            <Text tag="div" size="sm" color={theme.color.text.text02}>
              The status of the corresponding event will transition to{" "}
              <b>In Progress</b> within approximately 1 minute.
            </Text>
          </Box>
        </Modal>
      </When>
    </>
  );
}
