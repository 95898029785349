/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ResourcePageFragment } from "~/generated/graphql";

export type ResourceTableState = {
  resources: ResourcePageFragment["result"];
  count?: number;
  loading: boolean;
};

export const minSearchLength = 3;
export const maxSearchLength = 100;
