/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { When } from "react-if";
import { Text } from "@nordcloud/gnui";
import { EventAction, ExecutionStatus } from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { LogsOutputButton } from "~/views/events/EventsDetails/components/LogOutputButton";

export function ActionDetails({ eventAction }: { eventAction?: EventAction }) {
  const startTime = dayjs(eventAction?.globalState?.startTime).format(
    dateFormat.dayMonthYearHour
  );

  const endTime = dayjs(eventAction?.globalState?.endTime).format(
    dateFormat.dayMonthYearHour
  );

  return (
    <div css={{ minHeight: "19.3rem" }}>
      <Text weight="medium">Action Details</Text>
      <Text size="sm" tag="div">
        Name
      </Text>
      <Text>{eventAction?.name}</Text>
      <When
        condition={
          eventAction?.globalState?.status !== ExecutionStatus.Upcoming
        }
      >
        <Text size="sm" tag="div">
          Start Time (local)
        </Text>
        <Text>{startTime}</Text>
      </When>
      <When
        condition={isNotStatusInProgressOrUpcoming(
          eventAction?.globalState?.status
        )}
      >
        <Text size="sm" tag="div">
          End Time (local)
        </Text>
        <Text>{endTime}</Text>
      </When>
      <When condition={eventAction?.globalState?.outputUri}>
        <LogsOutputButton
          actionLogLabel={eventAction?.name}
          outputUri={eventAction?.globalState?.outputUri}
        />
      </When>

      {/* TODO AP-4540}
      
      <When condition={isNotEmpty(eventAction?.resourceStates ?? [])}>
        <Text size="sm" tag="div">
          Resource Status Summary
        </Text>
      </When> */}
    </div>
  );
}

function isNotStatusInProgressOrUpcoming(status?: ExecutionStatus): boolean {
  if (!status) {
    return false;
  }

  return ![ExecutionStatus.InProgress, ExecutionStatus.Upcoming].includes(
    status
  );
}
