/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ReactNode } from "react";
import { FlexContainer, Spacer, theme } from "@nordcloud/gnui";
import { NoData } from "~/components";

type Props = {
  addAction: ReactNode;
};

export function NoActionView({ addAction }: Props) {
  return (
    <NoData
      hasIcon
      customCss={{ flexDirection: "column" }}
      message={
        <FlexContainer
          justifyContent="center"
          direction="column"
          css={{ textAlign: "center" }}
        >
          <Spacer height={theme.spacing.spacing04} />
          There are no actions in the plan yet. <br />
          To create a plan you need to add the first action.
          {addAction}
        </FlexContainer>
      }
    />
  );
}
