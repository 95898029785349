/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PlanActionType } from "~/generated/graphql";
import { SvgName } from "~/tools/types";

export type Item = {
  id: string;
  listId?: string;
  name?: string;
  description?: string;
  actionType?: PlanActionType;
  color?: string;
  icon?: SvgName;
  columns?: Column[];
  selected?: boolean;
};

export type Column = {
  data: string[];
  type: ColumnType;
};

export enum ColumnType {
  text = "text",
  os = "os",
  selectionMethod = "selectionType",
}

export enum SelectionMethod {
  manual = "manual",
  dynamic = "dynamic",
}
