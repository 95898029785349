/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import {
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  EventActionAttempt,
  useEventActionStatusChangedSubscription,
} from "~/generated/graphql";
import {
  ButtonText,
  ColorLabel,
  IconWrapper,
  ToggleButtonItem,
} from "~/components/ToggleItemBox";
import { isNotNil } from "~/tools";
import { SvgName } from "~/tools/types";
import { useEvent } from "../../EventsDetails/EventProvider";
import { getActionStatus } from "../../helpers";
import { useGetEvent } from "../../hooks";
import { Item } from "../types";
import { addOpacityToHexColor } from "../utils";

export function EventAttemptToggleItemBox({
  item,
  attempt,
  isAttemptSelected,
}: {
  item: Item;
  attempt: EventActionAttempt;
  isAttemptSelected: boolean;
}) {
  const { event: eventCtx } = useEvent();
  const { refetch } = useGetEvent({
    eventId: eventCtx.id,
  });
  const { data } = useEventActionStatusChangedSubscription({
    variables: {
      input: {
        id: attempt.id,
      },
    },
    onData: () => {
      refetch();
    },
  });

  const attemptStatus = getActionStatus(attempt.state?.status);

  const updatedAttemptStatus =
    data?.eventActionStatusChanged &&
    getActionStatus(data?.eventActionStatusChanged);

  return (
    <ToggleButtonItem
      color={item.color}
      active={isAttemptSelected}
      key={attempt.id}
      onMouseDown={() => {
        item.onMouseDown?.();
        item.onSelect?.(attempt);
      }}
    >
      <ColorLabel color={addOpacityToHexColor(item.color, 0.5)} />
      <FlexContainer css={{ marginLeft: "4.3rem" }}>
        <When condition={isNotNil(attempt.state?.status)}>
          <IconWrapper>
            <ExtendedTooltip
              caption={
                updatedAttemptStatus?.description ?? attemptStatus.description
              }
              status={
                updatedAttemptStatus?.tooltipColor ?? attemptStatus.tooltipColor
              }
              position="start"
            >
              <SVGIcon
                name={
                  (updatedAttemptStatus?.icon ?? attemptStatus.icon) as SvgName
                }
                size="md"
                color={updatedAttemptStatus?.color() ?? attemptStatus.color()}
              />
            </ExtendedTooltip>
          </IconWrapper>
        </When>
        <ButtonText>
          <FlexContainer
            direction="column"
            alignItems="flex-start"
            mr={theme.spacing.spacing02}
          >
            <Text tag="div" align="left" mb={0} weight="medium">
              Attempt {attempt.attempt}
            </Text>
          </FlexContainer>
        </ButtonText>
      </FlexContainer>
    </ToggleButtonItem>
  );
}
