/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { v4 } from "uuid";
import { Text } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { isNotEmpty } from "~/tools";
import { NextDateTooltip } from "../components/NextDateTooltip";

type Props = {
  nextExecutionDates: string[];
};

export function NextExecutionDatesTooltip({ nextExecutionDates }: Props) {
  const tooltip = () => {
    if (isNotEmpty(nextExecutionDates)) {
      return nextExecutionDates.map((date) => (
        <Text key={v4()} size="sm" mb={0} color="text01">
          Next: {dayjs(date).format(dateFormat.dayMonthYearHour)}
        </Text>
      ));
    }
    return (
      <Text size="sm" mb={0} color="text01">
        No future execution dates have been set.
      </Text>
    );
  };

  return (
    <NextDateTooltip tooltip={tooltip()} description="Plan will run on time" />
  );
}
