/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useRef } from "react";
import dayjs from "dayjs";
import { When } from "react-if";
import {
  Box,
  Button,
  FlexContainer,
  Input,
  Label,
  SelectButton,
  theme,
} from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { useClickOutside, useDateValues, useDisclosure } from "~/hooks";
import { useFilters } from "~/hooks/useFilters";
import { filterKeys } from "../constants";
import { DateRange, EventsFiltersType, Period } from "../types";
import { defaultRangeLabel } from "../utils";
import {
  ButtonWrapper,
  Content,
  Wrapper,
  ApplyWrap,
  InputWrap,
  ClearButton,
} from "./styles";

type Props = {
  label: string;
  isActive: boolean;
  period?: Period;
  range?: DateRange;
  onClick: () => void;
  onClear: () => void;
  onApply: (startDate?: string, endDate?: string) => void;
};

export function DateRangeSelector({
  isActive,
  onApply,
  period,
  label,
  range,
  onClear,
  onClick,
}: Props) {
  const { isOpen, open, close } = useDisclosure();

  const wrapper = useRef<HTMLDivElement>(null);

  const { apply, setFilters } = useFilters<EventsFiltersType>({
    filterKeys,
  });

  const { endDate, setEndDate, startDate, setStartDate } = useDateValues(range);

  const defaultDate = dayjs();

  useClickOutside(
    wrapper,
    () => {
      close();
    },
    isOpen
  );

  const handleRangeClick = useCallback(() => {
    if (period !== Period.range && label !== defaultRangeLabel) {
      onApply(startDate, endDate);
    } else {
      open();
    }
  }, [period, label, onApply, startDate, endDate, open]);

  const handleApply = useCallback(() => {
    onApply(startDate, endDate);
    close();
  }, [onApply, startDate, endDate, close]);

  const handleClear = () => {
    onClear();
    setStartDate(undefined);
    setEndDate(undefined);
    close();
  };

  const handleStartDateSelection = (value: string) => {
    setStartDate(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate: value,
    }));
    if (dayjs(endDate).isBefore(value)) {
      setEndDate(undefined);
      setFilters((prevFilters) => ({
        ...prevFilters,
        startDate: undefined,
      }));
    }
  };
  const handleEndDateSelection = (value: string) => {
    setEndDate(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      endDate: value,
    }));
  };
  const isActionDisabled = !endDate || !startDate;

  return (
    <Wrapper ref={wrapper}>
      <ButtonWrapper>
        <SelectButton
          name="range"
          value="13"
          labelText={label}
          isActive={isActive}
          style={{ whiteSpace: "nowrap", minWidth: "7.5rem" }}
          onClick={() => {
            onClick();
            handleRangeClick();
          }}
        />
        <When condition={label !== defaultRangeLabel}>
          <ClearButton
            icon="close"
            size="sm"
            severity={isActive ? undefined : "medium"}
            onClick={handleClear}
          />
        </When>
      </ButtonWrapper>
      <Content isVisible={isOpen}>
        <Box innerSpacing="spacing00" shadow="shadow04" spacing="spacing00">
          <FlexContainer
            css={{ padding: theme.spacing.spacing04 }}
            direction="column"
            alignItems="stretch"
          >
            <div>
              <FlexContainer gap={theme.spacing.spacing04}>
                <InputWrap>
                  <Label name="Start date" />
                  <Input
                    aria-label="Start date"
                    type="date"
                    value={startDate ?? ""}
                    min={getDateToMinValue(defaultDate)}
                    onChange={(event) =>
                      handleStartDateSelection(event.target.value)
                    }
                    small
                  />
                </InputWrap>
                <InputWrap>
                  <Label name="End date" />
                  <Input
                    aria-label="End date"
                    type="date"
                    value={endDate ?? ""}
                    min={startDate}
                    max={defaultDate
                      .add(1, "year")
                      .format(dateFormat.shortDate)}
                    disabled={startDate === undefined}
                    onChange={(event) =>
                      handleEndDateSelection(event.target.value)
                    }
                    small
                  />
                </InputWrap>
              </FlexContainer>
            </div>
          </FlexContainer>
          <ApplyWrap justifyContent="center">
            <Button
              disabled={isActionDisabled}
              display="inline-flex"
              onClick={() => {
                apply();
                handleApply();
              }}
            >
              Apply
            </Button>
          </ApplyWrap>
        </Box>
      </Content>
    </Wrapper>
  );
}

function getDateToMinValue(defaultDate: dayjs.Dayjs, startDate?: string) {
  return startDate
    ? dayjs(startDate).subtract(1, "year").format(dateFormat.shortDate)
    : defaultDate.subtract(1, "year").format(dateFormat.shortDate);
}
