/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { Col, Row } from "react-awesome-styled-grid";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Case, Switch } from "react-if";
import { ZodType } from "zod";
import { Box, theme } from "@nordcloud/gnui";
import { hasTruthyValue, isEmpty } from "~/tools";
import { SpecifiedDatesSchedule } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanScheduleForms/components/SpecifiedDatesSchedule";
import {
  TimeBetweenErrorMessage,
  TimeErrorMessage,
} from "~/views/plans/PlanDetails/components/constants";
import { selectScheduleSchema } from "~/views/plans/schemas";
import { ScheduleType } from "~/views/plans/types";
import {
  getValidDates,
  makeDateItemTimezoneAware,
  validateDateTime,
  validateMinThreeHoursDiff,
} from "~/views/plans/utils";
import { PlanField } from "../constants";
import { FormData } from "../formConfig";
import { usePlanWizard } from "../PlanProvider";
import { FormField, TimeUnits } from "../types";
import { ScheduleCron } from "./components/ScheduleCron";
import { SelectSchedule } from "./components/SelectSchedule";
import { isFormValid } from "./utils";

type Props = {
  nextStep: () => void;
};

export function PlanScheduleForm({ nextStep }: Props) {
  const { planData, setPlanData } = usePlanWizard();
  const [scheduleType, setScheduleType] = useState(
    planData?.[PlanField.SCHEDULE_TYPE] ?? ScheduleType.recurring
  );

  const [currentSchema, setSchema] = useState<ZodType>(
    selectScheduleSchema(
      planData?.[PlanField.SCHEDULE_TYPE] ?? ScheduleType.recurring
    )
  );

  const formMethods = useForm({
    resolver: zodResolver(currentSchema),
    defaultValues: {
      [FormField.SCHEDULE_TIMEZONE]:
        planData?.schedule_plan?.scheduleTimezone ?? dayjs.tz.guess(),
      [FormField.SCHEDULE_CRON]: planData?.schedule_plan?.scheduleCron ?? "",
      [FormField.SCHEDULE_OFFSET]: planData?.schedule_plan?.scheduleOffset ?? 0,
      [FormField.SCHEDULE_OFFSET_UNIT]: TimeUnits.minutes,
      [FormField.SCHEDULE_EXECUTION_DATES]:
        planData?.schedule_plan?.scheduleExecutionDates ?? [],
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const setErrorIfInvalid = (
    isError: boolean,
    index: number,
    errorMessage: string
  ) => {
    if (isError) {
      setError(
        `${FormField.SCHEDULE_EXECUTION_DATES}.${index}.${FormField.EXECUTION_TIME}`,
        {
          message: errorMessage,
        }
      );
    }
  };

  const submitDatesSchedule = (formData: FormData) => {
    const formDataDates = formData?.scheduleExecutionDates ?? [];
    const prepareDates = formDataDates?.map((date) => {
      return {
        time: date.executionTime,
        date: date.executionDate,
      };
    });

    const validDateTime = validateDateTime(
      prepareDates,
      formData?.scheduleTimezone ?? ""
    );

    if (hasTruthyValue(validDateTime ?? [])) {
      validDateTime?.forEach((isError, i) =>
        setErrorIfInvalid(isError, i, TimeErrorMessage)
      );
      return;
    }
    const validHourSpace = validateMinThreeHoursDiff(
      formData?.scheduleExecutionDates ?? []
    );
    if (hasTruthyValue(validHourSpace)) {
      validHourSpace?.forEach((isError, i) =>
        setErrorIfInvalid(isError, i + 1, TimeBetweenErrorMessage)
      );
      return;
    }

    const validDates = getValidDates(formDataDates);
    if (isEmpty(validDates)) {
      return;
    }
    nextStep();
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...formData,
        scheduleExecutionDates: validDates.map((date) =>
          makeDateItemTimezoneAware(date, formData.scheduleTimezone)
        ),
      },
    }));
  };

  const submit: SubmitHandler<FormData> = (formData) => {
    if (scheduleType === ScheduleType.specifiedDates) {
      submitDatesSchedule(formData);

      return;
    }

    if (!isFormValid(errors)) {
      errors.scheduleCron?.message &&
        setError(FormField.SCHEDULE_CRON, {
          message: errors.scheduleCron.message,
        });
      return;
    }
    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...formData,
      },
    }));

    nextStep();
  };

  const handleScheduleTypeChange = useCallback(
    (type: ScheduleType) => {
      setScheduleType(type);
      setSchema(selectScheduleSchema(type));
      setPlanData((prevPlanData) => ({
        ...prevPlanData,
        [PlanField.SCHEDULE_TYPE]: type,
      }));
    },
    [setPlanData]
  );

  return (
    <Row justify="center">
      <Col sm={6} md={6} lg={8}>
        Schedule Options
        <Box
          boxStyle="lightGrey"
          spacing="spacing04"
          mt={theme.spacing.spacing04}
        >
          <FormProvider {...formMethods}>
            <form id="planScheduleForm" onSubmit={handleSubmit(submit)}>
              <SelectSchedule
                activeScheduleType={scheduleType}
                onScheduleTypeChange={handleScheduleTypeChange}
              />
              <Switch>
                <Case condition={scheduleType === ScheduleType.recurring}>
                  <ScheduleCron
                    planDataForm={planData}
                    setPlanData={setPlanData}
                  />
                </Case>
                <Case condition={scheduleType === ScheduleType.specifiedDates}>
                  <SpecifiedDatesSchedule
                    planDataForm={planData}
                    setPlanData={setPlanData}
                  />
                </Case>
              </Switch>
            </form>
          </FormProvider>
        </Box>
      </Col>
    </Row>
  );
}
