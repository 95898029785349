/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { useEffect, useState } from "react";
import { RowSelectionState } from "@tanstack/react-table";
import {
  ResourceFragment,
  ResourcesSortFields,
  SortOrder,
} from "~/generated/graphql";
import { Pagination } from "~/components/Pagination";
import { TableWrapper } from "~/components/Tables";
import { useQueryState } from "~/hooks";
import { isNotNil } from "~/tools";
import { useGetResources } from "~/views/resources/hooks";
import { ResourceQuery } from "~/views/resources/types";
import { ResourceCtxProvider } from "../ResourceProvider";
import { minSearchLength, ResourceTableState } from "./const";
import { ResourceListHeader } from "./ResourceListHeader";
import { columns } from "./ResourcesColumns";

type Props = {
  providerId?: string;
};

export function ResourcesTable({ providerId }: Props) {
  const [resourcesIds, setResourcesIds] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [resourcesSearchPage, setResourcesSearchPage] =
    useState<ResourceTableState>({
      resources: [],
      loading: false,
    });

  const {
    queryState: { page, limit, field, order, searchPhrase },
  } = useQueryState<ResourceQuery>();

  const { data, loading, count } = useGetResources({
    page: page,
    limit: limit,
    sort: {
      field: field ?? ResourcesSortFields.Name,
      order: order ?? SortOrder.Asc,
    },
    filter: {
      providerId: providerId,
    },
  });
  const isResourceSearch =
    searchPhrase && searchPhrase.length >= minSearchLength;

  const resourcePage = isResourceSearch
    ? resourcesSearchPage
    : { resources: data, count, loading };

  const resources = resourcePage.resources?.filter(isNotNil) ?? [];

  const resourcesDetails = resources.filter((item) =>
    resourcesIds.includes(item?.id ?? "")
  );

  const cleanRowSelection = () => setRowSelection({});

  useEffect(() => {
    if (!isResourceSearch) {
      setResourcesSearchPage({
        resources: data,
        count: count,
        loading: false,
      });
    }
  }, [isResourceSearch]);

  return (
    <ResourceCtxProvider value={{ cleanRowSelection }}>
      <ResourceListHeader
        resourcesDetails={resourcesDetails}
        setResourcesSearchPage={setResourcesSearchPage}
      />
      <TableWrapper<ResourceFragment>
        loading={resourcesSearchPage.loading || loading}
        data={resources}
        columns={columns()}
        selectedRowsHandler={setResourcesIds}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        stripped
      />
      <Pagination count={resourcePage.count ?? 0} />
    </ResourceCtxProvider>
  );
}
