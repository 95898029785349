/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { When } from "react-if";
import { Box, FlexContainer, Spacer, theme } from "@nordcloud/gnui";
import {
  EventAction,
  EventActionAttempt,
  EventActionScope,
} from "~/generated/graphql";
import { getLastItem } from "~/tools";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { ActionLog } from "./ActionLog";
import { Rerun } from "./Rerun";

type Props = {
  eventAction: EventAction;
  attempt?: EventActionAttempt;
};

export function EventResourceHeader({ eventAction, attempt }: Props) {
  const { event } = useEvent();
  const { id: eventId } = event;

  const lastEventActionAttemptId = getLastItem(eventAction.attempts).id;

  const outputUri =
    attempt?.state?.outputUri ?? eventAction?.globalState?.outputUri;
  const modalLabel = attempt?.attempt
    ? `Attempt ${attempt?.attempt.toString()}`
    : eventAction?.action?.name;

  return (
    <>
      <Box>
        <FlexContainer justifyContent="flex-end">
          <When condition={eventAction?.scope === EventActionScope.Plan}>
            <ActionLog
              outputUri={outputUri ?? ""}
              modalLabel={modalLabel ?? ""}
            />
          </When>
          <Rerun {...{ eventId }} eventActionId={lastEventActionAttemptId} />
        </FlexContainer>
      </Box>
      <Spacer height={theme.spacing.spacing04} />
    </>
  );
}
