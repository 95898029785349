/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CloudAccount, UpdateCloudAccountInput } from "~/generated/graphql";
import { CloudAccountFields } from "./types";

export function toCloudAccountPutInput(
  account: CloudAccount
): UpdateCloudAccountInput {
  return {
    id: account.id,
    name: account.name,
    description: account.description,
    environment: account.environment,
    regions: account.regions,
    metadata: account.metadata,
  };
}

export function getUpdateObject(
  data: CloudAccountFields,
  cloudAccount: CloudAccount
): UpdateCloudAccountInput {
  const putCloudAccount = toCloudAccountPutInput(cloudAccount);
  if (
    cloudAccount.provider === "AWS" &&
    data.arn !== undefined &&
    data.externalId !== undefined
  ) {
    return {
      ...putCloudAccount,
      credentials: {
        aws: {
          rolesChain: [
            {
              externalId: data.externalId,
              arn: data.arn ?? "",
            },
          ],
        },
      },
    };
  }
  if (
    cloudAccount.provider === "AZURE" &&
    data.appId !== undefined &&
    data.password !== undefined &&
    data.tenantId !== undefined
  ) {
    return {
      ...putCloudAccount,
      credentials: {
        azure: {
          appId: data.appId ?? "",
          password: data.password ?? "",
          tenantId: data.tenantId ?? "",
        },
      },
    };
  }

  return { ...putCloudAccount, ...data };
}
