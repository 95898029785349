/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { PlanActionType } from "~/generated/graphql";
import { ExecutionMethod } from "./types";

export const actionsType = [
  {
    label: "System Actions",
    value: PlanActionType.System,
  },
  {
    label: "Custom Actions",
    value: PlanActionType.Custom,
  },
];

export const defaultState = {
  isSideBarOpen: false,
  isModalOpen: false,
  batchName: "",
  actionType: PlanActionType.System,
  selectedActions: [],
  selectionType: ExecutionMethod.single,
};
