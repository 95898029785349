/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then } from "react-if";
import { Box, theme } from "@nordcloud/gnui";
import { IncomingEventStatus, PlanAction } from "~/generated/graphql";
import { ResourceGroupSection } from "~/components";
import { isNotEmpty } from "~/tools";
import { IncomingEventActionSettingsSection } from "./components/IncomingEventActionSettingsSection";

type Props = {
  planAction?: PlanAction;
  planId?: string;
  status: IncomingEventStatus;
};

export function IncomingEventActionBox({ planAction, planId, status }: Props) {
  const isResourceSection = isNotEmpty(planAction?.resourceGroups ?? []);
  return (
    <Row>
      <If condition={isResourceSection}>
        <Then>
          <Col
            xs={4}
            sm={4}
            md={4}
            lg={8}
            css={{ paddingRight: theme.spacing.spacing01 }}
          >
            <Box boxStyle="grey" innerSpacing="spacing00">
              <ResourceGroupSection
                resourceGroups={planAction?.resourceGroups}
                status={status}
              />
            </Box>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4}>
            <Box innerSpacing="spacing00" css={{ height: "100%" }}>
              <IncomingEventActionSettingsSection
                planAction={planAction}
                {...{ planId }}
              />
            </Box>
          </Col>
        </Then>
        <Else>
          <Col xs={8} sm={8} md={12} lg={12}>
            <Box innerSpacing="spacing00">
              <IncomingEventActionSettingsSection
                planAction={planAction}
                {...{ planId }}
              />
            </Box>
          </Col>
        </Else>
      </If>
    </Row>
  );
}
