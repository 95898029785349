/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Box, FlexContainer, theme } from "@nordcloud/gnui";
import { SearchInput } from "~/components";
import { ReportDownload } from "../../ReportDownload";
import { useReportState } from "../ReportProvider";
import { ReportFilters } from "./ReportFilters";

export function ReportHeader() {
  const { reportState, updateReportState } = useReportState();

  return (
    <Box mb={theme.spacing.spacing04}>
      <FlexContainer mb={theme.spacing.spacing04}>
        <div css={{ flex: 1, marginRight: theme.spacing.spacing04 }}>
          <SearchInput
            placeholder="Type a Keyword (e.g., Resource Name, Resource ID, Update Name, etc.) "
            onSubmit={(query) => updateReportState({ searchQuery: query })}
          />
        </div>
        <ReportDownload eventActionId={reportState.eventActionId} />
      </FlexContainer>
      <ReportFilters />
    </Box>
  );
}
