/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import { Box, FlexContainer, Text, theme, Switch } from "@nordcloud/gnui";
import {
  EventAction,
  EventActionAttempt,
  EventActionScope,
  useEventActionStatusChangedSubscription,
  useGetEventActionsResourceStatesQuery,
} from "~/generated/graphql";
import { NoData } from "~/components";
import { useToggle } from "~/hooks";
import { isNonEmpty } from "~/tools";
import { EventToggleItemBox } from "~/views/events/components/EventToggleItemBox/EventToggleItemBox";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { ReportView } from "~/views/events/EventsDetails/Tabs/Details/components/ReportView/ReportView";
import {
  getActionStatus,
  shouldAwaitResourceStates,
} from "~/views/events/helpers";
import { useGetEvent } from "~/views/events/hooks";
import { EventActionBox } from "./components/ActionBox/EventActionBox";
import { EventDetailsTabCtxProvider } from "./EventDetailsTabProvider";

export function EventDetailsTab() {
  const { event: eventCtx } = useEvent();
  const actions = eventCtx.actions;
  if (isNonEmpty(actions)) {
    return EventActionDetailsTab();
  }
  return <NoData hasIcon message="There are no Actions" />;
}

function EventActionDetailsTab() {
  const { event: eventCtx } = useEvent();
  const actions = eventCtx.actions;

  const [attempt, setAttempt] = useState<EventActionAttempt>();
  const [isReportView, toggleReportView] = useToggle(false);

  const { event, refetch: refetchEvent } = useGetEvent({
    eventId: eventCtx.id,
  });

  const { startPolling, stopPolling } = useGetEventActionsResourceStatesQuery({
    variables: {
      id: eventCtx.id,
    },
    fetchPolicy: "network-only",
  });

  const [eventAction, setEventAction] = useState<EventAction>(actions[0]);

  useEventActionStatusChangedSubscription({
    variables: {
      input: {
        // globalState is recalculated at product api - needs to be refetched when latest attempt status changes
        id: eventAction.attempts[eventAction.attempts.length - 1].id,
      },
    },
    onData: () => {
      refetchEvent();
    },
  });

  const handleSelectAttempt = (value: EventActionAttempt) => setAttempt(value);

  const updatedResourceStates = useMemo(
    () =>
      event?.actions?.filter((action) => eventAction.id === action.id)[0]
        .resourceStates ?? [],
    [event?.actions, eventAction.id]
  );

  const awaitResourceStates = useCallback(() => {
    return shouldAwaitResourceStates(eventAction, updatedResourceStates);
  }, [eventAction, updatedResourceStates]);

  const resourceStates = attempt
    ? attempt?.resourceStates
    : updatedResourceStates;

  useEffect(() => {
    if (awaitResourceStates()) {
      startPolling(2000);
    } else {
      stopPolling();
    }
  }, [awaitResourceStates, startPolling, stopPolling]);

  return (
    <Box innerSpacing="spacing00">
      <EventDetailsTabCtxProvider
        value={{ eventAction, attempt, setEventAction, setAttempt }}
      >
        <Row>
          <Col xs={8}>
            <FlexContainer
              direction="row"
              justifyContent="space-between"
              padding={`${theme.spacing.spacing04} 0`}
            >
              <div>
                <Text width="100%" align="left" size="md" weight="medium">
                  Actions
                </Text>
              </div>
              <div>
                <When
                  condition={eventAction.scope === EventActionScope.Resource}
                >
                  <Switch
                    name="label-left"
                    position="left"
                    labelText="Report View"
                    onChange={toggleReportView}
                  />
                </When>
              </div>
            </FlexContainer>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={8} md={3} lg={3}>
            <FlexContainer direction="column" justifyContent="flex-start">
              {actions?.map((item: EventAction) => {
                const attempts = item.attempts?.length > 1 ? item.attempts : [];

                const statusDetails = getActionStatus(item.globalState?.status);

                return (
                  <EventToggleItemBox
                    key={item.id}
                    item={{
                      id: item.id,
                      color: item.action?.color ?? theme.color.support.grey,
                      name: item.name,
                      icon: statusDetails.icon,
                      iconColor: statusDetails.color(),
                      tooltip: statusDetails.description,
                      tooltipColor: statusDetails.tooltipColor,
                      onMouseDown: () => {
                        setEventAction(item);
                        setAttempt(undefined);
                      },
                      onSelect: handleSelectAttempt,
                    }}
                    attempts={attempts}
                    selectedAttempt={attempt}
                    active={item.id === eventAction.id}
                  />
                );
              })}
            </FlexContainer>
          </Col>
          <Col xs={4} sm={8} md={5} lg={9}>
            <Box boxStyle="grey" innerSpacing="spacing04">
              <FlexContainer alignItems="flex-start">
                <If condition={isReportView}>
                  <Then>
                    <ReportView
                      eventId={eventCtx.id}
                      eventActionId={eventAction.id}
                      attempt={attempt}
                    />
                  </Then>
                  <Else>
                    <EventActionBox
                      eventAction={eventAction}
                      resourceStates={resourceStates}
                      planId={eventCtx?.plan?.id}
                      attempt={attempt}
                    />
                  </Else>
                </If>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </EventDetailsTabCtxProvider>
    </Box>
  );
}
