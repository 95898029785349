// Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
import { Event } from "~/generated/graphql";
import { DetailData } from "~/components/Details/Detail";

export const DetailsFirstPreset: (event?: Partial<Event>) => DetailData[] = (
  event
) => [
  {
    label: "Event ID",
    value: event?.id,
  },
  {
    label: "Description",
    value: event?.description,
  },
];
