/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import { Checkbox, theme } from "@nordcloud/gnui";

type Props = {
  isVisible?: boolean;
  isSelected?: boolean;
};

export function ItemCheckbox({ isVisible, isSelected }: Props) {
  return (
    <When condition={isVisible}>
      <CheckboxWrap>
        <Checkbox withoutLabel readOnly checked={isSelected} />
      </CheckboxWrap>
    </When>
  );
}

const CheckboxWrap = styled.div`
  flex: 0;
  padding: ${theme.spacing.spacing02};
`;
