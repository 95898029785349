/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { KeyboardEvent, useState } from "react";
import { InputSearch } from "@nordcloud/gnui";

type Props = {
  disabled?: boolean;
  placeholder?: string;
  onSubmit: (query: string) => void;
};

export function SearchInput({
  disabled,
  onSubmit,
  placeholder = "Search",
}: Props) {
  const [searchInput, setSearchInput] = useState("");

  const onSearch = () => {
    onSubmit(searchInput);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <InputSearch
      onKeyPress={handleKeyPress}
      value={searchInput}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchInput(e.target.value)
      }
      placeholder={placeholder}
      onSearch={onSearch}
      name="search"
      disabled={disabled}
    />
  );
}
