/**
 * Copyright 2022-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import { Else, If, Then } from "react-if";
import { Box, FlexContainer } from "@nordcloud/gnui";
import { PlanSortFields, SortOrder, Plan } from "~/generated/graphql";
import {
  LoaderWrap,
  NoData,
  Pagination,
  SortSelector,
  TableWrapper,
} from "~/components";
import { useQueryState } from "~/hooks";
import { isEmpty, isNotNil } from "~/tools";
import { useGetPlans } from "~/views/plans/hooks";
import { columns } from "./PlansTableColumns";

type QuerySort = {
  field: PlanSortFields;
  order: SortOrder;
};

const sortOptions = [
  {
    value: PlanSortFields.Name,
    label: "Plan Name",
  },
  {
    value: PlanSortFields.CreatedAt,
    label: "Date Added",
  },
  {
    value: PlanSortFields.UpdatedAt,
    label: "Date Updated",
  },
  {
    value: PlanSortFields.UpdatedBy,
    label: "Updated By",
  },
];

type Props = {
  customActionId?: string;
};

export function PlansTable({ customActionId }: Props) {
  const { queryState, updateQueryState } = useQueryState<QuerySort>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const handleSortDirection = (order: SortOrder) => {
    updateQueryState({
      ...queryState,
      order,
    });
  };

  const handleSortField = (field: PlanSortFields) => {
    updateQueryState({
      ...queryState,
      field,
    });
  };

  const { data, loading, count } = useGetPlans({
    page: queryState.page,
    limit: queryState.limit,
    filter: {
      listId: customActionId,
    },
    sort: {
      field: queryState.field ?? PlanSortFields.Name,
      order: queryState.order ?? SortOrder.Asc,
    },
  });

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer justifyContent="space-between">
          <SortSelector<PlanSortFields>
            options={sortOptions}
            value={queryState.field ?? PlanSortFields.Name}
            direction={queryState.order ?? SortOrder.Asc}
            onSelect={handleSortField}
            onSortChange={handleSortDirection}
          />
        </FlexContainer>
      </Box>
      <LoaderWrap loading={loading} inContent>
        <If condition={isEmpty(data)}>
          <Then>
            <NoData hasIcon message={"There are no Plans."} />
          </Then>
          <Else>
            <TableWrapper<Partial<Plan>>
              loading={loading}
              data={data.filter(isNotNil) as Plan[]}
              columns={columns()}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              stripped
            />
            <Pagination count={count} />
          </Else>
        </If>
      </LoaderWrap>
    </>
  );
}
