/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { Case, Switch } from "react-if";
import { FormData } from "src/views/plans/PlanCreate/components/PlanCreateWizard/formConfig";
import { ZodType } from "zod";
import { Box, Button, Sidebar, theme } from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { showSuccess } from "~/services/toast";
import { hasTruthyValue, isEmpty } from "~/tools";
import { generateActionSuccessText } from "~/tools/text";
import { removePastDates } from "~/utils/dates";
import {
  FormField,
  TimeUnits,
} from "~/views/plans//PlanCreate/components/PlanCreateWizard/types";
import { useGenerateDatesFromCron, useUpdatePlan } from "~/views/plans/hooks";
import { ScheduleCron } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanScheduleForms/components/ScheduleCron";
import { SelectSchedule } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanScheduleForms/components/SelectSchedule";
import { isFormValid } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanScheduleForms/utils";
import { useRegenerateCronDates } from "~/views/plans/PlanDetails/hooks";
import { selectScheduleSchema } from "~/views/plans/schemas";
import { ScheduleType } from "~/views/plans/types";
import {
  convertDateItemToDateString,
  convertToMinutes,
  getValidDates,
  validateDateTime,
  validateMinThreeHoursDiff,
} from "~/views/plans/utils";
import { usePlan } from "../PlanProvider";
import { ScheduleState } from "../types";
import { TimeBetweenErrorMessage, TimeErrorMessage } from "./constants";
import { mapToSpecificDateItem, planScheduleType } from "./helpers";
import { NextExecutionComponent } from "./NextExecution";
import { SpecifiedDatesScheduleDetails } from "./SpecifiedDatesScheduleDetails";

export function ScheduleDetails() {
  const { plan, refetch } = usePlan();
  const scheduleExecutionDates = plan?.scheduleExecutionDates ?? [];

  const scheduleType = planScheduleType({
    scheduleExecutionDates: scheduleExecutionDates,
    cronExpression: plan?.scheduleCron ?? "",
  });

  const timezone = plan?.scheduleTimezone ?? dayjs.tz.guess();
  const defaultState = {
    scheduleType: scheduleType,
    cronNextDates: [],
    isSidebarOpen: false,
    scheduleExecutionDates: scheduleExecutionDates.map((date) =>
      mapToSpecificDateItem(date, plan?.scheduleTimezone ?? "")
    ),
    scheduleTimezone: timezone,
  };

  const [state, updateState] = useReducer(
    (data: ScheduleState, partialData: Partial<ScheduleState>) => ({
      ...data,
      ...partialData,
    }),
    defaultState
  );
  const [currentSchema, setSchema] = useState<ZodType>(
    selectScheduleSchema(state.scheduleType)
  );

  const formMethods = useForm({
    resolver: zodResolver(currentSchema),
    defaultValues: {
      [FormField.SCHEDULE_CRON]: plan?.scheduleCron ?? "",
      [FormField.SCHEDULE_OFFSET]: plan?.scheduleOffset ?? 0,
      [FormField.SCHEDULE_OFFSET_UNIT]: TimeUnits.minutes,
      [FormField.SCHEDULE_EXECUTION_DATES]: state.scheduleExecutionDates,
      [FormField.SCHEDULE_TIMEZONE]: timezone,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = formMethods;

  const success = () => {
    updateState({ isSidebarOpen: false });
    showSuccess(generateActionSuccessText("Plan")()("updated")());
  };

  const [updatePlan, loading] = useUpdatePlan({
    onSuccess: success,
  });

  const { generateDatesFromCron, loading: generatingDates } =
    useGenerateDatesFromCron({
      onSuccess: (dates) => updateState({ cronNextDates: dates }),
      planId: plan?.id,
    });

  const setErrorIfInvalid = (
    isError: boolean,
    index: number,
    errorMessage: string
  ) => {
    if (isError) {
      setError(
        `${FormField.SCHEDULE_EXECUTION_DATES}.${index}.${FormField.EXECUTION_TIME}`,
        {
          message: errorMessage,
        }
      );
    }
  };

  const submitDatesSchedule = (formData: FormData) => {
    const formDataDates = formData?.scheduleExecutionDates;

    const prepareDates = formDataDates?.map((date) => {
      return {
        time: date.executionTime,
        date: date.executionDate,
      };
    });

    const validDateTime = validateDateTime(
      prepareDates,
      timezone,
      plan?.scheduleExecutionDates ?? []
    );

    if (hasTruthyValue(validDateTime ?? [])) {
      validDateTime?.forEach((isError, i) =>
        setErrorIfInvalid(isError, i, TimeErrorMessage)
      );
      return;
    }
    const validHourSpace = validateMinThreeHoursDiff(
      formData?.scheduleExecutionDates ?? []
    );
    if (hasTruthyValue(validHourSpace)) {
      validHourSpace?.forEach((isError, i) =>
        setErrorIfInvalid(isError, i + 1, TimeBetweenErrorMessage)
      );
      return;
    }

    const validDates = getValidDates(formDataDates ?? []);
    if (isEmpty(validDates)) {
      return;
    }

    const scheduleExecutionDatesUTC = validDates.map((date) => {
      return dayjs(convertDateItemToDateString(date))
        .tz(state.scheduleTimezone, true)
        .toISOString();
    });
    updatePlan({
      id: plan?.id ?? "",
      scheduleTimezone: formData.scheduleTimezone,
      scheduleExecutionDates: scheduleExecutionDatesUTC,
      scheduleCron: null,
      scheduleOffset: 0,
    });
  };

  const submitOnDemand = () => {
    updatePlan({
      id: plan?.id ?? "",
      scheduleExecutionDates: [],
      scheduleCron: null,
      scheduleOffset: 0,
    });
  };

  const submitScheduleCron = (formData: FormData) => {
    if (!isFormValid(errors)) {
      errors.scheduleCron?.message &&
        setError(FormField.SCHEDULE_CRON, {
          message: errors.scheduleCron.message,
        });
      return;
    }

    const offsetInMinutes = convertToMinutes(
      formData.scheduleOffset?.toString() ?? "",
      formData.scheduleOffsetUnit ?? TimeUnits.minutes
    );
    updatePlan({
      id: plan?.id ?? "",
      scheduleCron: formData.scheduleCron,
      scheduleTimezone: formData.scheduleTimezone,
      scheduleOffset: offsetInMinutes,
      scheduleExecutionDates: [],
    });
  };

  const onSubmit = async (formData: FormData) => {
    switch (state.scheduleType) {
      case ScheduleType.specifiedDates:
        submitDatesSchedule(formData);
        break;
      case ScheduleType.onDemand:
        submitOnDemand();
        break;
      case ScheduleType.recurring:
        submitScheduleCron(formData);
        break;
    }
  };

  const handleEdit = () => {
    refetch?.();
    updateState({
      isSidebarOpen: true,
      scheduleTimezone: timezone,
      scheduleExecutionDates: scheduleExecutionDates.map((date) =>
        mapToSpecificDateItem(date, plan?.scheduleTimezone ?? "")
      ),
    });

    clearErrors(FormField.SCHEDULE_CRON);
    reset((formValues) => ({
      ...formValues,
      scheduleTimezone: timezone,
    }));
  };
  const handleScheduleTypeChange = (type: ScheduleType) => {
    updateState({ scheduleType: type });
    setSchema(selectScheduleSchema(type));
  };

  const nextExecutionDate = () => {
    refetch?.();
    const date = isEmpty(scheduleExecutionDates)
      ? state.cronNextDates[0]
      : removePastDates(scheduleExecutionDates)[0];

    if (date) {
      return dayjs(date).tz(timezone).format(dateFormat.dayMonthYearHour);
    } else {
      return "Unavailable";
    }
  };

  const { regenerateCronDates } = useRegenerateCronDates({
    generateDatesFromCron,
  });

  const handleCloseSidebar = () => {
    setSchema(selectScheduleSchema(scheduleType));
    regenerateCronDates();
    reset();
    updateState(defaultState);
  };

  const isLoading = loading || generatingDates;
  return (
    <>
      <NextExecutionComponent
        loading={isLoading}
        handleEdit={handleEdit}
        nextExecutionDate={
          state.scheduleType === ScheduleType.onDemand
            ? undefined
            : nextExecutionDate
        }
      />
      <Sidebar
        isOpen={state.isSidebarOpen}
        onClick={handleCloseSidebar}
        title="Schedule Plan"
        width={"40rem"}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box boxStyle="lightGrey">
              <SelectSchedule
                activeScheduleType={state.scheduleType}
                onScheduleTypeChange={handleScheduleTypeChange}
              />
              <Switch>
                <Case condition={state.scheduleType === ScheduleType.recurring}>
                  <ScheduleCron
                    planData={plan}
                    nextDates={state.cronNextDates}
                  />
                </Case>
                <Case
                  condition={state.scheduleType === ScheduleType.specifiedDates}
                >
                  <SpecifiedDatesScheduleDetails state={state} />
                </Case>
              </Switch>
            </Box>
            <Button
              mt={theme.spacing.spacing04}
              type="submit"
              disabled={loading}
              initialState={loading ? "loading" : ""}
            >
              Apply
            </Button>
          </form>
        </FormProvider>
      </Sidebar>
    </>
  );
}
