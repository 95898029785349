import { GraphQLUpload } from "graphql-upload-minimal";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  UUID: { input: string; output: string };
  Upload: { input: typeof GraphQLUpload; output: typeof GraphQLUpload };
};

export type AccountCredentialsInput = {
  aws?: InputMaybe<AwsCredentialsInput>;
  azure?: InputMaybe<AzureCredentialsInput>;
};

export type Action = {
  __typename?: "Action";
  /** Change details represent when and by whom data was created or modified */
  changeDetails: ChangeDetails;
  /** Color of the action in hexadecimal numeral system e.g. #FFFFFF. Used for presentation purposes */
  color: Scalars["String"]["output"];
  /** Additional description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Action identifier */
  id: Scalars["UUID"]["output"];
  /** Action name */
  name: Scalars["String"]["output"];
  /** Defines what parameters can be defined for the action */
  parameterDefinitions: Array<ActionParameterDefinition>;
  /** Scope of the action */
  scope: ActionScope;
  /** Determines whether the action is executed in a synchronous or asynchronous way */
  synchronous: Scalars["Boolean"]["output"];
  /** Determines type of the action and which source will be used */
  type: ActionType;
  /** Content of the action respectively based on the chosen type */
  typeProperties: ActionTypeProperties;
};

export type ActionPage = {
  __typename?: "ActionPage";
  /** Information about returned page */
  pageInfo: PageInfo;
  /** List of actions */
  result?: Maybe<Array<Action>>;
};

export type ActionParameterDefinition = {
  __typename?: "ActionParameterDefinition";
  /** Optionally defines what values are allowed for this parameter. Values other than string are JSON-serialized to string. */
  allowedValues?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Optionally defines the default value of the parameter. Values other than string are JSON-serialized to string. */
  defaultValue?: Maybe<Scalars["String"]["output"]>;
  /** Optionally defines additional field description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Name of the parameter */
  name: Scalars["String"]["output"];
  /** Determines whether the parameter is required or not */
  required: Scalars["Boolean"]["output"];
  /** Type of the parameter */
  type: ActionParameterType;
};

export enum ActionParameterType {
  Bool = "BOOL",
  Int = "INT",
  List = "LIST",
  String = "STRING",
}

export type ActionPropertyAwsLambda = {
  __typename?: "ActionPropertyAWSLambda";
  /** ARN of the AWS Lambda function */
  lambdaArn: Scalars["String"]["output"];
};

export type ActionPropertyAzureFunction = {
  __typename?: "ActionPropertyAzureFunction";
  /** Access key ID needed for authorization to execute Azure function */
  accessKeyId: Scalars["String"]["output"];
  /** Method used by the Azure function e.g. "GET", "POST", "PUT", "PATCH", "DELETE" */
  functionMethod: AzureFunctionMethod;
  /** URL of the Azure function */
  functionUrl: Scalars["String"]["output"];
};

export type ActionPropertySsmDocument = {
  __typename?: "ActionPropertySSMDocument";
  /** ARN or name of the AWS SSM document */
  documentId: Scalars["String"]["output"];
};

export type ActionPropertyScript = {
  __typename?: "ActionPropertyScript";
  /** URL reference to a script file on the S3. Use `scriptContent` query to get content of the script. */
  s3FileUrl: Scalars["String"]["output"];
};

export type ActionPropertyWebhook = {
  __typename?: "ActionPropertyWebhook";
  /** Field that is attached header fields (additional context and metadata) to the webhook request */
  headers: Array<KeyValue>;
  /** Payload is the data used in the webhook request in stringified JSON format */
  payload: Scalars["String"]["output"];
  /** Method used to invoke webhook e.g. "GET", "POST", "PUT", "PATCH", "DELETE" */
  webhookMethod: WebhookActionMethod;
  /** URL of the Webhook */
  webhookUrl: Scalars["String"]["output"];
};

export enum ActionScope {
  Plan = "PLAN",
  Resource = "RESOURCE",
}

export enum ActionSortFields {
  Color = "COLOR",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Name = "NAME",
  Synchronous = "SYNCHRONOUS",
  Type = "TYPE",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type ActionSortInput = {
  /** Defines fields which can be use for sorting purpose */
  field: ActionSortFields;
  /** Order of the list of the actions */
  order?: InputMaybe<SortOrder>;
};

export enum ActionType {
  AwsLambda = "AWS_LAMBDA",
  AzureFunction = "AZURE_FUNCTION",
  Script = "SCRIPT",
  SsmDocument = "SSM_DOCUMENT",
  Webhook = "WEBHOOK",
}

/** Represents all possible action type properties */
export type ActionTypeProperties =
  | ActionPropertyAwsLambda
  | ActionPropertyAzureFunction
  | ActionPropertySsmDocument
  | ActionPropertyScript
  | ActionPropertyWebhook;

export type Activation = {
  __typename?: "Activation";
  code: Scalars["String"]["output"];
  credentialsId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  region: Scalars["String"]["output"];
};

export type ApiKey = {
  __typename?: "ApiKey";
  changeDetails: ChangeDetails;
  description?: Maybe<Scalars["String"]["output"]>;
  expiresAt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  lastUsedAt?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  owner: Scalars["String"]["output"];
  roleBindings?: Maybe<RoleBindingsPage>;
};

export type ApiKeyRoleBindingsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<RoleBindingsSortInput>;
};

export type ApiKeysPage = {
  __typename?: "ApiKeysPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<Maybe<ApiKey>>>;
};

export enum ApiKeysSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  ExpiresAt = "EXPIRES_AT",
  Id = "ID",
  Name = "NAME",
  Owner = "OWNER",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type ApiKeysSortInput = {
  field: ApiKeysSortFields;
  order?: InputMaybe<SortOrder>;
};

export type AwsCredentialsInput = {
  rolesChain?: InputMaybe<Array<CredentialsAwsRoleInput>>;
  staticCredentials?: InputMaybe<StaticAwsCredentialsInput>;
};

export type AzureCredentialsInput = {
  appId: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  tenantId: Scalars["String"]["input"];
};

export enum AzureFunctionMethod {
  Delete = "DELETE",
  Get = "GET",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

export type BundledReportInfo = {
  __typename?: "BundledReportInfo";
  /** A message describing any error that occurred during report generation. */
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  /** Status of the report (e.g., READY, PENDING, ERROR). */
  status: ReportStatus;
  /** The presigned URL for downloading the report. */
  url?: Maybe<Scalars["String"]["output"]>;
};

export type BundledReportInput = {
  /** Date range for the report. */
  dateRange: DateRange;
  /** Type of report to request (e.g. CSV_EVENTS_LIST). */
  type: ReportType;
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type ChangeDetails = {
  __typename?: "ChangeDetails";
  createdAt: Scalars["String"]["output"];
  createdBy: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
  updatedBy: Scalars["String"]["output"];
};

export type ChangeResourceExecutionStatusInput = {
  annotation: Scalars["String"]["input"];
  eventActionId: Scalars["UUID"]["input"];
  eventActionResourceStateId: Scalars["UUID"]["input"];
  eventId: Scalars["UUID"]["input"];
  status: ResourceExecutionStatusManual;
};

export type Channel = {
  __typename?: "Channel";
  id: Scalars["UUID"]["output"];
  typeProperties: ChannelTypeProperties;
};

export type ChannelCreateInput = {
  type: ChannelType;
  /** Content of the notification channel respectively based on the chosen type */
  typeProperties: CreateChannelTypeTypePropertiesInput;
};

export enum ChannelType {
  Email = "EMAIL",
  Webhook = "WEBHOOK",
}

export type ChannelTypeProperties = EmailChannel | WebhookChannel;

export type ChannelUpdateInput = {
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  type?: InputMaybe<ChannelType>;
  /** Content of the notification channel respectively based on the chosen type */
  typeProperties?: InputMaybe<UpdateChannelTypePropertiesInput>;
};

export type CloudAccount = {
  __typename?: "CloudAccount";
  changeDetails: ChangeDetails;
  description?: Maybe<Scalars["String"]["output"]>;
  environment?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  lastScanEndDate?: Maybe<Scalars["String"]["output"]>;
  lastScanStartDate?: Maybe<Scalars["String"]["output"]>;
  metadata?: Maybe<Array<KeyValue>>;
  name: Scalars["String"]["output"];
  provider: CloudAccountCloudProvider;
  providerId: Scalars["String"]["output"];
  regions: Array<Scalars["String"]["output"]>;
  regionsStatuses?: Maybe<Array<Maybe<RegionStatus>>>;
  resourceCleanerEnabled: Scalars["Boolean"]["output"];
  resourceDiscoveryEnabled: Scalars["Boolean"]["output"];
  status: CloudAccountStatus;
};

export enum CloudAccountCloudProvider {
  Aws = "AWS",
  Azure = "AZURE",
}

export enum CloudAccountRegionStatus {
  AccessError = "ACCESS_ERROR",
  AccessOk = "ACCESS_OK",
}

export enum CloudAccountStatus {
  AccessError = "ACCESS_ERROR",
  AccessMissing = "ACCESS_MISSING",
  AccessOk = "ACCESS_OK",
  InProgress = "IN_PROGRESS",
}

export type CloudAccountsPage = {
  __typename?: "CloudAccountsPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<Maybe<CloudAccount>>>;
};

export enum CloudAccountsSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Name = "NAME",
  Provider = "PROVIDER",
  ResourceCleanerEnabled = "RESOURCE_CLEANER_ENABLED",
  ResourceDiscoveryEnabled = "RESOURCE_DISCOVERY_ENABLED",
  Status = "STATUS",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type CloudAccountsSortInput = {
  field: CloudAccountsSortFields;
  order?: InputMaybe<SortOrder>;
};

export type CreateAwsLambdaInput = {
  /** ARN of the AWS Lambda function */
  lambdaArn: Scalars["String"]["input"];
};

export type CreateActionInput = {
  /** Color of action in hexadecimal numeral system e.g. #FFFFFF. Used for presentation purposes */
  color: Scalars["String"]["input"];
  /** Additional description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Action name */
  name: Scalars["String"]["input"];
  /** Defines what parameters can be defined for the action */
  parameterDefinitions: Array<CreateActionParameterDefinitionInput>;
  /** Scope of the action */
  scope: ActionScope;
  /** Determines whether the action is executed in a synchronous or asynchronous way */
  synchronous: Scalars["Boolean"]["input"];
  /** Determines type of the action and which source will be used */
  type: ActionType;
  /** Content of the action respectively based on the chosen type */
  typeProperties: CreateTypePropertiesInput;
};

export type CreateActionParameterDefinitionInput = {
  /** Optionally defines list of allowed values */
  allowedValues?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optionally defines the default value of the parameter */
  defaultValue?: InputMaybe<Scalars["String"]["input"]>;
  /** Optionally defines additional field description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Name of the parameter */
  name: Scalars["String"]["input"];
  /** Determines whether the parameter is required or not */
  required: Scalars["Boolean"]["input"];
  /** Type of the parameter */
  type: ActionParameterType;
};

export type CreateApiKeyInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Valid format - ISO 8601, example: 2023-11-04T15:20:06
   * If field is not provided then the API key will expire after one year.
   */
  expiresAt?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  owner: Scalars["String"]["input"];
};

export type CreateApiKeyResult = {
  __typename?: "CreateApiKeyResult";
  /** The API key. */
  apiKey: ApiKey;
  /** The actual API key secret to be used in requests authentication. Please save it as it cannot be retrieved later. */
  apiKeySecret: Scalars["String"]["output"];
};

export type CreateAzureFunctionInput = {
  /** Access key ID needed for authorization to execute Azure function */
  accessKeyId: Scalars["String"]["input"];
  /** Method used by the Azure function e.g. "GET", "POST", "PUT", "PATCH", "DELETE" */
  functionMethod: AzureFunctionMethod;
  /** URL of the Azure function */
  functionUrl: Scalars["String"]["input"];
};

export type CreateChannelTypeTypePropertiesInput = {
  /** Content of the Email type channel */
  email?: InputMaybe<CreateEmailInput>;
  /** Content of the Webhook type channel */
  webhook?: InputMaybe<CreateWebhookInput>;
};

export type CreateCloudAccountInput = {
  credentials: AccountCredentialsInput;
  description?: InputMaybe<Scalars["String"]["input"]>;
  environment?: InputMaybe<Scalars["String"]["input"]>;
  metadata?: InputMaybe<Array<KeyValueInput>>;
  name: Scalars["String"]["input"];
  provider: CloudAccountCloudProvider;
  providerId: Scalars["String"]["input"];
  regions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  resourceCleanerEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceDiscoveryEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CreateEmailInput = {
  email: Scalars["String"]["input"];
};

export type CreateGroupInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  metadata?: InputMaybe<Array<KeyValueInput>>;
  name: Scalars["String"]["input"];
  resourceSelectors: Array<CreateResourceSelectorInput>;
};

export type CreateNotificationGroupInput = {
  channels?: InputMaybe<Array<ChannelCreateInput>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type CreatePlanActionInput = {
  /** Action to be used in plan action */
  action: PlanActionIdInput;
  executionPolicy: ExecutionPolicy;
  /**
   * Names and values of parameters that are passed to the action.
   * They must comply with System Action's or Custom Action's parameter definitions.
   * Values of type other than string are JSON-serialized to string.
   */
  inputParameters?: InputMaybe<Array<KeyValueInput>>;
  /** Plan action name */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Plan action notification groups */
  notificationGroups?: InputMaybe<
    Array<CreatePlanActionNotificationGroupInput>
  >;
  /** Must be greater than 0 */
  order: Scalars["Int"]["input"];
  /** Resource group IDs that will be added to the plan action */
  resourceGroupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** If set to true, then an action will be performed one at a time for each resource. Default value is false if not provided. */
  runInSequence?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The skipWindow property can be set to one of the values: ALWAYS, ON_SUCCESS, or NEVER */
  skipWindow: SkipWindowType;
  /** Window duration in minutes must be greater than 0 */
  windowDuration: Scalars["Int"]["input"];
};

export type CreatePlanActionNotificationGroupInput = {
  inAdvance?: InputMaybe<Scalars["Int"]["input"]>;
  notificationGroupId: Scalars["ID"]["input"];
  scope: PlanActionNotificationGroupScope;
  triggerEvent: PlanActionNotificationTriggerType;
};

export type CreatePlanInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  enabled: Scalars["Boolean"]["input"];
  /** Metadata provided by user */
  metadata?: InputMaybe<Array<KeyValueInput>>;
  name: Scalars["String"]["input"];
  /** Plan notification groups to be added to plan */
  notificationGroups?: InputMaybe<Array<CreatePlanNotificationGroupInput>>;
  /** Actions to be added to plan. */
  planActions?: InputMaybe<Array<CreatePlanActionInput>>;
  /** Schedule cron e.g. 5 4 * * * */
  scheduleCron?: InputMaybe<Scalars["String"]["input"]>;
  /** Dates on which plan execution will be scheduled. When provided then `scheduleCron` and `scheduleOffset` must not be provided. */
  scheduleExecutionDates?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Schedule offset is a delay expressed in minutes that is added to cron-generated dates */
  scheduleOffset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Schedule time zone e.g. UTC. Required if `scheduleExecutionDates` or `scheduleCron` are set. */
  scheduleTimezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreatePlanNotificationGroupInput = {
  inAdvance?: InputMaybe<Scalars["Int"]["input"]>;
  notificationGroupId: Scalars["ID"]["input"];
  triggerEvent: PlanNotificationTriggerType;
};

export type CreateResourceInput = {
  /** Access needed for running commands on resource */
  access: ResourceAccessInput;
  /** Cloud resource ID e.g. instance ID for AWS, VM ID for Azure, instance ID for GC, virtual server instance ID for IBM Cloud. Field must be filled for all cloud providers excluding OTHER for which it will be filled automatically with the N/A value. */
  cloudResourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Additional description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** If set to false, it skips execution of all assigned actions to a particular resource. */
  enabled: Scalars["Boolean"]["input"];
  /** Full cloud resource ID is a full cloud identifier of a resource e.g. ARN for AWS, Resource ID for Azure, Resource Name for GC, CRN for IBM Cloud. Field must be filled for all cloud providers excluding OTHER. */
  fullCloudResourceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Metadata provided by user */
  metadata?: InputMaybe<Array<KeyValueInput>>;
  /** Resource name */
  name: Scalars["String"]["input"];
  /** Resource Operating System */
  os?: InputMaybe<ResourceOsInput>;
  /** Cloud provider */
  provider: ResourceCloudProvider;
  /** Provider ID is a provider account ID (AWS account ID, Azure subscription ID, GC project ID etc.) */
  providerId: Scalars["String"]["input"];
  /** Resource region */
  region: Scalars["String"]["input"];
};

export type CreateResourceSelectorInput = {
  order: Scalars["Int"]["input"];
  resourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  tagsExpression?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateRoleBindingInput = {
  roleId: Scalars["ID"]["input"];
  /** Email for USER type or API key id for API_KEY type. */
  subject: Scalars["String"]["input"];
  type: RbacRoleBindingType;
};

export type CreateSsmDocumentInput = {
  /** ARN or name of the AWS SSM document */
  documentId: Scalars["String"]["input"];
};

export type CreateScriptInput = {
  /** File contains the script */
  file: Scalars["Upload"]["input"];
};

export type CreateTypePropertiesInput = {
  /** Content of the AWS_LAMBDA type action */
  awsLambda?: InputMaybe<CreateAwsLambdaInput>;
  /** Content of the AZURE_FUNCTION type action */
  azureFunction?: InputMaybe<CreateAzureFunctionInput>;
  /** Details of the script file */
  script?: InputMaybe<CreateScriptInput>;
  /** Content of the SSM_DOCUMENT type action */
  ssmDocument?: InputMaybe<CreateSsmDocumentInput>;
  /** Content of the WEBHOOK type action */
  webhook?: InputMaybe<CreateWebhookActionInput>;
};

export type CreateWebhookActionInput = {
  /** Field that is attached header fields (additional context and metadata) to the webhook request */
  headers: Array<KeyValueInput>;
  /** Payload is the data used in the webhook request in stringified JSON format */
  payload: Scalars["String"]["input"];
  /** Method used to invoke webhook e.g. "GET", "POST", "PUT", "PATCH", "DELETE" */
  webhookMethod: WebhookActionMethod;
  /** URL of the Webhook */
  webhookUrl: Scalars["String"]["input"];
};

export type CreateWebhookInput = {
  headers?: InputMaybe<Array<KeyValueInput>>;
  method: Scalars["String"]["input"];
  /** Payload contains request body payload in stringified JSON format */
  payload?: InputMaybe<Scalars["String"]["input"]>;
  type: WebhookChannelType;
  url: Scalars["String"]["input"];
};

export type CredentialsAwsRoleInput = {
  arn: Scalars["String"]["input"];
  externalId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Customer = {
  __typename?: "Customer";
  customerSettings: CustomerSettings;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  organizationName: Scalars["String"]["output"];
};

export type CustomerSettings = {
  __typename?: "CustomerSettings";
  regions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type DateRange = {
  /**
   * The end date of the date range in ISO 8601 format with Coordinated Universal Time (UTC)
   * or local time zone (e.g., "2023-01-31T23:59:59Z" or "2023-01-31T23:59:59").
   */
  endDate: Scalars["String"]["input"];
  /**
   * The start date of the date range in ISO 8601 format with Coordinated Universal Time (UTC)
   * or local time zone (e.g., "2023-01-01T00:00:00Z" or "2023-01-01T00:00:00").
   */
  startDate: Scalars["String"]["input"];
};

export type EmailChannel = {
  __typename?: "EmailChannel";
  email: Scalars["String"]["output"];
};

export type Event = {
  __typename?: "Event";
  /** Event actions */
  actions?: Maybe<Array<EventAction>>;
  changeDetails: ChangeDetails;
  /** Additional description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Estimated end time of the event */
  estimatedEndTime: Scalars["String"]["output"];
  /** Event id */
  id: Scalars["UUID"]["output"];
  /** Event metadata */
  metadata?: Maybe<Array<KeyValue>>;
  /** Event name */
  name: Scalars["String"]["output"];
  /** Event notifications */
  notifications?: Maybe<Array<NotificationGroup>>;
  /** Event plan. If it is set to null then the plan has been removed. */
  plan?: Maybe<Plan>;
  /** Event schedule timezone e.g. UTC */
  scheduleTimezone: Scalars["String"]["output"];
  /** Event start time */
  startTime: Scalars["String"]["output"];
  /** Event status */
  status: EventStatus;
};

export type EventAction = {
  __typename?: "EventAction";
  /** Action */
  action?: Maybe<EventActionProperties>;
  /** Action name */
  actionName: Scalars["String"]["output"];
  /** List of event action attempts */
  attempts: Array<EventActionAttempt>;
  /** Event action execution policy */
  executionPolicy: ExecutionPolicy;
  /** Event action state */
  globalState?: Maybe<GlobalEventActionState>;
  /** Event action id */
  id: Scalars["UUID"]["output"];
  /**
   * Names and values of parameters that are passed to the action.
   * They must comply with System Action's or Custom Action's parameter definitions.
   * Values of type other than string are JSON-serialized to string.
   */
  inputParameters?: Maybe<Array<KeyValue>>;
  /** Event action name */
  name: Scalars["String"]["output"];
  /** Order of the event action. Must be greater or equal 0 */
  order: Scalars["Int"]["output"];
  /** Event action resource groups */
  resourceGroups?: Maybe<Array<Maybe<Group>>>;
  /** Event action merged resource statuses */
  resourceStates?: Maybe<Array<EventActionResourceState>>;
  /** If set to true then an action has been performed on each resource, one at a time. */
  runInSequence: Scalars["Boolean"]["output"];
  /** Event action scope */
  scope: EventActionScope;
  /** The skipWindow property can be set to one of the values: ALWAYS, ON_SUCCESS, or NEVER */
  skipWindow: SkipWindowType;
  /**
   * Event action first attempt state
   * @deprecated Use globalState instead
   */
  state?: Maybe<EventActionState>;
  /** Event action type */
  type: EventActionType;
  /** Event action window duration in minutes. Must be greater or equal 0 */
  windowDuration: Scalars["Int"]["output"];
};

export type EventActionAttempt = {
  __typename?: "EventActionAttempt";
  /** Determines which attempt it was in turn */
  attempt: Scalars["Int"]["output"];
  /** Event action attempt id */
  id: Scalars["UUID"]["output"];
  /** Event action attempt resource statuses */
  resourceStates?: Maybe<Array<EventActionResourceState>>;
  /** Event action attempt state */
  state?: Maybe<EventActionState>;
};

export type EventActionAttemptReport = {
  __typename?: "EventActionAttemptReport";
  attempt: Scalars["Int"]["output"];
  report: EventActionReport;
};

export type EventActionProperties = Action | SystemAction;

export type EventActionReport = {
  __typename?: "EventActionReport";
  /** Action name */
  actionName: Scalars["String"]["output"];
  /** Event action end time */
  endTime?: Maybe<Scalars["String"]["output"]>;
  /** Event action id */
  eventActionId: Scalars["UUID"]["output"];
  /** Event id */
  eventId: Scalars["UUID"]["output"];
  /** Event action name */
  name: Scalars["String"]["output"];
  /** Event action output, null for resource based action */
  output?: Maybe<Scalars["String"]["output"]>;
  /** Event action output URI, null for resource based action */
  outputUri?: Maybe<Scalars["String"]["output"]>;
  /** Event action resource reports */
  resources?: Maybe<Array<EventActionResourceReport>>;
  /** Event action start time */
  startTime?: Maybe<Scalars["String"]["output"]>;
  /** Event action execution status */
  status: ExecutionStatus;
};

export type EventActionResourceReport = {
  __typename?: "EventActionResourceReport";
  /** Resource name */
  name: Scalars["String"]["output"];
  /** Resource id */
  resourceId: Scalars["UUID"]["output"];
  /** Parsed report result */
  result: EventActionResourceReportResult;
  /** Resource execution status */
  status: ResourceExecutionStatus;
};

export type EventActionResourceReportResult = {
  __typename?: "EventActionResourceReportResult";
  /** List of available (not installed) packages */
  available?: Maybe<Array<ResourceReportPackage>>;
  /** List of packages that failed due to errors */
  failed?: Maybe<Array<ResourceReportPackage>>;
  /** List of installed packages */
  installed?: Maybe<Array<ResourceReportPackage>>;
  /** Raw output for non-patching actions, null for patching action */
  output?: Maybe<Scalars["String"]["output"]>;
  /** Resource output URI for non-patching actions, null for patching action */
  outputUri?: Maybe<Scalars["String"]["output"]>;
  /** List of removed packages */
  removed?: Maybe<Array<ResourceReportPackage>>;
  /** List of updated packages */
  updated?: Maybe<Array<ResourceReportPackage>>;
};

export type EventActionResourceState = {
  __typename?: "EventActionResourceState";
  /** Annotation for manual status change */
  annotation: Scalars["String"]["output"];
  changeDetails: ChangeDetails;
  /** Event action id */
  eventActionId: Scalars["UUID"]["output"];
  /** Resource state id */
  id: Scalars["UUID"]["output"];
  /** Resource state order */
  order: Scalars["Int"]["output"];
  /** Resource state output */
  output?: Maybe<Array<Maybe<KeyValue>>>;
  /** Resource state output URI */
  outputUri?: Maybe<Scalars["String"]["output"]>;
  /** Resource state resource */
  resource?: Maybe<Resource>;
  /** Resource state resource group */
  resourceGroup?: Maybe<Group>;
  /** Resource group id */
  resourceGroupId: Scalars["UUID"]["output"];
  /** Resource id */
  resourceId: Scalars["UUID"]["output"];
  /** Resource state status */
  status: ResourceExecutionStatus;
};

export enum EventActionScope {
  Plan = "PLAN",
  Resource = "RESOURCE",
}

export type EventActionState = {
  __typename?: "EventActionState";
  approvedAt?: Maybe<Scalars["String"]["output"]>;
  /** Event action approval */
  approvedBy?: Maybe<Scalars["String"]["output"]>;
  changeDetails: ChangeDetails;
  /** Event action end time */
  endTime?: Maybe<Scalars["String"]["output"]>;
  /** Event action estimated start time */
  estimatedStartTime: Scalars["String"]["output"];
  /** Event action id */
  eventActionId: Scalars["UUID"]["output"];
  /** Event id */
  eventId: Scalars["UUID"]["output"];
  /** Event action output */
  output?: Maybe<Array<Maybe<KeyValue>>>;
  /** Event action output URI */
  outputUri?: Maybe<Scalars["String"]["output"]>;
  /** Event action start time */
  startTime?: Maybe<Scalars["String"]["output"]>;
  /** Event action execution status */
  status: ExecutionStatus;
};

export enum EventActionType {
  Custom = "CUSTOM",
  System = "SYSTEM",
}

export type EventReport = {
  __typename?: "EventReport";
  /** Event actions reports */
  actions?: Maybe<Array<EventActionReport>>;
  /** Event end time */
  endTime?: Maybe<Scalars["String"]["output"]>;
  /** Event id */
  eventId: Scalars["UUID"]["output"];
  /** Event name */
  name: Scalars["String"]["output"];
  /** Event start time */
  startTime?: Maybe<Scalars["String"]["output"]>;
  /** Event status */
  status: EventStatus;
};

export enum EventStatus {
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  PartialSuccess = "PARTIAL_SUCCESS",
  Revoked = "REVOKED",
  Success = "SUCCESS",
  Upcoming = "UPCOMING",
  WaitingForApproval = "WAITING_FOR_APPROVAL",
}

export enum EventsFilterByFields {
  StartTime = "START_TIME",
}

export type EventsFilterByInput = {
  field: EventsFilterByFields;
  operator: FilterComparisonOperator;
  values?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type EventsFilterInput = {
  filterBy?: InputMaybe<Array<EventsFilterByInput>>;
};

export type EventsPage = {
  __typename?: "EventsPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<Event>>;
};

export enum EventsSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  EstimatedEndTime = "ESTIMATED_END_TIME",
  Name = "NAME",
  StartTime = "START_TIME",
  Status = "STATUS",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type EventsSortInput = {
  field: EventsSortFields;
  order?: InputMaybe<SortOrder>;
};

export enum ExecutionPolicy {
  Anyway = "ANYWAY",
  Approval = "APPROVAL",
  SuccessOrApproval = "SUCCESS_OR_APPROVAL",
}

export enum ExecutionStatus {
  ApprovalRequired = "APPROVAL_REQUIRED",
  ApprovalTimeout = "APPROVAL_TIMEOUT",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  PartialSuccess = "PARTIAL_SUCCESS",
  Success = "SUCCESS",
  Timeout = "TIMEOUT",
  Upcoming = "UPCOMING",
}

export enum FilterComparisonOperator {
  Eq = "EQ",
  Ge = "GE",
  Gt = "GT",
  Le = "LE",
  Lt = "LT",
  Ne = "NE",
}

export type GenerateDatesFromCronInput = {
  /** Optionally defines how many dates should be generated. Allowed range 1 - 100. Default: 3. */
  countLimit?: InputMaybe<Scalars["Int"]["input"]>;
  /** Defines the cron expression as the basis for date generation. */
  cronExpression: Scalars["String"]["input"];
  /** Defines the timezone in which the dates are generated. The timezone must be a valid IANA timezone identifier. */
  location: Scalars["String"]["input"];
  /** Optionally defines the number of minutes (positive or negative) added to or subtracted from each generated date. */
  offsetInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GenerateDatesFromCronResult = {
  __typename?: "GenerateDatesFromCronResult";
  /** List of generated dates in ISO 8601 format. */
  dates?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Any error encountered during the dates generation process. */
  error?: Maybe<Scalars["String"]["output"]>;
  /** Message displayed when generated dates do not comply with Fleet Control rules. */
  validationMsg?: Maybe<Scalars["String"]["output"]>;
};

export type GlobalEventActionState = {
  __typename?: "GlobalEventActionState";
  /** Event action end time */
  endTime?: Maybe<Scalars["String"]["output"]>;
  /** Event action estimated start time */
  estimatedStartTime: Scalars["String"]["output"];
  /** Event action output URI */
  outputUri?: Maybe<Scalars["String"]["output"]>;
  /** Event action start time */
  startTime?: Maybe<Scalars["String"]["output"]>;
  /** Event action execution status */
  status: ExecutionStatus;
};

export type Group = {
  __typename?: "Group";
  changeDetails: ChangeDetails;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  metadata?: Maybe<Array<KeyValue>>;
  name: Scalars["String"]["output"];
  plans?: Maybe<Array<Maybe<Plan>>>;
  resourceSelectors?: Maybe<Array<ResourceSelector>>;
  totalNumberOfResources: Scalars["Int"]["output"];
};

export type GroupsPage = {
  __typename?: "GroupsPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<Group>>;
};

export enum GroupsSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Name = "NAME",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type GroupsSortInput = {
  field: GroupsSortFields;
  order?: InputMaybe<SortOrder>;
};

export type HostAccessInput = {
  credentialsId: Scalars["String"]["input"];
  ssmAccess: HostSsmAccessInput;
  type: ResourceAccessType;
};

export type HostInput = {
  access?: InputMaybe<HostAccessInput>;
  cloudHostId: Scalars["String"]["input"];
  cloudMetadata: Array<KeyValueInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  fullCloudResourceId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  os?: InputMaybe<ResourceOsInput>;
  provider: ResourceCloudProvider;
  providerId: Scalars["String"]["input"];
  region: Scalars["String"]["input"];
  tags: Array<KeyValueInput>;
};

export type HostSsmAccessInput = {
  instanceId: Scalars["String"]["input"];
  region: Scalars["String"]["input"];
};

export type IncomingEvent = {
  __typename?: "IncomingEvent";
  changeDetails: ChangeDetails;
  /** Estimated end time of the event */
  estimatedEndTime: Scalars["String"]["output"];
  /** Incoming event id */
  id: Scalars["UUID"]["output"];
  /** Event name */
  name: Scalars["String"]["output"];
  /** Event plan. If it is set to null then the plan has been removed. */
  plan?: Maybe<Plan>;
  /** Event schedule timezone e.g. UTC */
  scheduleTimezone: Scalars["String"]["output"];
  /** Start time of the event */
  startTime: Scalars["String"]["output"];
  /** Event status */
  status: IncomingEventStatus;
};

export type IncomingEventPage = {
  __typename?: "IncomingEventPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<IncomingEvent>>;
};

export enum IncomingEventStatus {
  Revoked = "REVOKED",
  Upcoming = "UPCOMING",
}

export enum IncomingEventsFilterByFields {
  StartTime = "START_TIME",
}

export type IncomingEventsFilterByInput = {
  field: IncomingEventsFilterByFields;
  operator: FilterComparisonOperator;
  values?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type IncomingEventsFilterInput = {
  filterBy?: InputMaybe<Array<IncomingEventsFilterByInput>>;
};

export enum IncomingEventsSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  EstimatedEndTime = "ESTIMATED_END_TIME",
  StartTime = "START_TIME",
  Status = "STATUS",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type IncomingEventsSortInput = {
  field: IncomingEventsSortFields;
  order?: InputMaybe<SortOrder>;
};

export enum InventoryStatus {
  Error = "ERROR",
  Success = "SUCCESS",
}

export type KeyValue = {
  __typename?: "KeyValue";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type KeyValueInput = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** This mutation approves event action */
  approveEventAction: Scalars["Boolean"]["output"];
  /** This mutation allows to change resource execution status with annotation */
  changeResourceExecutionStatus: Scalars["Boolean"]["output"];
  /** The mutation creates action */
  createAction: Action;
  /** The mutation creates a new API key for any entity. */
  createApiKey: CreateApiKeyResult;
  /** This mutation creates cloud account */
  createCloudAccount: CloudAccount;
  /** The mutation creates group. */
  createGroup: Group;
  /** The mutation creates a notification group */
  createNotificationGroup: NotificationGroup;
  /** The mutation creates plan */
  createPlan: Plan;
  /** Creates new resource. Returns newly created resource */
  createResource: Resource;
  /** The mutation creates new RBAC role */
  createRole: Role;
  /** The mutation creates Role Binding. */
  createRoleBinding: RoleBinding;
  /** The mutation generates dates based on cron expression. */
  generateDatesFromCron: GenerateDatesFromCronResult;
  /** Ping resource based on provided identifier */
  pingResource?: Maybe<Scalars["Boolean"]["output"]>;
  /** The mutation removes action */
  removeAction: Scalars["Boolean"]["output"];
  /** The mutation removes the API key. */
  removeApiKey: Scalars["Boolean"]["output"];
  /** This mutation removes cloud account */
  removeCloudAccount: Scalars["Boolean"]["output"];
  /** The mutation removes group. */
  removeGroup: Scalars["Boolean"]["output"];
  /** The mutation removes a notification group */
  removeNotificationGroup: Scalars["Boolean"]["output"];
  /** The mutation removes plan */
  removePlan: Scalars["Boolean"]["output"];
  /** Remove resource based on provided identifier */
  removeResource?: Maybe<Scalars["Boolean"]["output"]>;
  /** The mutation removes RBAC role based on provided identifier */
  removeRole: Scalars["Boolean"]["output"];
  /** The mutation removes Role Binding. */
  removeRoleBinding: Scalars["Boolean"]["output"];
  /** This mutation reruns failed event action */
  rerunEventAction: Scalars["Boolean"]["output"];
  /** This mutation sets REVOKED status for the incoming event */
  revokeIncomingEvent: Scalars["Boolean"]["output"];
  /** This mutation invokes a synchronous account scan */
  scanCloudAccount: Scalars["Boolean"]["output"];
  /** The mutation triggers plan */
  triggerPlan: Event;
  /** The mutation updates action */
  updateAction: Action;
  /** The mutation updates API key */
  updateApiKey: ApiKey;
  /** This mutation updates cloud account */
  updateCloudAccount: CloudAccount;
  /** The mutation updates group. */
  updateGroup: Group;
  /** The mutation updates a notification group */
  updateNotificationGroup: NotificationGroup;
  /** The mutation updates plan */
  updatePlan: Plan;
  /** Updates resource. Returns updated resource. */
  updateResource: Resource;
  /** The mutation updates RBAC role */
  updateRole: Role;
  /** This query creates if not exist or updates if exists a host using the host installer */
  upsertHost: Scalars["Boolean"]["output"];
};

export type MutationApproveEventActionArgs = {
  eventActionId: Scalars["UUID"]["input"];
  eventId: Scalars["UUID"]["input"];
};

export type MutationChangeResourceExecutionStatusArgs = {
  input: ChangeResourceExecutionStatusInput;
};

export type MutationCreateActionArgs = {
  input: CreateActionInput;
};

export type MutationCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};

export type MutationCreateCloudAccountArgs = {
  input: CreateCloudAccountInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateNotificationGroupArgs = {
  input: CreateNotificationGroupInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

export type MutationCreateRoleArgs = {
  input: RoleInput;
};

export type MutationCreateRoleBindingArgs = {
  input: CreateRoleBindingInput;
};

export type MutationGenerateDatesFromCronArgs = {
  input: GenerateDatesFromCronInput;
};

export type MutationPingResourceArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveActionArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveApiKeyArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveCloudAccountArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveGroupArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveNotificationGroupArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemovePlanArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveResourceArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveRoleArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationRemoveRoleBindingArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRerunEventActionArgs = {
  input: RerunEventActionInput;
};

export type MutationRevokeIncomingEventArgs = {
  eventId: Scalars["UUID"]["input"];
};

export type MutationScanCloudAccountArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationTriggerPlanArgs = {
  id: Scalars["UUID"]["input"];
};

export type MutationUpdateActionArgs = {
  input: UpdateActionInput;
};

export type MutationUpdateApiKeyArgs = {
  input: UpdateApiKeyInput;
};

export type MutationUpdateCloudAccountArgs = {
  input: UpdateCloudAccountInput;
};

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};

export type MutationUpdateNotificationGroupArgs = {
  input: UpdateNotificationGroupInput;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

export type MutationUpdateRoleArgs = {
  input: RoleUpdateInput;
};

export type MutationUpsertHostArgs = {
  input: UpsertHostInput;
};

export type NotificationGroup = {
  __typename?: "NotificationGroup";
  changeDetails: ChangeDetails;
  channels?: Maybe<Array<Channel>>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  plans?: Maybe<Array<Plan>>;
};

export type NotificationGroupPage = {
  __typename?: "NotificationGroupPage";
  /** Information about returned page */
  pageInfo: PageInfo;
  /** List of notification groups */
  result?: Maybe<Array<NotificationGroup>>;
};

export enum NotificationGroupSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Name = "NAME",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type NotificationGroupSortInput = {
  field: NotificationGroupSortFields;
  order?: InputMaybe<SortOrder>;
};

export enum OsSubType {
  Amazon = "AMAZON",
  Centos = "CENTOS",
  Debian = "DEBIAN",
  OracleLinux = "ORACLE_LINUX",
  Redhat = "REDHAT",
  Suse = "SUSE",
  Ubuntu = "UBUNTU",
  WindowsServer = "WINDOWS_SERVER",
}

export enum OsType {
  Linux = "LINUX",
  Windows = "WINDOWS",
}

export type PageInfo = {
  __typename?: "PageInfo";
  count: Scalars["Int"]["output"];
  pages: Scalars["Int"]["output"];
};

export type Plan = {
  __typename?: "Plan";
  changeDetails: ChangeDetails;
  description?: Maybe<Scalars["String"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** Metadata provided by user */
  metadata?: Maybe<Array<KeyValue>>;
  name: Scalars["String"]["output"];
  /** Plan next three execution dates */
  nextExecutionDates?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Plan notification groups */
  notificationGroups?: Maybe<Array<PlanNotificationGroup>>;
  /** Plan actions */
  planActions?: Maybe<Array<PlanAction>>;
  /** Schedule cron e.g. 5 4 * * * */
  scheduleCron?: Maybe<Scalars["String"]["output"]>;
  /** Dates on which plan execution will be scheduled. */
  scheduleExecutionDates?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Schedule offset is a delay expressed in minutes that is added to cron-generated dates */
  scheduleOffset: Scalars["Int"]["output"];
  /** Schedule time zone e.g. UTC */
  scheduleTimezone?: Maybe<Scalars["String"]["output"]>;
};

export type PlanAction = {
  __typename?: "PlanAction";
  action: PlanActionProperties;
  /** Execution policy provided by user */
  executionPolicy: ExecutionPolicy;
  /** Plan action id */
  id: Scalars["UUID"]["output"];
  /**
   * Names and values of parameters that are passed to the action.
   * They must comply with System Action's or Custom Action's parameter definitions.
   * Values of type other than string are JSON-serialized to string.
   */
  inputParameters?: Maybe<Array<KeyValue>>;
  /** Plan action name */
  name: Scalars["String"]["output"];
  /** Plan action notification groups */
  notificationGroups?: Maybe<Array<PlanActionNotificationGroup>>;
  /** Must be greater than 0 */
  order: Scalars["Int"]["output"];
  /** Resource groups that belong to plan action */
  resourceGroups?: Maybe<Array<Group>>;
  /** If set to true, then an action will be performed one at a time for each resource. */
  runInSequence: Scalars["Boolean"]["output"];
  /** The skipWindow property can be set to one of the values: ALWAYS, ON_SUCCESS, or NEVER */
  skipWindow: SkipWindowType;
  /** Window duration in minutes must be greater than 0 */
  windowDuration: Scalars["Int"]["output"];
};

export type PlanActionIdInput = {
  /** Action id */
  actionId: Scalars["UUID"]["input"];
  /** Action type */
  actionType: PlanActionType;
};

export type PlanActionNotificationGroup = {
  __typename?: "PlanActionNotificationGroup";
  id: Scalars["ID"]["output"];
  inAdvance?: Maybe<Scalars["Int"]["output"]>;
  notificationGroup: NotificationGroup;
  scope: PlanActionNotificationGroupScope;
  triggerEvent: PlanActionNotificationTriggerType;
};

export enum PlanActionNotificationGroupScope {
  Action = "ACTION",
  Resource = "RESOURCE",
}

export enum PlanActionNotificationTriggerType {
  /** @deprecated Use FAILED instead */
  EventActionsFailed = "EVENT_ACTIONS_FAILED",
  /** @deprecated Use SUCCESSFUL instead */
  EventActionsFinished = "EVENT_ACTIONS_FINISHED",
  /** @deprecated Use UPCOMING instead */
  EventActionsIncoming = "EVENT_ACTIONS_INCOMING",
  /** @deprecated Use NOT_APPROVED instead */
  EventActionsNotApproved = "EVENT_ACTIONS_NOT_APPROVED",
  /** @deprecated Use PARTIALLY_SUCCESSFUL instead */
  EventActionsPartiallySucceeded = "EVENT_ACTIONS_PARTIALLY_SUCCEEDED",
  /** @deprecated Use START instead */
  EventActionsStarted = "EVENT_ACTIONS_STARTED",
  /** @deprecated Use SUCCESSFUL instead */
  EventActionsSucceeded = "EVENT_ACTIONS_SUCCEEDED",
  /** @deprecated Use RESOURCES_ERROR instead */
  EventActionResourcesError = "EVENT_ACTION_RESOURCES_ERROR",
  /** @deprecated Use RESOURCES_FAILED instead */
  EventActionResourcesFailed = "EVENT_ACTION_RESOURCES_FAILED",
  /** @deprecated Use RESOURCES_SUCCESSFUL instead */
  EventActionResourcesFinished = "EVENT_ACTION_RESOURCES_FINISHED",
  /** @deprecated Use RESOURCES_NOT_IN_TIME_WINDOW instead */
  EventActionResourcesNotInTimeWindow = "EVENT_ACTION_RESOURCES_NOT_IN_TIME_WINDOW",
  /** @deprecated Use RESOURCES_START instead */
  EventActionResourcesStarted = "EVENT_ACTION_RESOURCES_STARTED",
  /** @deprecated Use RESOURCES_SUCCESSFUL instead */
  EventActionResourcesSucceeded = "EVENT_ACTION_RESOURCES_SUCCEEDED",
  /** @deprecated Use RESOURCES_TIMEOUT instead */
  EventActionResourcesTimeout = "EVENT_ACTION_RESOURCES_TIMEOUT",
  Failed = "FAILED",
  NotApproved = "NOT_APPROVED",
  PartiallySuccessful = "PARTIALLY_SUCCESSFUL",
  ResourcesError = "RESOURCES_ERROR",
  ResourcesFailed = "RESOURCES_FAILED",
  ResourcesNotInTimeWindow = "RESOURCES_NOT_IN_TIME_WINDOW",
  ResourcesStart = "RESOURCES_START",
  ResourcesSuccessful = "RESOURCES_SUCCESSFUL",
  ResourcesTimeout = "RESOURCES_TIMEOUT",
  Start = "START",
  Successful = "SUCCESSFUL",
  Upcoming = "UPCOMING",
}

export type PlanActionProperties = Action | SystemAction;

export enum PlanActionType {
  Custom = "CUSTOM",
  System = "SYSTEM",
}

export type PlanNotificationGroup = {
  __typename?: "PlanNotificationGroup";
  id: Scalars["ID"]["output"];
  inAdvance?: Maybe<Scalars["Int"]["output"]>;
  notificationGroup: NotificationGroup;
  triggerEvent: PlanNotificationTriggerType;
};

export enum PlanNotificationTriggerType {
  Created = "CREATED",
  Deleted = "DELETED",
  /** @deprecated Use FAILED instead */
  EventsFailed = "EVENTS_FAILED",
  /** @deprecated Use FINISHED instead */
  EventsFinished = "EVENTS_FINISHED",
  /** @deprecated Use INCOMING instead */
  EventsIncoming = "EVENTS_INCOMING",
  /** @deprecated Use PARTIALLY_SUCCESSFUL instead */
  EventsPartiallySucceeded = "EVENTS_PARTIALLY_SUCCEEDED",
  /** @deprecated Use START instead */
  EventsStarted = "EVENTS_STARTED",
  /** @deprecated Use SUCCESSFUL instead */
  EventsSucceeded = "EVENTS_SUCCEEDED",
  Failed = "FAILED",
  Finished = "FINISHED",
  PartiallySuccessful = "PARTIALLY_SUCCESSFUL",
  /** @deprecated Use CREATED instead */
  PlansCreated = "PLANS_CREATED",
  /** @deprecated Use DELETED instead */
  PlansDeleted = "PLANS_DELETED",
  /** @deprecated Use UPDATED instead */
  PlansUpdated = "PLANS_UPDATED",
  Start = "START",
  Successful = "SUCCESSFUL",
  Upcoming = "UPCOMING",
  Updated = "UPDATED",
}

export type PlanPage = {
  __typename?: "PlanPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<Plan>>;
};

export enum PlanSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Enabled = "ENABLED",
  Name = "NAME",
  ScheduleCron = "SCHEDULE_CRON",
  ScheduleTimezone = "SCHEDULE_TIMEZONE",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type PlanSortInput = {
  field: PlanSortFields;
  order?: InputMaybe<SortOrder>;
};

export type Query = {
  __typename?: "Query";
  /** The query returns a specified action. */
  action?: Maybe<Action>;
  /** The query returns actions with pagination. */
  actions: ActionPage;
  /** This query returns existing activation or creates new if the activation limit is exceeded. When there is a need to create a new customer account exception is thrown. */
  activation: Activation;
  /** The query returns API key. */
  apiKey?: Maybe<ApiKey>;
  /** The query returns paginated list of API key. */
  apiKeys: ApiKeysPage;
  /** This query returns CFN template that can be used for AWS Cloud Account onboarding */
  awsOnboardingTemplate: Scalars["String"]["output"];
  /** This query returns Bash script that can be used for Azure Cloud Account onboarding */
  azureOnboardingScript: Scalars["String"]["output"];
  /** This query initiates bundled report generation for each unique date range and retrieves bundled report info. */
  bundledReport?: Maybe<BundledReportInfo>;
  /** This query returns specified cloud account */
  cloudAccount?: Maybe<CloudAccount>;
  /** This query returns customer cloud accounts with pagination */
  cloudAccounts: CloudAccountsPage;
  /** The query returns a list of customers to which the user or API key has an access. */
  customers: Array<Customer>;
  /** This query returns specified event */
  event?: Maybe<Event>;
  /** This query returns report for single event action */
  eventActionReport?: Maybe<EventActionReport>;
  /** This query returns reports for all event action attempts */
  eventActionReports?: Maybe<Array<EventActionAttemptReport>>;
  /** This query returns report for the whole event including all actions and resources */
  eventReport?: Maybe<EventReport>;
  /** This query returns events with pagination */
  events: EventsPage;
  /** The query returns a specified group. */
  group?: Maybe<Group>;
  /** The query returns groups with pagination. */
  groups: GroupsPage;
  /** This query returns specified incoming event */
  incomingEvent?: Maybe<IncomingEvent>;
  /** This query returns incoming events with pagination */
  incomingEvents: IncomingEventPage;
  /** The query returns a specified notification group. */
  notificationGroup?: Maybe<NotificationGroup>;
  /** The query returns notification groups with pagination. */
  notificationGroups: NotificationGroupPage;
  /** The query returns a specified plan. */
  plan?: Maybe<Plan>;
  /** The query returns plans with pagination. */
  plans: PlanPage;
  /** This query returns presigned URL for given event action and event action resource output URL */
  presignedEventActionOutputUrl?: Maybe<Scalars["String"]["output"]>;
  /** This registration query verifies if the registration exists for the given managed node id. */
  registration: Registration;
  /** Returns single resource based on provided identifier */
  resource?: Maybe<Resource>;
  /** Returns list of resources */
  resources?: Maybe<ResourcesPage>;
  /** Returns list of resources by provided tags expression */
  resourcesByTags?: Maybe<ResourcesPage>;
  /** Returns list of resources by provided search expression */
  resourcesSearch?: Maybe<ResourcesPage>;
  /** The query returns single role based on provided identifier */
  role?: Maybe<Role>;
  /** The query returns Role Binding. */
  roleBinding?: Maybe<RoleBinding>;
  /** The query returns paginated list of Role Bindings. */
  roleBindings?: Maybe<RoleBindingsPage>;
  /** The query returns paginated list of Role Bindings grouped by Users. */
  roleBindingsByUsers?: Maybe<RoleBindingsByUsersPage>;
  /** The query returns list of roles */
  roles: RolesPage;
  /** The query returns a script content for a specified action. */
  scriptContent: Scalars["String"]["output"];
  /** The query returns a specified system action. */
  systemAction?: Maybe<SystemAction>;
  /** The query returns system actions with pagination. */
  systemActions: SystemActionPage;
};

export type QueryActionArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryActionsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ActionSortInput>;
};

export type QueryApiKeyArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryApiKeysArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ApiKeysSortInput>;
};

export type QueryAzureOnboardingScriptArgs = {
  subscriptionId: Scalars["UUID"]["input"];
};

export type QueryBundledReportArgs = {
  input: BundledReportInput;
};

export type QueryCloudAccountArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryCloudAccountsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<CloudAccountsSortInput>;
};

export type QueryEventArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryEventActionReportArgs = {
  eventActionId: Scalars["UUID"]["input"];
  eventId: Scalars["UUID"]["input"];
};

export type QueryEventActionReportsArgs = {
  eventActionId: Scalars["UUID"]["input"];
  eventId: Scalars["UUID"]["input"];
};

export type QueryEventReportArgs = {
  eventId: Scalars["UUID"]["input"];
};

export type QueryEventsArgs = {
  filter?: InputMaybe<EventsFilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<EventsSortInput>;
};

export type QueryGroupArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryGroupsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<GroupsSortInput>;
};

export type QueryIncomingEventArgs = {
  eventId: Scalars["UUID"]["input"];
};

export type QueryIncomingEventsArgs = {
  filter?: InputMaybe<IncomingEventsFilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<IncomingEventsSortInput>;
};

export type QueryNotificationGroupArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryNotificationGroupsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<NotificationGroupSortInput>;
};

export type QueryPlanArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryPlansArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<PlanSortInput>;
};

export type QueryPresignedEventActionOutputUrlArgs = {
  url: Scalars["String"]["input"];
};

export type QueryRegistrationArgs = {
  input: RegistrationInput;
};

export type QueryResourceArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryResourcesArgs = {
  filter?: InputMaybe<ResourceFilterInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ResourcesSortInput>;
};

export type QueryResourcesByTagsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  tagsExpression: Scalars["String"]["input"];
};

export type QueryResourcesSearchArgs = {
  input?: InputMaybe<ResourcesSearchInput>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ResourcesSortInput>;
};

export type QueryRoleArgs = {
  id: Scalars["UUID"]["input"];
};

export type QueryRoleBindingArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryRoleBindingsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<RoleBindingsSortInput>;
};

export type QueryRoleBindingsByUsersArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<RoleBindingsSortInput>;
};

export type QueryRolesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<RolesSortInput>;
};

export type QueryScriptContentArgs = {
  actionId: Scalars["UUID"]["input"];
};

export type QuerySystemActionArgs = {
  id: Scalars["UUID"]["input"];
};

export type QuerySystemActionsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<SystemActionsSortInput>;
};

/** All available actions on FleetControl resources. Choose ANY to allow the use of all actions. */
export enum RbacAction {
  Any = "ANY",
  Create = "CREATE",
  Delete = "DELETE",
  Execute = "EXECUTE",
  Update = "UPDATE",
  View = "VIEW",
}

/** All available FleetControl resources. Choose ANY to grant access to all resources. */
export enum RbacResource {
  Action = "ACTION",
  Activation = "ACTIVATION",
  Any = "ANY",
  ApiKey = "API_KEY",
  CloudAccount = "CLOUD_ACCOUNT",
  Event = "EVENT",
  NotificationGroup = "NOTIFICATION_GROUP",
  Plan = "PLAN",
  Report = "REPORT",
  Resource = "RESOURCE",
  ResourceGroup = "RESOURCE_GROUP",
  Role = "ROLE",
  RoleBinding = "ROLE_BINDING",
}

export enum RbacRoleBindingType {
  ApiKey = "API_KEY",
  User = "USER",
}

export type RegionStatus = {
  __typename?: "RegionStatus";
  details?: Maybe<Scalars["String"]["output"]>;
  region: Scalars["String"]["output"];
  status: CloudAccountRegionStatus;
};

export type Registration = {
  __typename?: "Registration";
  /** The credentials ID for the customer account that has the managed node registered in SSM. */
  credentialsId?: Maybe<Scalars["String"]["output"]>;
};

export type RegistrationInput = {
  /** The manged node id is the the machine identifier in AWS Systems Manager. */
  managedNodeId: Scalars["String"]["input"];
  /** The region is the AWS region where the managed node is located. */
  region: Scalars["String"]["input"];
};

export enum ReportStatus {
  /** An error occurred during report generation. */
  Error = "ERROR",
  /** The report is being generated. */
  Pending = "PENDING",
  /** The report is available for download. */
  Ready = "READY",
}

export enum ReportType {
  /** The report in CSV format contains a list of events. */
  CsvEventsList = "CSV_EVENTS_LIST",
}

export type RerunEventActionInput = {
  eventActionId: Scalars["UUID"]["input"];
  eventId: Scalars["UUID"]["input"];
};

export type Resource = {
  __typename?: "Resource";
  /** Access needed for running commands on resource */
  access?: Maybe<ResourceAccess>;
  /** Determines whether resource has been discovered automatically by Klarity FleetControl or has been added manually by user */
  autoDiscovery: Scalars["Boolean"]["output"];
  /** Change details represent when and by whom data was created or modified */
  changeDetails: ChangeDetails;
  /** Additional metadata fetched from cloud provider */
  cloudMetadata?: Maybe<Array<KeyValue>>;
  /** Cloud resource ID e.g. instance ID for AWS, VM ID for Azure, instance ID for GC, virtual server instance ID for IBM Cloud and N/A for OTHER. */
  cloudResourceId: Scalars["String"]["output"];
  /** Additional description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** If set to false, it skips execution of all assigned actions to a particular resource. */
  enabled: Scalars["Boolean"]["output"];
  /** Full cloud resource ID is a full cloud identifier of a resource e.g. ARN for AWS, Resource ID for Azure, Resource Name for GC, CRN for IBM Cloud and N/A for OTHER. */
  fullCloudResourceId: Scalars["String"]["output"];
  /** Resource identifier */
  id: Scalars["String"]["output"];
  /** Latest available update */
  latestInventory?: Maybe<ResourceInventory>;
  /** Metadata provided by user */
  metadata?: Maybe<Array<KeyValue>>;
  /** Resource name */
  name: Scalars["String"]["output"];
  /** Resource Operating System */
  os?: Maybe<ResourceOs>;
  /** Cloud provider */
  provider: ResourceCloudProvider;
  /** Provider ID is a provider account ID (AWS account ID, Azure subscription ID, GC project ID etc.) */
  providerId: Scalars["String"]["output"];
  /** Resource region */
  region: Scalars["String"]["output"];
  /** Indicates if resource is scanned from Cloud Account or manually onboarded */
  scanned: Scalars["Boolean"]["output"];
  /** Resource state - connection status and other information */
  state?: Maybe<ResourceState>;
  /** Resource tags from cloud provider */
  tags?: Maybe<Array<KeyValue>>;
};

/** Represents access to resource */
export type ResourceAccess = SsmAccess;

export type ResourceAccessInput = {
  /** Access to resource using AWS SSM service */
  ssmAccess: SsmAccessInput;
  /** Type of resource access. It determines which field have to be filled, */
  type: ResourceAccessType;
};

export enum ResourceAccessType {
  Ssm = "SSM",
}

export type ResourceAccessUpdateInput = {
  /** Access to resource using AWS SSM service */
  ssmAccess: SsmAccessUpdateInput;
  /** Type of resource access. It determines which field have to be filled, */
  type: ResourceAccessType;
};

export enum ResourceCloudProvider {
  Aws = "AWS",
  Azure = "AZURE",
  Gc = "GC",
  Ibm = "IBM",
  Other = "OTHER",
}

export enum ResourceExecutionStatus {
  Error = "ERROR",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  ManualSuccess = "MANUAL_SUCCESS",
  NotInTimeWindow = "NOT_IN_TIME_WINDOW",
  Pending = "PENDING",
  Success = "SUCCESS",
  Timeout = "TIMEOUT",
}

export enum ResourceExecutionStatusManual {
  ManualSuccess = "MANUAL_SUCCESS",
}

export type ResourceFilterInput = {
  cloudResourceId?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResourceInventory = {
  __typename?: "ResourceInventory";
  availablePackagesUri?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["String"]["output"];
  error?: Maybe<Scalars["String"]["output"]>;
  inventoryUri?: Maybe<Scalars["String"]["output"]>;
  status: InventoryStatus;
};

/** Operating system details */
export type ResourceOs = {
  __typename?: "ResourceOS";
  /** Subtype of operating system */
  subType?: Maybe<OsSubType>;
  /** Resource operating system type, e.g Windows, Linux */
  type: OsType;
  /** Version of operating system */
  version?: Maybe<Scalars["String"]["output"]>;
};

/** Operating system details */
export type ResourceOsInput = {
  /** Subtype of operating system */
  subType?: InputMaybe<OsSubType>;
  /** Resource operating system type, e.g Windows, Linux */
  type: OsType;
  /** Version of operating system */
  version?: InputMaybe<Scalars["String"]["input"]>;
};

/** Operating system details */
export type ResourceOsUpdateInput = {
  /** Version of operating system */
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResourceReportPackage = {
  __typename?: "ResourceReportPackage";
  /** Error message */
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  /** Package ID */
  id: Scalars["String"]["output"];
  /** Package name */
  name: Scalars["String"]["output"];
  /** Package previous version */
  previousVersion?: Maybe<Scalars["String"]["output"]>;
  /** Package version */
  version?: Maybe<Scalars["String"]["output"]>;
};

export type ResourceSelector = {
  __typename?: "ResourceSelector";
  dynamicResources?: Maybe<Array<Resource>>;
  id: Scalars["UUID"]["output"];
  order: Scalars["Int"]["output"];
  resource?: Maybe<Resource>;
  resourceId?: Maybe<Scalars["String"]["output"]>;
  tagsExpression?: Maybe<Scalars["String"]["output"]>;
};

export type ResourceState = {
  __typename?: "ResourceState";
  /** Error message */
  error?: Maybe<Scalars["String"]["output"]>;
  /** SSM metadata */
  ssmMetadata?: Maybe<Array<KeyValue>>;
  /** Connection status between resource and FleetControl */
  status: ResourceStatus;
  /** Date of state change */
  updatedAt: Scalars["String"]["output"];
};

export enum ResourceStatus {
  ConnectionLost = "CONNECTION_LOST",
  Online = "ONLINE",
}

export type ResourcesFilterInput = {
  field: ResourcesFilterableFields;
  operator: FilterComparisonOperator;
  values?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum ResourcesFilterableFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Provider = "PROVIDER",
  ProviderId = "PROVIDER_ID",
  Region = "REGION",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type ResourcesPage = {
  __typename?: "ResourcesPage";
  /** Information about returned page */
  pageInfo: PageInfo;
  /** List of resources */
  result?: Maybe<Array<Maybe<Resource>>>;
};

export type ResourcesSearchInput = {
  /** Can be provided with phrase to narrow down search results. */
  filter?: InputMaybe<Array<ResourcesFilterInput>>;
  /**
   * Used to match phrase on multiple fields by the prefix.
   * Multiple keywords can be provided separated by the space and they are considered as combined by AND statement.
   */
  phrase?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ResourcesSortFields {
  AutoDiscovery = "AUTO_DISCOVERY",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Enabled = "ENABLED",
  Name = "NAME",
  Provider = "PROVIDER",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type ResourcesSortInput = {
  field: ResourcesSortFields;
  order?: InputMaybe<SortOrder>;
};

/** Role contains permissions and can be attached to the user/API key. */
export type Role = {
  __typename?: "Role";
  /** Change details represent when and by whom data was created or modified */
  changeDetails: ChangeDetails;
  /** Additional role description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Role identifier */
  id: Scalars["UUID"]["output"];
  /** Friendly role name */
  name: Scalars["String"]["output"];
  /** Rules allow to control access to FleetControl resources */
  rules: Array<RoleRule>;
};

export type RoleBinding = {
  __typename?: "RoleBinding";
  changeDetails: ChangeDetails;
  id: Scalars["ID"]["output"];
  role: Role;
  /** Email for USER type or API key id for API_KEY type. */
  subject: Scalars["String"]["output"];
  type: RbacRoleBindingType;
};

export type RoleBindingByUser = {
  __typename?: "RoleBindingByUser";
  customerId: Scalars["UUID"]["output"];
  roles: Array<Role>;
  /** Email for USER type or API key id for API_KEY type. */
  subject: Scalars["String"]["output"];
  type: RbacRoleBindingType;
};

export type RoleBindingsByUsersPage = {
  __typename?: "RoleBindingsByUsersPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<Maybe<RoleBindingByUser>>>;
};

export type RoleBindingsPage = {
  __typename?: "RoleBindingsPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<Maybe<RoleBinding>>>;
};

export enum RoleBindingsSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Id = "ID",
  RoleId = "ROLE_ID",
  Subject = "SUBJECT",
  Type = "TYPE",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type RoleBindingsSortInput = {
  field: RoleBindingsSortFields;
  order?: InputMaybe<SortOrder>;
};

/** Role contains permissions and can be attached to the user/API key. */
export type RoleInput = {
  /** Additional role description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Friendly role name */
  name: Scalars["String"]["input"];
  /** Rules allow to control access to FleetControl resources */
  rules: Array<RoleRuleInput>;
};

/** RoleRule allows to control access to FleetControl resources */
export type RoleRule = {
  __typename?: "RoleRule";
  /** List of actions that can be performed by the role */
  actions: Array<RbacAction>;
  /** List of resources that role has access */
  resources: Array<RbacResource>;
};

/** RoleRule allows to control access to FleetControl resources */
export type RoleRuleInput = {
  /** List of actions that can be performed by the role */
  actions: Array<RbacAction>;
  /** List of resources that role has access */
  resources: Array<RbacResource>;
};

export type RoleUpdateInput = {
  /** Additional role description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Role identifier */
  id: Scalars["UUID"]["input"];
  /** Friendly role name */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Rules allow to control access to FleetControl resources */
  rules?: InputMaybe<Array<RoleRuleInput>>;
};

export type RolesPage = {
  __typename?: "RolesPage";
  /** Page details */
  pageInfo: PageInfo;
  /** List of roles */
  result?: Maybe<Array<Maybe<Role>>>;
};

export enum RolesSortFields {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Name = "NAME",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
}

export type RolesSortInput = {
  field: RolesSortFields;
  order?: InputMaybe<SortOrder>;
};

/** Access to resource using AWS SSM service */
export type SsmAccess = {
  __typename?: "SSMAccess";
  /** Resource ID in SSM service */
  instanceId: Scalars["String"]["output"];
  /** Region where instance has been registered */
  region: Scalars["String"]["output"];
};

/** Access to resource using AWS SSM service */
export type SsmAccessInput = {
  credentials: AwsCredentialsInput;
  /** Resource ID in SSM service */
  instanceId: Scalars["String"]["input"];
  /** Region where instance has been registered */
  region: Scalars["String"]["input"];
};

/** Access to resource using AWS SSM service */
export type SsmAccessUpdateInput = {
  credentials?: InputMaybe<AwsCredentialsInput>;
  /** Resource ID in SSM service */
  instanceId?: InputMaybe<Scalars["String"]["input"]>;
  /** Region where instance has been registered */
  region?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SkipWindowType {
  Always = "ALWAYS",
  Never = "NEVER",
  OnSuccess = "ON_SUCCESS",
}

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type StaticAwsCredentialsInput = {
  accessKeyId: Scalars["String"]["input"];
  secretAccessKey: Scalars["String"]["input"];
};

export type StatusChangedInput = {
  id: Scalars["UUID"]["input"];
};

export type Subscription = {
  __typename?: "Subscription";
  /** This subscription allows to get the newest event action status */
  eventActionStatusChanged: ExecutionStatus;
  /** This subscription allows to get the newest event resource status */
  eventResourceStatusChanged: ResourceExecutionStatus;
  /** This subscription allows to get the newest event status */
  eventStatusChanged: EventStatus;
};

export type SubscriptionEventActionStatusChangedArgs = {
  input: StatusChangedInput;
};

export type SubscriptionEventResourceStatusChangedArgs = {
  input: StatusChangedInput;
};

export type SubscriptionEventStatusChangedArgs = {
  input: StatusChangedInput;
};

export type SystemAction = {
  __typename?: "SystemAction";
  /** Change details represent when and by whom data was created or modified */
  changeDetails: ChangeDetails;
  /** Color of the action in hexadecimal numeral system e.g. #FFFFFF. Used for presentation purposes */
  color: Scalars["String"]["output"];
  /** Additional description */
  description?: Maybe<Scalars["String"]["output"]>;
  /** Action identifier */
  id: Scalars["UUID"]["output"];
  /** Action name */
  name: Scalars["String"]["output"];
  /** Describes the possible parameters of the action */
  parameterDefinitions: Array<ActionParameterDefinition>;
  /** Scope of the action */
  scope: ActionScope;
  /** Version of action */
  version: Scalars["String"]["output"];
};

export type SystemActionPage = {
  __typename?: "SystemActionPage";
  pageInfo: PageInfo;
  result?: Maybe<Array<SystemAction>>;
};

export enum SystemActionsSortFields {
  Color = "COLOR",
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  Name = "NAME",
  UpdatedAt = "UPDATED_AT",
  UpdatedBy = "UPDATED_BY",
  Version = "VERSION",
}

export type SystemActionsSortInput = {
  field: SystemActionsSortFields;
  order?: InputMaybe<SortOrder>;
};

export type UpdateAwsLambdaInput = {
  /** ARN of the AWS lambda function */
  lambdaArn: Scalars["String"]["input"];
};

export type UpdateActionInput = {
  /** Color of action in hexadecimal numeral system e.g. #FFFFFF. Used for presentation purposes */
  color?: InputMaybe<Scalars["String"]["input"]>;
  /** Additional description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Action identifier */
  id: Scalars["UUID"]["input"];
  /** Action name */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Defines what parameters can be defined for the action */
  parameterDefinitions?: InputMaybe<
    Array<UpdateActionParameterDefinitionInput>
  >;
  /** Scope of the action */
  scope?: InputMaybe<ActionScope>;
  /** Determines whether the action is executed in a synchronous or asynchronous way */
  synchronous?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Determines type of the action and which source will be used, must be set in case updating the `typeProperties` */
  type?: InputMaybe<ActionType>;
  /** Content of the action respectively based on the chosen type */
  typeProperties?: InputMaybe<UpdateTypePropertiesInput>;
};

export type UpdateActionParameterDefinitionInput = {
  /** Optionally defines list of allowed values */
  allowedValues?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Optionally defines the default value of the parameter */
  defaultValue?: InputMaybe<Scalars["String"]["input"]>;
  /** Optionally defines additional field description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Name of the parameter */
  name: Scalars["String"]["input"];
  /** Determines whether the parameter is required or not */
  required: Scalars["Boolean"]["input"];
  /** Type of the parameter */
  type: ActionParameterType;
};

export type UpdateApiKeyInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  owner?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateAzureFunctionInput = {
  /** Access key ID needed for authorization to execute Azure function */
  accessKeyId: Scalars["String"]["input"];
  /** Method used by the azure function e.g. GET, POST, PUT, PATCH, DELETE */
  functionMethod: AzureFunctionMethod;
  /** URL of the Azure function */
  functionUrl: Scalars["String"]["input"];
};

export type UpdateChannelTypePropertiesInput = {
  /** Content of the Email type channel */
  email?: InputMaybe<UpdateEmailInput>;
  /** Content of the Webhook type channel */
  webhook?: InputMaybe<UpdateWebhookInput>;
};

export type UpdateCloudAccountInput = {
  credentials?: InputMaybe<AccountCredentialsInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  environment?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  metadata?: InputMaybe<Array<KeyValueInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  regions?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  resourceCleanerEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceDiscoveryEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateEmailInput = {
  email: Scalars["String"]["input"];
};

export type UpdateGroupInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  metadata?: InputMaybe<Array<KeyValueInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  resourceSelectors?: InputMaybe<Array<UpdateResourceSelectorInput>>;
  resourceSelectorsToDelete?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

export type UpdateNotificationGroupInput = {
  channels?: InputMaybe<Array<ChannelUpdateInput>>;
  channelsToDelete?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePlanActionInput = {
  /** Action to be used in plan action */
  action?: InputMaybe<PlanActionIdInput>;
  /** Execution policy provided by user */
  executionPolicy?: InputMaybe<ExecutionPolicy>;
  /** Plan action id. If id is not set then action will be added to the plan otherwise corresponding action will be updated in plan. */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Names and values of parameters that are passed to the action.
   * They must comply with System Action's or Custom Action's parameter definitions.
   * Values of type other than string are JSON-serialized to string.
   */
  inputParameters?: InputMaybe<Array<KeyValueInput>>;
  /** Plan action name */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Notification groups to be added or updated */
  notificationGroups?: InputMaybe<
    Array<UpdatePlanActionNotificationGroupInput>
  >;
  /** Ids of notification groups to be removed from plan action */
  notificationGroupsToDelete?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Must be greater than 0 */
  order?: InputMaybe<Scalars["Int"]["input"]>;
  /** Resource group IDs that will be added to the plan action */
  resourceGroupIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Resource group IDs that will be removed from the plan action */
  resourceGroupIdsToDelete?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** If set to true, then an action will be performed one at a time for each resource. */
  runInSequence?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The skipWindow property can be set to one of the values: ALWAYS, ON_SUCCESS, or NEVER */
  skipWindow?: InputMaybe<SkipWindowType>;
  /** Window duration in minutes must be greater than 0 */
  windowDuration?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdatePlanActionNotificationGroupInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  inAdvance?: InputMaybe<Scalars["Int"]["input"]>;
  notificationGroupId?: InputMaybe<Scalars["ID"]["input"]>;
  scope?: InputMaybe<PlanActionNotificationGroupScope>;
  triggerEvent: PlanActionNotificationTriggerType;
};

export type UpdatePlanInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** Metadata provided by user */
  metadata?: InputMaybe<Array<KeyValueInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Notification groups to be added or updated */
  notificationGroups?: InputMaybe<Array<UpdatePlanNotificationGroupInput>>;
  /** Ids of notification groups to be removed from plan */
  notificationGroupsToDelete?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Actions to be added or updated */
  planActions?: InputMaybe<Array<UpdatePlanActionInput>>;
  /** Ids of actions to be removed from plan */
  planActionsToDelete?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Schedule cron e.g. 5 4 * * * */
  scheduleCron?: InputMaybe<Scalars["String"]["input"]>;
  /** Dates on which plan execution will be scheduled. When provided then `scheduleCron` and `scheduleOffset` must not be provided. */
  scheduleExecutionDates?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Schedule offset is a delay expressed in minutes that is added to cron-generated dates */
  scheduleOffset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Schedule time zone e.g. UTC. Required if `scheduleExecutionDates` or `scheduleCron` are set. */
  scheduleTimezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePlanNotificationGroupInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  inAdvance?: InputMaybe<Scalars["Int"]["input"]>;
  notificationGroupId?: InputMaybe<Scalars["ID"]["input"]>;
  triggerEvent?: InputMaybe<PlanNotificationTriggerType>;
};

export type UpdateResourceInput = {
  /** Access needed for running commands on resource */
  access?: InputMaybe<ResourceAccessUpdateInput>;
  /** Additional description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** If set to false, it skips execution of all assigned actions to a particular resource. */
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Resource identifier which will be modified */
  id: Scalars["UUID"]["input"];
  /** Metadata provided by user */
  metadata?: InputMaybe<Array<KeyValueInput>>;
  /** Resource name */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Resource Operating System */
  os?: InputMaybe<ResourceOsUpdateInput>;
  /** Resource region */
  region?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateResourceSelectorInput = {
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  order: Scalars["Int"]["input"];
  resourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  tagsExpression?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateSsmDocumentInput = {
  /** ARN or name of the AWS SSM document */
  documentId: Scalars["String"]["input"];
};

export type UpdateScriptInput = {
  /** File contains the script, which will be uploaded to S3 */
  file: Scalars["Upload"]["input"];
};

export type UpdateTypePropertiesInput = {
  /** Content of the AWS_LAMBDA type action */
  awsLambda?: InputMaybe<UpdateAwsLambdaInput>;
  /** Content of the AZURE_FUNCTION type action */
  azureFunction?: InputMaybe<UpdateAzureFunctionInput>;
  /** Details of the script file */
  script?: InputMaybe<UpdateScriptInput>;
  /** Content of the SSM_DOCUMENT type action */
  ssmDocument?: InputMaybe<UpdateSsmDocumentInput>;
  /** Content of the WEBHOOK type action */
  webhook?: InputMaybe<UpdateWebhookActionInput>;
};

export type UpdateWebhookActionInput = {
  /** Field that is attached header fields (additional context and metadata) to the webhook request */
  headers: Array<KeyValueInput>;
  /** Payload is the data used in the webhook request in stringified JSON format */
  payload: Scalars["String"]["input"];
  /** Method used to invoke webhook e.g. "GET", "POST", "PUT", "PATCH", "DELETE" */
  webhookMethod: WebhookActionMethod;
  /** URL of the Webhook */
  webhookUrl: Scalars["String"]["input"];
};

export type UpdateWebhookInput = {
  headers?: InputMaybe<Array<KeyValueInput>>;
  method: Scalars["String"]["input"];
  /** Payload contains request body payload in stringified JSON format */
  payload?: InputMaybe<Scalars["String"]["input"]>;
  type: WebhookChannelType;
  url: Scalars["String"]["input"];
};

export type UpsertHostInput = {
  host: HostInput;
  hostGroups: Array<Scalars["String"]["input"]>;
};

export enum WebhookActionMethod {
  Delete = "DELETE",
  Get = "GET",
  Patch = "PATCH",
  Post = "POST",
  Put = "PUT",
}

export type WebhookChannel = {
  __typename?: "WebhookChannel";
  headers?: Maybe<Array<KeyValue>>;
  method: Scalars["String"]["output"];
  /** Payload contains request body payload in stringified JSON format */
  payload?: Maybe<Scalars["String"]["output"]>;
  type: WebhookChannelType;
  url: Scalars["String"]["output"];
};

export enum WebhookChannelType {
  Custom = "CUSTOM",
  PagerDuty = "PAGER_DUTY",
  Slack = "SLACK",
}

export type CustomerQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerQuery = {
  __typename?: "Query";
  customers: Array<{
    __typename?: "Customer";
    id: string;
    name: string;
    organizationName: string;
  }>;
};

export type AwsOnboardingTemplateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AwsOnboardingTemplateQuery = {
  __typename?: "Query";
  awsOnboardingTemplate: string;
};

export type AzureOnboardingScriptQueryVariables = Exact<{
  subscriptionId: Scalars["UUID"]["input"];
}>;

export type AzureOnboardingScriptQuery = {
  __typename?: "Query";
  azureOnboardingScript: string;
};

export type CreateCloudAccountMutationVariables = Exact<{
  input: CreateCloudAccountInput;
}>;

export type CreateCloudAccountMutation = {
  __typename?: "Mutation";
  createCloudAccount: { __typename?: "CloudAccount"; id: string };
};

export type DeleteCloudAccountMutationVariables = Exact<{
  removeCloudAccountId: Scalars["UUID"]["input"];
}>;

export type DeleteCloudAccountMutation = {
  __typename?: "Mutation";
  removeCloudAccount: boolean;
};

export type GetCloudAccountQueryVariables = Exact<{
  cloudAccountId: Scalars["UUID"]["input"];
}>;

export type GetCloudAccountQuery = {
  __typename?: "Query";
  cloudAccount?: {
    __typename?: "CloudAccount";
    id: string;
    name: string;
    description?: string | null;
    provider: CloudAccountCloudProvider;
    providerId: string;
    environment?: string | null;
    status: CloudAccountStatus;
    lastScanStartDate?: string | null;
    lastScanEndDate?: string | null;
    regions: Array<string>;
    resourceDiscoveryEnabled: boolean;
    resourceCleanerEnabled: boolean;
    metadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    regionsStatuses?: Array<{
      __typename?: "RegionStatus";
      region: string;
      status: CloudAccountRegionStatus;
      details?: string | null;
    } | null> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      updatedBy: string;
    };
  } | null;
};

export type GetCloudAccountsQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  page: Scalars["Int"]["input"];
  sort?: InputMaybe<CloudAccountsSortInput>;
}>;

export type GetCloudAccountsQuery = {
  __typename?: "Query";
  cloudAccounts: {
    __typename?: "CloudAccountsPage";
    result?: Array<{
      __typename?: "CloudAccount";
      name: string;
      id: string;
      provider: CloudAccountCloudProvider;
      environment?: string | null;
      status: CloudAccountStatus;
      providerId: string;
      changeDetails: { __typename?: "ChangeDetails"; createdAt: string };
    } | null> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type ScanCloudAccountMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type ScanCloudAccountMutation = {
  __typename?: "Mutation";
  scanCloudAccount: boolean;
};

export type UpdateCloudAccountMutationVariables = Exact<{
  input: UpdateCloudAccountInput;
}>;

export type UpdateCloudAccountMutation = {
  __typename?: "Mutation";
  updateCloudAccount: {
    __typename?: "CloudAccount";
    id: string;
    name: string;
    description?: string | null;
    environment?: string | null;
    regionsStatuses?: Array<{
      __typename?: "RegionStatus";
      region: string;
      status: CloudAccountRegionStatus;
      details?: string | null;
    } | null> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      updatedAt: string;
      updatedBy: string;
    };
  };
};

export type CreateCustomActionMutationVariables = Exact<{
  input: CreateActionInput;
}>;

export type CreateCustomActionMutation = {
  __typename?: "Mutation";
  createAction: {
    __typename?: "Action";
    id: string;
    name: string;
    description?: string | null;
    color: string;
    type: ActionType;
    scope: ActionScope;
  };
};

export type DeleteCustomActionMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type DeleteCustomActionMutation = {
  __typename?: "Mutation";
  removeAction: boolean;
};

export type GetCustomActionQueryVariables = Exact<{
  customActionId: Scalars["UUID"]["input"];
}>;

export type GetCustomActionQuery = {
  __typename?: "Query";
  action?: {
    __typename?: "Action";
    id: string;
    name: string;
    description?: string | null;
    color: string;
    type: ActionType;
    scope: ActionScope;
    typeProperties:
      | { __typename: "ActionPropertyAWSLambda"; lambdaArn: string }
      | {
          __typename: "ActionPropertyAzureFunction";
          accessKeyId: string;
          functionMethod: AzureFunctionMethod;
          functionUrl: string;
        }
      | { __typename: "ActionPropertySSMDocument"; documentId: string }
      | { __typename: "ActionPropertyScript"; s3FileUrl: string }
      | {
          __typename: "ActionPropertyWebhook";
          webhookUrl: string;
          webhookMethod: WebhookActionMethod;
          payload: string;
          headers: Array<{
            __typename?: "KeyValue";
            key: string;
            value: string;
          }>;
        };
  } | null;
};

export type GetCustomActionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ActionSortInput>;
}>;

export type GetCustomActionsQuery = {
  __typename?: "Query";
  actions: {
    __typename?: "ActionPage";
    result?: Array<{
      __typename?: "Action";
      id: string;
      name: string;
      color: string;
      description?: string | null;
      scope: ActionScope;
      type: ActionType;
      synchronous: boolean;
      parameterDefinitions: Array<{
        __typename?: "ActionParameterDefinition";
        name: string;
        required: boolean;
        description?: string | null;
        allowedValues?: Array<string> | null;
        defaultValue?: string | null;
        type: ActionParameterType;
      }>;
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type GetCustomActionPlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<PlanSortInput>;
}>;

export type GetCustomActionPlansQuery = {
  __typename?: "Query";
  plans: {
    __typename?: "PlanPage";
    result?: Array<{
      __typename?: "Plan";
      name: string;
      planActions?: Array<{
        __typename?: "PlanAction";
        action:
          | { __typename?: "Action"; name: string; id: string }
          | { __typename?: "SystemAction" };
      }> | null;
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type UpdateCustomActionMutationVariables = Exact<{
  input: UpdateActionInput;
}>;

export type UpdateCustomActionMutation = {
  __typename?: "Mutation";
  updateAction: {
    __typename?: "Action";
    id: string;
    name: string;
    description?: string | null;
    type: ActionType;
    scope: ActionScope;
    typeProperties:
      | { __typename: "ActionPropertyAWSLambda"; lambdaArn: string }
      | {
          __typename: "ActionPropertyAzureFunction";
          accessKeyId: string;
          functionUrl: string;
          functionMethod: AzureFunctionMethod;
        }
      | { __typename: "ActionPropertySSMDocument"; documentId: string }
      | { __typename: "ActionPropertyScript"; s3FileUrl: string }
      | {
          __typename: "ActionPropertyWebhook";
          webhookUrl: string;
          webhookMethod: WebhookActionMethod;
          payload: string;
          headers: Array<{
            __typename?: "KeyValue";
            key: string;
            value: string;
          }>;
        };
  };
};

export type GetEventActionReportQueryVariables = Exact<{
  eventId: Scalars["UUID"]["input"];
  eventActionId: Scalars["UUID"]["input"];
}>;

export type GetEventActionReportQuery = {
  __typename?: "Query";
  eventActionReport?: {
    __typename?: "EventActionReport";
    eventId: string;
    eventActionId: string;
    name: string;
    actionName: string;
    status: ExecutionStatus;
    startTime?: string | null;
    endTime?: string | null;
    output?: string | null;
    resources?: Array<{
      __typename?: "EventActionResourceReport";
      resourceId: string;
      name: string;
      status: ResourceExecutionStatus;
      result: {
        __typename?: "EventActionResourceReportResult";
        output?: string | null;
        outputUri?: string | null;
        installed?: Array<{
          __typename?: "ResourceReportPackage";
          name: string;
          id: string;
          version?: string | null;
        }> | null;
        updated?: Array<{
          __typename?: "ResourceReportPackage";
          name: string;
          id: string;
          version?: string | null;
        }> | null;
        removed?: Array<{
          __typename?: "ResourceReportPackage";
          name: string;
          id: string;
          version?: string | null;
        }> | null;
        available?: Array<{
          __typename?: "ResourceReportPackage";
          name: string;
          id: string;
          version?: string | null;
        }> | null;
        failed?: Array<{
          __typename?: "ResourceReportPackage";
          name: string;
          id: string;
          version?: string | null;
        }> | null;
      };
    }> | null;
  } | null;
};

export type GetEventActionReportsQueryVariables = Exact<{
  eventId: Scalars["UUID"]["input"];
  eventActionId: Scalars["UUID"]["input"];
}>;

export type GetEventActionReportsQuery = {
  __typename?: "Query";
  eventActionReports?: Array<{
    __typename?: "EventActionAttemptReport";
    attempt: number;
    report: {
      __typename?: "EventActionReport";
      eventId: string;
      eventActionId: string;
      name: string;
      actionName: string;
      status: ExecutionStatus;
      startTime?: string | null;
      endTime?: string | null;
      output?: string | null;
      resources?: Array<{
        __typename?: "EventActionResourceReport";
        resourceId: string;
        name: string;
        status: ResourceExecutionStatus;
        result: {
          __typename?: "EventActionResourceReportResult";
          output?: string | null;
          outputUri?: string | null;
          installed?: Array<{
            __typename?: "ResourceReportPackage";
            name: string;
            id: string;
            version?: string | null;
          }> | null;
          updated?: Array<{
            __typename?: "ResourceReportPackage";
            name: string;
            id: string;
            version?: string | null;
            previousVersion?: string | null;
          }> | null;
          removed?: Array<{
            __typename?: "ResourceReportPackage";
            name: string;
            id: string;
            version?: string | null;
          }> | null;
          available?: Array<{
            __typename?: "ResourceReportPackage";
            name: string;
            id: string;
            version?: string | null;
          }> | null;
          failed?: Array<{
            __typename?: "ResourceReportPackage";
            name: string;
            id: string;
            version?: string | null;
          }> | null;
        };
      }> | null;
    };
  }> | null;
};

export type EventResourceStatusChangedSubscriptionVariables = Exact<{
  input: StatusChangedInput;
}>;

export type EventResourceStatusChangedSubscription = {
  __typename?: "Subscription";
  eventResourceStatusChanged: ResourceExecutionStatus;
};

export type EventActionStatusChangedSubscriptionVariables = Exact<{
  input: StatusChangedInput;
}>;

export type EventActionStatusChangedSubscription = {
  __typename?: "Subscription";
  eventActionStatusChanged: ExecutionStatus;
};

export type EventStatusChangedSubscriptionVariables = Exact<{
  input: StatusChangedInput;
}>;

export type EventStatusChangedSubscription = {
  __typename?: "Subscription";
  eventStatusChanged: EventStatus;
};

export type ApproveEventActionMutationVariables = Exact<{
  eventId: Scalars["UUID"]["input"];
  actionId: Scalars["UUID"]["input"];
}>;

export type ApproveEventActionMutation = {
  __typename?: "Mutation";
  approveEventAction: boolean;
};

export type ChangeResourceExecutionStatusMutationVariables = Exact<{
  input: ChangeResourceExecutionStatusInput;
}>;

export type ChangeResourceExecutionStatusMutation = {
  __typename?: "Mutation";
  changeResourceExecutionStatus: boolean;
};

export type EventActionAttemptResourceStatesFragment = {
  __typename?: "EventActionAttempt";
  resourceStates?: Array<{
    __typename?: "EventActionResourceState";
    id: string;
    eventActionId: string;
    resourceId: string;
    resourceGroupId: string;
    order: number;
    status: ResourceExecutionStatus;
    outputUri?: string | null;
    annotation: string;
    resource?: {
      __typename?: "Resource";
      id: string;
      name: string;
      description?: string | null;
      autoDiscovery: boolean;
      cloudResourceId: string;
      fullCloudResourceId: string;
      enabled: boolean;
      provider: ResourceCloudProvider;
      providerId: string;
      region: string;
      scanned: boolean;
      os?: {
        __typename?: "ResourceOS";
        type: OsType;
        subType?: OsSubType | null;
      } | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    } | null;
    resourceGroup?: {
      __typename?: "Group";
      id: string;
      name: string;
      description?: string | null;
      totalNumberOfResources: number;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    } | null;
    output?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    } | null> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdBy: string;
      createdAt: string;
      updatedBy: string;
      updatedAt: string;
    };
  }> | null;
};

export type EventActionResourceStatesFragment = {
  __typename?: "EventAction";
  resourceStates?: Array<{
    __typename?: "EventActionResourceState";
    id: string;
    eventActionId: string;
    resourceId: string;
    resourceGroupId: string;
    order: number;
    status: ResourceExecutionStatus;
    outputUri?: string | null;
    annotation: string;
    resource?: {
      __typename?: "Resource";
      id: string;
      name: string;
      description?: string | null;
      autoDiscovery: boolean;
      cloudResourceId: string;
      fullCloudResourceId: string;
      enabled: boolean;
      provider: ResourceCloudProvider;
      providerId: string;
      region: string;
      scanned: boolean;
      os?: {
        __typename?: "ResourceOS";
        type: OsType;
        subType?: OsSubType | null;
      } | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    } | null;
    resourceGroup?: {
      __typename?: "Group";
      id: string;
      name: string;
      description?: string | null;
      totalNumberOfResources: number;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    } | null;
    output?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    } | null> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdBy: string;
      createdAt: string;
      updatedBy: string;
      updatedAt: string;
    };
  }> | null;
};

export type GetEventQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetEventQuery = {
  __typename?: "Query";
  event?: {
    __typename?: "Event";
    id: string;
    name: string;
    description?: string | null;
    scheduleTimezone: string;
    estimatedEndTime: string;
    status: EventStatus;
    startTime: string;
    metadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    plan?: {
      __typename?: "Plan";
      id: string;
      name: string;
      scheduleCron?: string | null;
      scheduleOffset: number;
      scheduleTimezone?: string | null;
      enabled: boolean;
      planActions?: Array<{
        __typename?: "PlanAction";
        id: string;
        runInSequence: boolean;
        executionPolicy: ExecutionPolicy;
        order: number;
        skipWindow: SkipWindowType;
        windowDuration: number;
        action:
          | {
              __typename?: "Action";
              id: string;
              name: string;
              color: string;
              synchronous: boolean;
              type: ActionType;
              scope: ActionScope;
              typeProperties:
                | { __typename?: "ActionPropertyAWSLambda"; lambdaArn: string }
                | {
                    __typename?: "ActionPropertyAzureFunction";
                    accessKeyId: string;
                    functionMethod: AzureFunctionMethod;
                    functionUrl: string;
                  }
                | {
                    __typename?: "ActionPropertySSMDocument";
                    documentId: string;
                  }
                | { __typename?: "ActionPropertyScript"; s3FileUrl: string }
                | {
                    __typename?: "ActionPropertyWebhook";
                    webhookUrl: string;
                    webhookMethod: WebhookActionMethod;
                    payload: string;
                    headers: Array<{
                      __typename?: "KeyValue";
                      key: string;
                      value: string;
                    }>;
                  };
              parameterDefinitions: Array<{
                __typename?: "ActionParameterDefinition";
                name: string;
                required: boolean;
                type: ActionParameterType;
              }>;
              changeDetails: {
                __typename?: "ChangeDetails";
                createdAt: string;
                createdBy: string;
                updatedAt: string;
                updatedBy: string;
              };
            }
          | {
              __typename?: "SystemAction";
              id: string;
              name: string;
              scope: ActionScope;
              color: string;
              version: string;
              parameterDefinitions: Array<{
                __typename?: "ActionParameterDefinition";
                name: string;
                required: boolean;
                type: ActionParameterType;
              }>;
              changeDetails: {
                __typename?: "ChangeDetails";
                createdAt: string;
                createdBy: string;
                updatedAt: string;
                updatedBy: string;
              };
            };
        resourceGroups?: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          description?: string | null;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdAt: string;
            createdBy: string;
            updatedAt: string;
            updatedBy: string;
          };
        }> | null;
      }> | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    } | null;
    actions?: Array<{
      __typename?: "EventAction";
      id: string;
      actionName: string;
      name: string;
      type: EventActionType;
      scope: EventActionScope;
      order: number;
      windowDuration: number;
      skipWindow: SkipWindowType;
      executionPolicy: ExecutionPolicy;
      runInSequence: boolean;
      globalState?: {
        __typename?: "GlobalEventActionState";
        status: ExecutionStatus;
        outputUri?: string | null;
        estimatedStartTime: string;
        startTime?: string | null;
        endTime?: string | null;
      } | null;
      attempts: Array<{
        __typename?: "EventActionAttempt";
        id: string;
        attempt: number;
        state?: {
          __typename?: "EventActionState";
          eventId: string;
          eventActionId: string;
          status: ExecutionStatus;
          outputUri?: string | null;
          estimatedStartTime: string;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdAt: string;
            createdBy: string;
            updatedAt: string;
            updatedBy: string;
          };
        } | null;
        resourceStates?: Array<{
          __typename?: "EventActionResourceState";
          id: string;
          eventActionId: string;
          resourceId: string;
          resourceGroupId: string;
          order: number;
          status: ResourceExecutionStatus;
          outputUri?: string | null;
          annotation: string;
          resource?: {
            __typename?: "Resource";
            id: string;
            name: string;
            description?: string | null;
            autoDiscovery: boolean;
            cloudResourceId: string;
            fullCloudResourceId: string;
            enabled: boolean;
            provider: ResourceCloudProvider;
            providerId: string;
            region: string;
            scanned: boolean;
            os?: {
              __typename?: "ResourceOS";
              type: OsType;
              subType?: OsSubType | null;
            } | null;
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
          } | null;
          resourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            description?: string | null;
            totalNumberOfResources: number;
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
          } | null;
          output?: Array<{
            __typename?: "KeyValue";
            key: string;
            value: string;
          } | null> | null;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdBy: string;
            createdAt: string;
            updatedBy: string;
            updatedAt: string;
          };
        }> | null;
      }>;
      inputParameters?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      }> | null;
      action?:
        | {
            __typename?: "Action";
            id: string;
            name: string;
            type: ActionType;
            color: string;
            scope: ActionScope;
            synchronous: boolean;
            typeProperties:
              | { __typename?: "ActionPropertyAWSLambda"; lambdaArn: string }
              | {
                  __typename?: "ActionPropertyAzureFunction";
                  accessKeyId: string;
                  functionMethod: AzureFunctionMethod;
                  functionUrl: string;
                }
              | { __typename?: "ActionPropertySSMDocument"; documentId: string }
              | { __typename?: "ActionPropertyScript"; s3FileUrl: string }
              | {
                  __typename?: "ActionPropertyWebhook";
                  webhookUrl: string;
                  webhookMethod: WebhookActionMethod;
                  payload: string;
                  headers: Array<{
                    __typename?: "KeyValue";
                    key: string;
                    value: string;
                  }>;
                };
            parameterDefinitions: Array<{
              __typename?: "ActionParameterDefinition";
              name: string;
              required: boolean;
              type: ActionParameterType;
            }>;
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
          }
        | {
            __typename?: "SystemAction";
            id: string;
            name: string;
            scope: ActionScope;
            color: string;
            version: string;
            parameterDefinitions: Array<{
              __typename?: "ActionParameterDefinition";
              name: string;
              required: boolean;
              type: ActionParameterType;
            }>;
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
          }
        | null;
      resourceGroups?: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        description?: string | null;
        totalNumberOfResources: number;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdAt: string;
          createdBy: string;
          updatedAt: string;
          updatedBy: string;
        };
      } | null> | null;
      resourceStates?: Array<{
        __typename?: "EventActionResourceState";
        id: string;
        eventActionId: string;
        resourceId: string;
        resourceGroupId: string;
        order: number;
        status: ResourceExecutionStatus;
        outputUri?: string | null;
        annotation: string;
        resource?: {
          __typename?: "Resource";
          id: string;
          name: string;
          description?: string | null;
          autoDiscovery: boolean;
          cloudResourceId: string;
          fullCloudResourceId: string;
          enabled: boolean;
          provider: ResourceCloudProvider;
          providerId: string;
          region: string;
          scanned: boolean;
          os?: {
            __typename?: "ResourceOS";
            type: OsType;
            subType?: OsSubType | null;
          } | null;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdAt: string;
            createdBy: string;
            updatedAt: string;
            updatedBy: string;
          };
        } | null;
        resourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          description?: string | null;
          totalNumberOfResources: number;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdAt: string;
            createdBy: string;
            updatedAt: string;
            updatedBy: string;
          };
        } | null;
        output?: Array<{
          __typename?: "KeyValue";
          key: string;
          value: string;
        } | null> | null;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdBy: string;
          createdAt: string;
          updatedBy: string;
          updatedAt: string;
        };
      }> | null;
    }> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      updatedBy: string;
    };
  } | null;
};

export type GetEventActionsResourceStatesQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetEventActionsResourceStatesQuery = {
  __typename?: "Query";
  event?: {
    __typename?: "Event";
    actions?: Array<{
      __typename?: "EventAction";
      id: string;
      resourceStates?: Array<{
        __typename?: "EventActionResourceState";
        id: string;
      }> | null;
    }> | null;
  } | null;
};

export type EventActionFragment = {
  __typename?: "EventAction";
  id: string;
  name: string;
  type: EventActionType;
  scope: EventActionScope;
  order: number;
  windowDuration: number;
  skipWindow: SkipWindowType;
  executionPolicy: ExecutionPolicy;
  action?:
    | {
        __typename?: "Action";
        id: string;
        name: string;
        type: ActionType;
        color: string;
      }
    | {
        __typename?: "SystemAction";
        id: string;
        name: string;
        version: string;
        color: string;
      }
    | null;
  resourceGroups?: Array<{
    __typename?: "Group";
    id: string;
    name: string;
    description?: string | null;
    totalNumberOfResources: number;
  } | null> | null;
  globalState?: {
    __typename?: "GlobalEventActionState";
    status: ExecutionStatus;
    estimatedStartTime: string;
    startTime?: string | null;
    endTime?: string | null;
  } | null;
  resourceStates?: Array<{
    __typename?: "EventActionResourceState";
    order: number;
    status: ResourceExecutionStatus;
    outputUri?: string | null;
    resource?: { __typename?: "Resource"; id: string; name: string } | null;
    resourceGroup?: { __typename?: "Group"; id: string; name: string } | null;
    output?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    } | null> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdBy: string;
      createdAt: string;
      updatedBy: string;
      updatedAt: string;
    };
  }> | null;
};

export type EventActionsFragment = {
  __typename?: "Event";
  actions?: Array<{
    __typename?: "EventAction";
    id: string;
    name: string;
    type: EventActionType;
    scope: EventActionScope;
    order: number;
    windowDuration: number;
    skipWindow: SkipWindowType;
    executionPolicy: ExecutionPolicy;
    action?:
      | {
          __typename?: "Action";
          id: string;
          name: string;
          type: ActionType;
          color: string;
        }
      | {
          __typename?: "SystemAction";
          id: string;
          name: string;
          version: string;
          color: string;
        }
      | null;
    resourceGroups?: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      description?: string | null;
      totalNumberOfResources: number;
    } | null> | null;
    globalState?: {
      __typename?: "GlobalEventActionState";
      status: ExecutionStatus;
      estimatedStartTime: string;
      startTime?: string | null;
      endTime?: string | null;
    } | null;
    resourceStates?: Array<{
      __typename?: "EventActionResourceState";
      order: number;
      status: ResourceExecutionStatus;
      outputUri?: string | null;
      resource?: { __typename?: "Resource"; id: string; name: string } | null;
      resourceGroup?: { __typename?: "Group"; id: string; name: string } | null;
      output?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      } | null> | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdBy: string;
        createdAt: string;
        updatedBy: string;
        updatedAt: string;
      };
    }> | null;
  }> | null;
};

export type GetEventsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<EventsSortInput>;
  filter?: InputMaybe<EventsFilterInput>;
}>;

export type GetEventsQuery = {
  __typename?: "Query";
  events: {
    __typename?: "EventsPage";
    result?: Array<{
      __typename?: "Event";
      id: string;
      name: string;
      status: EventStatus;
      scheduleTimezone: string;
      estimatedEndTime: string;
      startTime: string;
      description?: string | null;
      plan?: { __typename?: "Plan"; name: string } | null;
      metadata?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      }> | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdBy: string;
        createdAt: string;
        updatedBy: string;
        updatedAt: string;
      };
      actions?: Array<{
        __typename?: "EventAction";
        id: string;
        name: string;
        type: EventActionType;
        scope: EventActionScope;
        order: number;
        windowDuration: number;
        skipWindow: SkipWindowType;
        executionPolicy: ExecutionPolicy;
        action?:
          | {
              __typename?: "Action";
              id: string;
              name: string;
              type: ActionType;
              color: string;
            }
          | {
              __typename?: "SystemAction";
              id: string;
              name: string;
              version: string;
              color: string;
            }
          | null;
        resourceGroups?: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          description?: string | null;
          totalNumberOfResources: number;
        } | null> | null;
        globalState?: {
          __typename?: "GlobalEventActionState";
          status: ExecutionStatus;
          estimatedStartTime: string;
          startTime?: string | null;
          endTime?: string | null;
        } | null;
        resourceStates?: Array<{
          __typename?: "EventActionResourceState";
          order: number;
          status: ResourceExecutionStatus;
          outputUri?: string | null;
          resource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          resourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
          } | null;
          output?: Array<{
            __typename?: "KeyValue";
            key: string;
            value: string;
          } | null> | null;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdBy: string;
            createdAt: string;
            updatedBy: string;
            updatedAt: string;
          };
        }> | null;
      }> | null;
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type GetIncomingEventQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetIncomingEventQuery = {
  __typename?: "Query";
  incomingEvent?: {
    __typename?: "IncomingEvent";
    id: string;
    name: string;
    scheduleTimezone: string;
    estimatedEndTime: string;
    status: IncomingEventStatus;
    startTime: string;
    plan?: {
      __typename?: "Plan";
      id: string;
      name: string;
      scheduleCron?: string | null;
      scheduleOffset: number;
      scheduleTimezone?: string | null;
      enabled: boolean;
      planActions?: Array<{
        __typename?: "PlanAction";
        id: string;
        name: string;
        runInSequence: boolean;
        executionPolicy: ExecutionPolicy;
        order: number;
        skipWindow: SkipWindowType;
        windowDuration: number;
        action:
          | {
              __typename?: "Action";
              id: string;
              name: string;
              color: string;
              synchronous: boolean;
              type: ActionType;
              scope: ActionScope;
              typeProperties:
                | { __typename?: "ActionPropertyAWSLambda"; lambdaArn: string }
                | {
                    __typename?: "ActionPropertyAzureFunction";
                    accessKeyId: string;
                    functionMethod: AzureFunctionMethod;
                    functionUrl: string;
                  }
                | {
                    __typename?: "ActionPropertySSMDocument";
                    documentId: string;
                  }
                | { __typename?: "ActionPropertyScript"; s3FileUrl: string }
                | {
                    __typename?: "ActionPropertyWebhook";
                    webhookUrl: string;
                    webhookMethod: WebhookActionMethod;
                    payload: string;
                    headers: Array<{
                      __typename?: "KeyValue";
                      key: string;
                      value: string;
                    }>;
                  };
              parameterDefinitions: Array<{
                __typename?: "ActionParameterDefinition";
                name: string;
                required: boolean;
                type: ActionParameterType;
              }>;
              changeDetails: {
                __typename?: "ChangeDetails";
                createdAt: string;
                createdBy: string;
                updatedAt: string;
                updatedBy: string;
              };
            }
          | {
              __typename?: "SystemAction";
              id: string;
              name: string;
              scope: ActionScope;
              color: string;
              version: string;
              parameterDefinitions: Array<{
                __typename?: "ActionParameterDefinition";
                name: string;
                required: boolean;
                type: ActionParameterType;
              }>;
              changeDetails: {
                __typename?: "ChangeDetails";
                createdAt: string;
                createdBy: string;
                updatedAt: string;
                updatedBy: string;
              };
            };
        resourceGroups?: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          description?: string | null;
          totalNumberOfResources: number;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdAt: string;
            createdBy: string;
            updatedAt: string;
            updatedBy: string;
          };
        }> | null;
      }> | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    } | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      updatedBy: string;
    };
  } | null;
};

export type GetIncomingEventsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<IncomingEventsSortInput>;
  filter?: InputMaybe<IncomingEventsFilterInput>;
}>;

export type GetIncomingEventsQuery = {
  __typename?: "Query";
  incomingEvents: {
    __typename?: "IncomingEventPage";
    result?: Array<{
      __typename?: "IncomingEvent";
      id: string;
      name: string;
      status: IncomingEventStatus;
      scheduleTimezone: string;
      estimatedEndTime: string;
      startTime: string;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdBy: string;
        createdAt: string;
        updatedBy: string;
        updatedAt: string;
      };
      plan?: {
        __typename?: "Plan";
        id: string;
        planActions?: Array<{
          __typename?: "PlanAction";
          resourceGroups?: Array<{
            __typename?: "Group";
            totalNumberOfResources: number;
          }> | null;
          action:
            | { __typename?: "Action"; name: string }
            | { __typename?: "SystemAction"; name: string };
        }> | null;
      } | null;
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type GetPresignedUrlQueryVariables = Exact<{
  url: Scalars["String"]["input"];
}>;

export type GetPresignedUrlQuery = {
  __typename?: "Query";
  presignedEventActionOutputUrl?: string | null;
};

export type RerunEventActionMutationVariables = Exact<{
  input: RerunEventActionInput;
}>;

export type RerunEventActionMutation = {
  __typename?: "Mutation";
  rerunEventAction: boolean;
};

export type RevokeIncomingEventMutationVariables = Exact<{
  eventId: Scalars["UUID"]["input"];
}>;

export type RevokeIncomingEventMutation = {
  __typename?: "Mutation";
  revokeIncomingEvent: boolean;
};

export type CreateNotificationGroupMutationVariables = Exact<{
  input: CreateNotificationGroupInput;
}>;

export type CreateNotificationGroupMutation = {
  __typename?: "Mutation";
  createNotificationGroup: {
    __typename?: "NotificationGroup";
    id: string;
    name: string;
  };
};

export type DeleteNotificationGroupMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type DeleteNotificationGroupMutation = {
  __typename?: "Mutation";
  removeNotificationGroup: boolean;
};

export type GetNotificationGroupQueryVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type GetNotificationGroupQuery = {
  __typename?: "Query";
  notificationGroup?: {
    __typename?: "NotificationGroup";
    id: string;
    name: string;
    description?: string | null;
    channels?: Array<{
      __typename?: "Channel";
      id: string;
      typeProperties:
        | { __typename?: "EmailChannel"; email: string }
        | {
            __typename?: "WebhookChannel";
            url: string;
            payload?: string | null;
            method: string;
            type: WebhookChannelType;
            headers?: Array<{
              __typename?: "KeyValue";
              key: string;
              value: string;
            }> | null;
          };
    }> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      updatedAt: string;
      createdAt: string;
      updatedBy: string;
      createdBy: string;
    };
  } | null;
};

export type GetNotificationGroupPlansQueryVariables = Exact<{
  notificationGroupId: Scalars["UUID"]["input"];
}>;

export type GetNotificationGroupPlansQuery = {
  __typename?: "Query";
  notificationGroup?: {
    __typename?: "NotificationGroup";
    plans?: Array<{ __typename?: "Plan"; id: string; name: string }> | null;
  } | null;
};

export type GetNotificationGroupsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<NotificationGroupSortInput>;
}>;

export type GetNotificationGroupsQuery = {
  __typename?: "Query";
  notificationGroups: {
    __typename?: "NotificationGroupPage";
    result?: Array<{
      __typename?: "NotificationGroup";
      id: string;
      name: string;
      description?: string | null;
      channels?: Array<{
        __typename?: "Channel";
        id: string;
        typeProperties:
          | { __typename?: "EmailChannel"; email: string }
          | {
              __typename?: "WebhookChannel";
              url: string;
              payload?: string | null;
              method: string;
              type: WebhookChannelType;
              headers?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            };
      }> | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        updatedAt: string;
        createdAt: string;
        updatedBy: string;
        createdBy: string;
      };
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type UpdateNotificationGroupMutationVariables = Exact<{
  input: UpdateNotificationGroupInput;
}>;

export type UpdateNotificationGroupMutation = {
  __typename?: "Mutation";
  updateNotificationGroup: {
    __typename?: "NotificationGroup";
    id: string;
    name: string;
    description?: string | null;
    channels?: Array<{
      __typename?: "Channel";
      id: string;
      typeProperties:
        | { __typename?: "EmailChannel"; email: string }
        | {
            __typename?: "WebhookChannel";
            url: string;
            payload?: string | null;
            method: string;
            type: WebhookChannelType;
            headers?: Array<{
              __typename?: "KeyValue";
              key: string;
              value: string;
            }> | null;
          };
    }> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      updatedAt: string;
      createdAt: string;
      updatedBy: string;
      createdBy: string;
    };
  };
};

export type CreateApiKeyMutationVariables = Exact<{
  input: CreateApiKeyInput;
}>;

export type CreateApiKeyMutation = {
  __typename?: "Mutation";
  createApiKey: {
    __typename?: "CreateApiKeyResult";
    apiKeySecret: string;
    apiKey: {
      __typename?: "ApiKey";
      id: string;
      name: string;
      owner: string;
      description?: string | null;
      lastUsedAt?: string | null;
      expiresAt?: string | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdBy: string;
        createdAt: string;
        updatedBy: string;
        updatedAt: string;
      };
    };
  };
};

export type DeleteApiKeyMutationVariables = Exact<{
  apiKeyId: Scalars["UUID"]["input"];
}>;

export type DeleteApiKeyMutation = {
  __typename?: "Mutation";
  removeApiKey: boolean;
};

export type GetApiKeysQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ApiKeysSortInput>;
}>;

export type GetApiKeysQuery = {
  __typename?: "Query";
  apiKeys: {
    __typename?: "ApiKeysPage";
    result?: Array<{
      __typename?: "ApiKey";
      id: string;
      name: string;
      owner: string;
      description?: string | null;
      lastUsedAt?: string | null;
      expiresAt?: string | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
      roleBindings?: {
        __typename?: "RoleBindingsPage";
        pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
        result?: Array<{
          __typename?: "RoleBinding";
          id: string;
          role: { __typename?: "Role"; id: string; name: string };
        } | null> | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type UpdateApiKeyMutationVariables = Exact<{
  input: UpdateApiKeyInput;
}>;

export type UpdateApiKeyMutation = {
  __typename?: "Mutation";
  updateApiKey: {
    __typename?: "ApiKey";
    id: string;
    name: string;
    description?: string | null;
    expiresAt?: string | null;
    owner: string;
    roleBindings?: {
      __typename?: "RoleBindingsPage";
      result?: Array<{
        __typename?: "RoleBinding";
        id: string;
        role: { __typename?: "Role"; id: string; name: string };
      } | null> | null;
    } | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      updatedAt: string;
      updatedBy: string;
    };
  };
};

export type GetRoleQueryVariables = Exact<{
  roleId: Scalars["UUID"]["input"];
}>;

export type GetRoleQuery = {
  __typename?: "Query";
  role?: {
    __typename?: "Role";
    id: string;
    name: string;
    description?: string | null;
    rules: Array<{
      __typename?: "RoleRule";
      resources: Array<RbacResource>;
      actions: Array<RbacAction>;
    }>;
  } | null;
};

export type UpdateRoleMutationVariables = Exact<{
  input: RoleUpdateInput;
}>;

export type UpdateRoleMutation = {
  __typename?: "Mutation";
  updateRole: {
    __typename?: "Role";
    id: string;
    name: string;
    description?: string | null;
    rules: Array<{
      __typename?: "RoleRule";
      resources: Array<RbacResource>;
      actions: Array<RbacAction>;
    }>;
    changeDetails: {
      __typename?: "ChangeDetails";
      updatedAt: string;
      updatedBy: string;
    };
  };
};

export type CreateRoleMutationVariables = Exact<{
  input: RoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: "Mutation";
  createRole: { __typename?: "Role"; id: string };
};

export type DeleteRoleMutationVariables = Exact<{
  roleId: Scalars["UUID"]["input"];
}>;

export type DeleteRoleMutation = {
  __typename?: "Mutation";
  removeRole: boolean;
};

export type GetRolesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<RolesSortInput>;
}>;

export type GetRolesQuery = {
  __typename?: "Query";
  roles: {
    __typename?: "RolesPage";
    result?: Array<{
      __typename?: "Role";
      id: string;
      name: string;
      description?: string | null;
      rules: Array<{
        __typename?: "RoleRule";
        resources: Array<RbacResource>;
        actions: Array<RbacAction>;
      }>;
      changeDetails: {
        __typename?: "ChangeDetails";
        createdAt: string;
        createdBy: string;
        updatedAt: string;
        updatedBy: string;
      };
    } | null> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type GetRoleBindingsByUsersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  page: Scalars["Int"]["input"];
}>;

export type GetRoleBindingsByUsersQuery = {
  __typename?: "Query";
  roleBindingsByUsers?: {
    __typename?: "RoleBindingsByUsersPage";
    result?: Array<{
      __typename?: "RoleBindingByUser";
      customerId: string;
      type: RbacRoleBindingType;
      subject: string;
      roles: Array<{
        __typename?: "Role";
        id: string;
        name: string;
        description?: string | null;
        rules: Array<{
          __typename?: "RoleRule";
          resources: Array<RbacResource>;
          actions: Array<RbacAction>;
        }>;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdAt: string;
          createdBy: string;
          updatedAt: string;
          updatedBy: string;
        };
      }>;
    } | null> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  } | null;
};

export type CreateRoleBindingMutationVariables = Exact<{
  input: CreateRoleBindingInput;
}>;

export type CreateRoleBindingMutation = {
  __typename?: "Mutation";
  createRoleBinding: {
    __typename?: "RoleBinding";
    id: string;
    type: RbacRoleBindingType;
    subject: string;
    role: {
      __typename?: "Role";
      id: string;
      name: string;
      description?: string | null;
    };
  };
};

export type DeleteRoleBindingMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteRoleBindingMutation = {
  __typename?: "Mutation";
  removeRoleBinding: boolean;
};

export type CreatePlanMutationVariables = Exact<{
  input: CreatePlanInput;
}>;

export type CreatePlanMutation = {
  __typename?: "Mutation";
  createPlan: { __typename?: "Plan"; id: string };
};

export type DeletePlanMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type DeletePlanMutation = {
  __typename?: "Mutation";
  removePlan: boolean;
};

export type GenerateDatesFromCronMutationVariables = Exact<{
  input: GenerateDatesFromCronInput;
}>;

export type GenerateDatesFromCronMutation = {
  __typename?: "Mutation";
  generateDatesFromCron: {
    __typename?: "GenerateDatesFromCronResult";
    dates?: Array<string> | null;
    validationMsg?: string | null;
    error?: string | null;
  };
};

export type GetPlanQueryVariables = Exact<{
  planId: Scalars["UUID"]["input"];
}>;

export type GetPlanQuery = {
  __typename?: "Query";
  plan?: {
    __typename?: "Plan";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    scheduleCron?: string | null;
    scheduleOffset: number;
    scheduleTimezone?: string | null;
    scheduleExecutionDates?: Array<string> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      updatedAt: string;
      updatedBy: string;
      createdAt: string;
      createdBy: string;
    };
    metadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    planActions?: Array<{
      __typename?: "PlanAction";
      id: string;
      order: number;
      name: string;
      windowDuration: number;
      skipWindow: SkipWindowType;
      executionPolicy: ExecutionPolicy;
      runInSequence: boolean;
      resourceGroups?: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        description?: string | null;
        totalNumberOfResources: number;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdAt: string;
          createdBy: string;
          updatedAt: string;
          updatedBy: string;
        };
      }> | null;
      inputParameters?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      }> | null;
      action:
        | {
            __typename?: "Action";
            id: string;
            name: string;
            description?: string | null;
            color: string;
            type: ActionType;
            scope: ActionScope;
            synchronous: boolean;
            typeProperties:
              | { __typename: "ActionPropertyAWSLambda"; lambdaArn: string }
              | {
                  __typename: "ActionPropertyAzureFunction";
                  accessKeyId: string;
                  functionMethod: AzureFunctionMethod;
                  functionUrl: string;
                }
              | { __typename: "ActionPropertySSMDocument"; documentId: string }
              | { __typename: "ActionPropertyScript"; s3FileUrl: string }
              | {
                  __typename: "ActionPropertyWebhook";
                  webhookUrl: string;
                  webhookMethod: WebhookActionMethod;
                  payload: string;
                  headers: Array<{
                    __typename?: "KeyValue";
                    key: string;
                    value: string;
                  }>;
                };
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
            parameterDefinitions: Array<{
              __typename?: "ActionParameterDefinition";
              name: string;
              required: boolean;
              type: ActionParameterType;
            }>;
          }
        | {
            __typename?: "SystemAction";
            id: string;
            name: string;
            scope: ActionScope;
            color: string;
            version: string;
            parameterDefinitions: Array<{
              __typename?: "ActionParameterDefinition";
              name: string;
              required: boolean;
              description?: string | null;
              allowedValues?: Array<string> | null;
              defaultValue?: string | null;
              type: ActionParameterType;
            }>;
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
          };
      notificationGroups?: Array<{
        __typename?: "PlanActionNotificationGroup";
        id: string;
        triggerEvent: PlanActionNotificationTriggerType;
        inAdvance?: number | null;
        scope: PlanActionNotificationGroupScope;
        notificationGroup: {
          __typename?: "NotificationGroup";
          id: string;
          name: string;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdAt: string;
            createdBy: string;
            updatedAt: string;
            updatedBy: string;
          };
        };
      }> | null;
    }> | null;
    notificationGroups?: Array<{
      __typename?: "PlanNotificationGroup";
      id: string;
      triggerEvent: PlanNotificationTriggerType;
      inAdvance?: number | null;
      notificationGroup: {
        __typename?: "NotificationGroup";
        id: string;
        name: string;
        description?: string | null;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdAt: string;
          createdBy: string;
          updatedAt: string;
          updatedBy: string;
        };
      };
    }> | null;
  } | null;
};

export type GetPlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<PlanSortInput>;
}>;

export type GetPlansQuery = {
  __typename?: "Query";
  plans: {
    __typename?: "PlanPage";
    result?: Array<{
      __typename?: "Plan";
      id: string;
      name: string;
      description?: string | null;
      enabled: boolean;
      scheduleCron?: string | null;
      scheduleTimezone?: string | null;
      scheduleExecutionDates?: Array<string> | null;
      scheduleOffset: number;
      nextExecutionDates?: Array<string> | null;
      changeDetails: {
        __typename?: "ChangeDetails";
        updatedAt: string;
        updatedBy: string;
        createdAt: string;
        createdBy: string;
      };
      metadata?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      }> | null;
      planActions?: Array<{
        __typename?: "PlanAction";
        id: string;
        action:
          | { __typename?: "Action"; id: string }
          | { __typename?: "SystemAction"; id: string };
      }> | null;
      notificationGroups?: Array<{
        __typename?: "PlanNotificationGroup";
        notificationGroup: {
          __typename?: "NotificationGroup";
          id: string;
          name: string;
          description?: string | null;
        };
      }> | null;
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type GetSystemActionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<SystemActionsSortInput>;
}>;

export type GetSystemActionsQuery = {
  __typename?: "Query";
  systemActions: {
    __typename?: "SystemActionPage";
    result?: Array<{
      __typename?: "SystemAction";
      id: string;
      name: string;
      color: string;
      description?: string | null;
      version: string;
      scope: ActionScope;
      parameterDefinitions: Array<{
        __typename?: "ActionParameterDefinition";
        name: string;
        required: boolean;
        description?: string | null;
        allowedValues?: Array<string> | null;
        defaultValue?: string | null;
        type: ActionParameterType;
      }>;
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type TriggerPlanMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type TriggerPlanMutation = {
  __typename?: "Mutation";
  triggerPlan: {
    __typename?: "Event";
    id: string;
    plan?: { __typename?: "Plan"; id: string } | null;
  };
};

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanInput;
}>;

export type UpdatePlanMutation = {
  __typename?: "Mutation";
  updatePlan: {
    __typename?: "Plan";
    id: string;
    name: string;
    description?: string | null;
    enabled: boolean;
    scheduleCron?: string | null;
    scheduleOffset: number;
    scheduleTimezone?: string | null;
    scheduleExecutionDates?: Array<string> | null;
    metadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    planActions?: Array<{
      __typename?: "PlanAction";
      id: string;
      order: number;
      windowDuration: number;
      skipWindow: SkipWindowType;
      executionPolicy: ExecutionPolicy;
      runInSequence: boolean;
      resourceGroups?: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        description?: string | null;
        totalNumberOfResources: number;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdAt: string;
          createdBy: string;
          updatedAt: string;
          updatedBy: string;
        };
      }> | null;
      inputParameters?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      }> | null;
      action:
        | {
            __typename?: "Action";
            id: string;
            name: string;
            description?: string | null;
            color: string;
            type: ActionType;
            scope: ActionScope;
            synchronous: boolean;
            typeProperties:
              | { __typename: "ActionPropertyAWSLambda"; lambdaArn: string }
              | {
                  __typename: "ActionPropertyAzureFunction";
                  accessKeyId: string;
                  functionMethod: AzureFunctionMethod;
                  functionUrl: string;
                }
              | { __typename: "ActionPropertySSMDocument"; documentId: string }
              | { __typename: "ActionPropertyScript"; s3FileUrl: string }
              | {
                  __typename: "ActionPropertyWebhook";
                  webhookUrl: string;
                  webhookMethod: WebhookActionMethod;
                  payload: string;
                  headers: Array<{
                    __typename?: "KeyValue";
                    key: string;
                    value: string;
                  }>;
                };
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
            parameterDefinitions: Array<{
              __typename?: "ActionParameterDefinition";
              name: string;
              required: boolean;
              type: ActionParameterType;
            }>;
          }
        | {
            __typename?: "SystemAction";
            id: string;
            name: string;
            scope: ActionScope;
            color: string;
            version: string;
            parameterDefinitions: Array<{
              __typename?: "ActionParameterDefinition";
              name: string;
              required: boolean;
              description?: string | null;
              allowedValues?: Array<string> | null;
              defaultValue?: string | null;
              type: ActionParameterType;
            }>;
            changeDetails: {
              __typename?: "ChangeDetails";
              createdAt: string;
              createdBy: string;
              updatedAt: string;
              updatedBy: string;
            };
          };
      notificationGroups?: Array<{
        __typename?: "PlanActionNotificationGroup";
        id: string;
        triggerEvent: PlanActionNotificationTriggerType;
        scope: PlanActionNotificationGroupScope;
        notificationGroup: {
          __typename?: "NotificationGroup";
          id: string;
          name: string;
          changeDetails: {
            __typename?: "ChangeDetails";
            createdAt: string;
            createdBy: string;
            updatedAt: string;
            updatedBy: string;
          };
        };
      }> | null;
    }> | null;
    notificationGroups?: Array<{
      __typename?: "PlanNotificationGroup";
      id: string;
      triggerEvent: PlanNotificationTriggerType;
      inAdvance?: number | null;
      notificationGroup: {
        __typename?: "NotificationGroup";
        id: string;
        name: string;
      };
    }> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      updatedBy: string;
    };
  };
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: "Mutation";
  createGroup: { __typename?: "Group"; id: string; name: string };
};

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars["UUID"]["input"];
}>;

export type DeleteGroupMutation = {
  __typename?: "Mutation";
  removeGroup: boolean;
};

export type GetGroupPlansQueryVariables = Exact<{
  resourceGroupId: Scalars["UUID"]["input"];
}>;

export type GetGroupPlansQuery = {
  __typename?: "Query";
  group?: {
    __typename?: "Group";
    plans?: Array<{
      __typename?: "Plan";
      id: string;
      name: string;
    } | null> | null;
  } | null;
};

export type GetResourceGroupQueryVariables = Exact<{
  resourceGroupId: Scalars["UUID"]["input"];
}>;

export type GetResourceGroupQuery = {
  __typename?: "Query";
  group?: {
    __typename?: "Group";
    id: string;
    name: string;
    description?: string | null;
    metadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    resourceSelectors?: Array<{
      __typename?: "ResourceSelector";
      id: string;
      tagsExpression?: string | null;
      order: number;
      resource?: {
        __typename?: "Resource";
        id: string;
        name: string;
        description?: string | null;
        autoDiscovery: boolean;
        fullCloudResourceId: string;
        cloudResourceId: string;
        enabled: boolean;
        provider: ResourceCloudProvider;
        providerId: string;
        region: string;
        scanned: boolean;
        os?: {
          __typename?: "ResourceOS";
          type: OsType;
          subType?: OsSubType | null;
        } | null;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdAt: string;
          createdBy: string;
          updatedAt: string;
          updatedBy: string;
        };
      } | null;
      dynamicResources?: Array<{
        __typename?: "Resource";
        name: string;
        id: string;
        provider: ResourceCloudProvider;
        autoDiscovery: boolean;
        cloudResourceId: string;
        fullCloudResourceId: string;
        enabled: boolean;
        providerId: string;
        region: string;
        scanned: boolean;
        os?: {
          __typename?: "ResourceOS";
          type: OsType;
          subType?: OsSubType | null;
        } | null;
        changeDetails: {
          __typename?: "ChangeDetails";
          createdAt: string;
          createdBy: string;
          updatedAt: string;
          updatedBy: string;
        };
      }> | null;
    }> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      updatedBy: string;
    };
  } | null;
};

export type GetResourceGroupsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<GroupsSortInput>;
}>;

export type GetResourceGroupsQuery = {
  __typename?: "Query";
  groups: {
    __typename?: "GroupsPage";
    result?: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      resourceSelectors?: Array<{
        __typename?: "ResourceSelector";
        id: string;
        tagsExpression?: string | null;
        order: number;
        resource?: { __typename?: "Resource"; id: string; name: string } | null;
      }> | null;
    }> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  };
};

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
  __typename?: "Mutation";
  updateGroup: {
    __typename?: "Group";
    id: string;
    name: string;
    description?: string | null;
    metadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    resourceSelectors?: Array<{
      __typename?: "ResourceSelector";
      id: string;
      tagsExpression?: string | null;
      order: number;
      resource?: {
        __typename?: "Resource";
        id: string;
        name: string;
        description?: string | null;
        os?: {
          __typename?: "ResourceOS";
          type: OsType;
          subType?: OsSubType | null;
        } | null;
      } | null;
      dynamicResources?: Array<{
        __typename?: "Resource";
        name: string;
        id: string;
        provider: ResourceCloudProvider;
        os?: {
          __typename?: "ResourceOS";
          type: OsType;
          subType?: OsSubType | null;
        } | null;
      }> | null;
    }> | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      updatedBy: string;
    };
  };
};

export type DeleteResourceMutationVariables = Exact<{
  removeResourceId: Scalars["UUID"]["input"];
}>;

export type DeleteResourceMutation = {
  __typename?: "Mutation";
  removeResource?: boolean | null;
};

export type GetResourceQueryVariables = Exact<{
  resourceId: Scalars["UUID"]["input"];
}>;

export type GetResourceQuery = {
  __typename?: "Query";
  resource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    provider: ResourceCloudProvider;
    providerId: string;
    description?: string | null;
    cloudResourceId: string;
    fullCloudResourceId: string;
    autoDiscovery: boolean;
    enabled: boolean;
    region: string;
    scanned: boolean;
    tags?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    metadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    cloudMetadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
    os?: {
      __typename?: "ResourceOS";
      type: OsType;
      subType?: OsSubType | null;
      version?: string | null;
    } | null;
    access?: { __typename?: "SSMAccess"; instanceId: string } | null;
    state?: {
      __typename?: "ResourceState";
      status: ResourceStatus;
      error?: string | null;
      updatedAt: string;
      ssmMetadata?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      }> | null;
    } | null;
    latestInventory?: {
      __typename?: "ResourceInventory";
      status: InventoryStatus;
      error?: string | null;
      inventoryUri?: string | null;
      availablePackagesUri?: string | null;
      createdAt: string;
    } | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      createdAt: string;
      createdBy: string;
      updatedAt: string;
      updatedBy: string;
    };
  } | null;
};

export type ResourceFragment = {
  __typename?: "Resource";
  name: string;
  id: string;
  provider: ResourceCloudProvider;
  scanned: boolean;
  os?: {
    __typename?: "ResourceOS";
    type: OsType;
    subType?: OsSubType | null;
    version?: string | null;
  } | null;
  access?: { __typename?: "SSMAccess"; instanceId: string } | null;
  state?: {
    __typename?: "ResourceState";
    status: ResourceStatus;
    updatedAt: string;
    ssmMetadata?: Array<{
      __typename?: "KeyValue";
      key: string;
      value: string;
    }> | null;
  } | null;
};

export type ResourcePageFragment = {
  __typename?: "ResourcesPage";
  result?: Array<{
    __typename?: "Resource";
    name: string;
    id: string;
    provider: ResourceCloudProvider;
    scanned: boolean;
    os?: {
      __typename?: "ResourceOS";
      type: OsType;
      subType?: OsSubType | null;
      version?: string | null;
    } | null;
    access?: { __typename?: "SSMAccess"; instanceId: string } | null;
    state?: {
      __typename?: "ResourceState";
      status: ResourceStatus;
      updatedAt: string;
      ssmMetadata?: Array<{
        __typename?: "KeyValue";
        key: string;
        value: string;
      }> | null;
    } | null;
  } | null> | null;
  pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
};

export type GetResourcesQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  page: Scalars["Int"]["input"];
  sort?: InputMaybe<ResourcesSortInput>;
  filter?: InputMaybe<ResourceFilterInput>;
}>;

export type GetResourcesQuery = {
  __typename?: "Query";
  resources?: {
    __typename?: "ResourcesPage";
    result?: Array<{
      __typename?: "Resource";
      name: string;
      id: string;
      provider: ResourceCloudProvider;
      scanned: boolean;
      os?: {
        __typename?: "ResourceOS";
        type: OsType;
        subType?: OsSubType | null;
        version?: string | null;
      } | null;
      access?: { __typename?: "SSMAccess"; instanceId: string } | null;
      state?: {
        __typename?: "ResourceState";
        status: ResourceStatus;
        updatedAt: string;
        ssmMetadata?: Array<{
          __typename?: "KeyValue";
          key: string;
          value: string;
        }> | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  } | null;
};

export type GetResourcesByTagsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  tagsExpression: Scalars["String"]["input"];
}>;

export type GetResourcesByTagsQuery = {
  __typename?: "Query";
  resourcesByTags?: {
    __typename?: "ResourcesPage";
    result?: Array<{
      __typename?: "Resource";
      name: string;
      id: string;
      provider: ResourceCloudProvider;
      os?: {
        __typename?: "ResourceOS";
        type: OsType;
        subType?: OsSubType | null;
        version?: string | null;
      } | null;
      state?: {
        __typename?: "ResourceState";
        status: ResourceStatus;
        ssmMetadata?: Array<{
          __typename?: "KeyValue";
          key: string;
          value: string;
        }> | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  } | null;
};

export type ResourcesSearchQueryVariables = Exact<{
  input: ResourcesSearchInput;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<ResourcesSortInput>;
}>;

export type ResourcesSearchQuery = {
  __typename?: "Query";
  resourcesSearch?: {
    __typename?: "ResourcesPage";
    result?: Array<{
      __typename?: "Resource";
      name: string;
      id: string;
      provider: ResourceCloudProvider;
      scanned: boolean;
      os?: {
        __typename?: "ResourceOS";
        type: OsType;
        subType?: OsSubType | null;
        version?: string | null;
      } | null;
      access?: { __typename?: "SSMAccess"; instanceId: string } | null;
      state?: {
        __typename?: "ResourceState";
        status: ResourceStatus;
        updatedAt: string;
        ssmMetadata?: Array<{
          __typename?: "KeyValue";
          key: string;
          value: string;
        }> | null;
      } | null;
    } | null> | null;
    pageInfo: { __typename?: "PageInfo"; pages: number; count: number };
  } | null;
};

export type UpdateResourceMutationVariables = Exact<{
  input: UpdateResourceInput;
}>;

export type UpdateResourceMutation = {
  __typename?: "Mutation";
  updateResource: {
    __typename?: "Resource";
    id: string;
    name: string;
    description?: string | null;
    changeDetails: {
      __typename?: "ChangeDetails";
      updatedAt: string;
      updatedBy: string;
    };
  };
};

export const EventActionAttemptResourceStatesFragmentDoc = gql`
  fragment EventActionAttemptResourceStates on EventActionAttempt {
    resourceStates {
      id
      eventActionId
      resourceId
      resourceGroupId
      resource {
        id
        name
        description
        os {
          type
          subType
        }
        autoDiscovery
        cloudResourceId
        fullCloudResourceId
        enabled
        provider
        providerId
        region
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        scanned
      }
      resourceGroup {
        id
        name
        description
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        totalNumberOfResources
      }
      order
      status
      outputUri
      output {
        key
        value
      }
      annotation
      changeDetails {
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;
export const EventActionResourceStatesFragmentDoc = gql`
  fragment EventActionResourceStates on EventAction {
    resourceStates {
      id
      eventActionId
      resourceId
      resourceGroupId
      resource {
        id
        name
        description
        os {
          type
          subType
        }
        autoDiscovery
        cloudResourceId
        fullCloudResourceId
        enabled
        provider
        providerId
        region
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        scanned
      }
      resourceGroup {
        id
        name
        description
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        totalNumberOfResources
      }
      order
      status
      outputUri
      output {
        key
        value
      }
      annotation
      changeDetails {
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;
export const EventActionFragmentDoc = gql`
  fragment EventAction on EventAction {
    id
    action {
      ... on Action {
        id
        name
        type
        color
      }
      ... on SystemAction {
        id
        name
        version
        color
      }
    }
    name
    type
    scope
    order
    windowDuration
    skipWindow
    executionPolicy
    resourceGroups {
      id
      name
      description
      totalNumberOfResources
    }
    globalState {
      status
      estimatedStartTime
      startTime
      endTime
    }
    resourceStates {
      resource {
        id
        name
      }
      resourceGroup {
        id
        name
      }
      order
      status
      outputUri
      output {
        key
        value
      }
      changeDetails {
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;
export const EventActionsFragmentDoc = gql`
  fragment EventActions on Event {
    actions {
      ...EventAction
    }
  }
  ${EventActionFragmentDoc}
`;
export const ResourceFragmentDoc = gql`
  fragment Resource on Resource {
    name
    id
    provider
    scanned
    os {
      type
      subType
      version
    }
    access {
      ... on SSMAccess {
        instanceId
      }
    }
    state {
      status
      ssmMetadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const ResourcePageFragmentDoc = gql`
  fragment ResourcePage on ResourcesPage {
    result {
      ...Resource
    }
    pageInfo {
      pages
      count
    }
  }
  ${ResourceFragmentDoc}
`;
export const CustomerDocument = gql`
  query Customer {
    customers {
      id
      name
      organizationName
    }
  }
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(
    CustomerDocument,
    options
  );
}
export function useCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerQuery,
    CustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(
    CustomerDocument,
    options
  );
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<
  typeof useCustomerLazyQuery
>;
export type CustomerQueryResult = Apollo.QueryResult<
  CustomerQuery,
  CustomerQueryVariables
>;
export const AwsOnboardingTemplateDocument = gql`
  query awsOnboardingTemplate {
    awsOnboardingTemplate
  }
`;

/**
 * __useAwsOnboardingTemplateQuery__
 *
 * To run a query within a React component, call `useAwsOnboardingTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwsOnboardingTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwsOnboardingTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useAwsOnboardingTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AwsOnboardingTemplateQuery,
    AwsOnboardingTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AwsOnboardingTemplateQuery,
    AwsOnboardingTemplateQueryVariables
  >(AwsOnboardingTemplateDocument, options);
}
export function useAwsOnboardingTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AwsOnboardingTemplateQuery,
    AwsOnboardingTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AwsOnboardingTemplateQuery,
    AwsOnboardingTemplateQueryVariables
  >(AwsOnboardingTemplateDocument, options);
}
export type AwsOnboardingTemplateQueryHookResult = ReturnType<
  typeof useAwsOnboardingTemplateQuery
>;
export type AwsOnboardingTemplateLazyQueryHookResult = ReturnType<
  typeof useAwsOnboardingTemplateLazyQuery
>;
export type AwsOnboardingTemplateQueryResult = Apollo.QueryResult<
  AwsOnboardingTemplateQuery,
  AwsOnboardingTemplateQueryVariables
>;
export const AzureOnboardingScriptDocument = gql`
  query azureOnboardingScript($subscriptionId: UUID!) {
    azureOnboardingScript(subscriptionId: $subscriptionId)
  }
`;

/**
 * __useAzureOnboardingScriptQuery__
 *
 * To run a query within a React component, call `useAzureOnboardingScriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useAzureOnboardingScriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAzureOnboardingScriptQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useAzureOnboardingScriptQuery(
  baseOptions: Apollo.QueryHookOptions<
    AzureOnboardingScriptQuery,
    AzureOnboardingScriptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AzureOnboardingScriptQuery,
    AzureOnboardingScriptQueryVariables
  >(AzureOnboardingScriptDocument, options);
}
export function useAzureOnboardingScriptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AzureOnboardingScriptQuery,
    AzureOnboardingScriptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AzureOnboardingScriptQuery,
    AzureOnboardingScriptQueryVariables
  >(AzureOnboardingScriptDocument, options);
}
export type AzureOnboardingScriptQueryHookResult = ReturnType<
  typeof useAzureOnboardingScriptQuery
>;
export type AzureOnboardingScriptLazyQueryHookResult = ReturnType<
  typeof useAzureOnboardingScriptLazyQuery
>;
export type AzureOnboardingScriptQueryResult = Apollo.QueryResult<
  AzureOnboardingScriptQuery,
  AzureOnboardingScriptQueryVariables
>;
export const CreateCloudAccountDocument = gql`
  mutation createCloudAccount($input: CreateCloudAccountInput!) {
    createCloudAccount(input: $input) {
      id
    }
  }
`;
export type CreateCloudAccountMutationFn = Apollo.MutationFunction<
  CreateCloudAccountMutation,
  CreateCloudAccountMutationVariables
>;

/**
 * __useCreateCloudAccountMutation__
 *
 * To run a mutation, you first call `useCreateCloudAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCloudAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCloudAccountMutation, { data, loading, error }] = useCreateCloudAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCloudAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCloudAccountMutation,
    CreateCloudAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCloudAccountMutation,
    CreateCloudAccountMutationVariables
  >(CreateCloudAccountDocument, options);
}
export type CreateCloudAccountMutationHookResult = ReturnType<
  typeof useCreateCloudAccountMutation
>;
export type CreateCloudAccountMutationResult =
  Apollo.MutationResult<CreateCloudAccountMutation>;
export type CreateCloudAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateCloudAccountMutation,
  CreateCloudAccountMutationVariables
>;
export const DeleteCloudAccountDocument = gql`
  mutation deleteCloudAccount($removeCloudAccountId: UUID!) {
    removeCloudAccount(id: $removeCloudAccountId)
  }
`;
export type DeleteCloudAccountMutationFn = Apollo.MutationFunction<
  DeleteCloudAccountMutation,
  DeleteCloudAccountMutationVariables
>;

/**
 * __useDeleteCloudAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCloudAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCloudAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCloudAccountMutation, { data, loading, error }] = useDeleteCloudAccountMutation({
 *   variables: {
 *      removeCloudAccountId: // value for 'removeCloudAccountId'
 *   },
 * });
 */
export function useDeleteCloudAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCloudAccountMutation,
    DeleteCloudAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCloudAccountMutation,
    DeleteCloudAccountMutationVariables
  >(DeleteCloudAccountDocument, options);
}
export type DeleteCloudAccountMutationHookResult = ReturnType<
  typeof useDeleteCloudAccountMutation
>;
export type DeleteCloudAccountMutationResult =
  Apollo.MutationResult<DeleteCloudAccountMutation>;
export type DeleteCloudAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteCloudAccountMutation,
  DeleteCloudAccountMutationVariables
>;
export const GetCloudAccountDocument = gql`
  query getCloudAccount($cloudAccountId: UUID!) {
    cloudAccount(id: $cloudAccountId) {
      id
      name
      description
      provider
      providerId
      environment
      status
      lastScanStartDate
      lastScanEndDate
      regions
      resourceDiscoveryEnabled
      resourceCleanerEnabled
      metadata {
        key
        value
      }
      regionsStatuses {
        region
        status
        details
      }
      changeDetails {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

/**
 * __useGetCloudAccountQuery__
 *
 * To run a query within a React component, call `useGetCloudAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudAccountQuery({
 *   variables: {
 *      cloudAccountId: // value for 'cloudAccountId'
 *   },
 * });
 */
export function useGetCloudAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCloudAccountQuery,
    GetCloudAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCloudAccountQuery, GetCloudAccountQueryVariables>(
    GetCloudAccountDocument,
    options
  );
}
export function useGetCloudAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCloudAccountQuery,
    GetCloudAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCloudAccountQuery,
    GetCloudAccountQueryVariables
  >(GetCloudAccountDocument, options);
}
export type GetCloudAccountQueryHookResult = ReturnType<
  typeof useGetCloudAccountQuery
>;
export type GetCloudAccountLazyQueryHookResult = ReturnType<
  typeof useGetCloudAccountLazyQuery
>;
export type GetCloudAccountQueryResult = Apollo.QueryResult<
  GetCloudAccountQuery,
  GetCloudAccountQueryVariables
>;
export const GetCloudAccountsDocument = gql`
  query getCloudAccounts(
    $limit: Int!
    $page: Int!
    $sort: CloudAccountsSortInput
  ) {
    cloudAccounts(page: $page, limit: $limit, sort: $sort) {
      result {
        name
        id
        provider
        environment
        status
        providerId
        changeDetails {
          createdAt
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetCloudAccountsQuery__
 *
 * To run a query within a React component, call `useGetCloudAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudAccountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCloudAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCloudAccountsQuery,
    GetCloudAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCloudAccountsQuery, GetCloudAccountsQueryVariables>(
    GetCloudAccountsDocument,
    options
  );
}
export function useGetCloudAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCloudAccountsQuery,
    GetCloudAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCloudAccountsQuery,
    GetCloudAccountsQueryVariables
  >(GetCloudAccountsDocument, options);
}
export type GetCloudAccountsQueryHookResult = ReturnType<
  typeof useGetCloudAccountsQuery
>;
export type GetCloudAccountsLazyQueryHookResult = ReturnType<
  typeof useGetCloudAccountsLazyQuery
>;
export type GetCloudAccountsQueryResult = Apollo.QueryResult<
  GetCloudAccountsQuery,
  GetCloudAccountsQueryVariables
>;
export const ScanCloudAccountDocument = gql`
  mutation scanCloudAccount($id: UUID!) {
    scanCloudAccount(id: $id)
  }
`;
export type ScanCloudAccountMutationFn = Apollo.MutationFunction<
  ScanCloudAccountMutation,
  ScanCloudAccountMutationVariables
>;

/**
 * __useScanCloudAccountMutation__
 *
 * To run a mutation, you first call `useScanCloudAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScanCloudAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scanCloudAccountMutation, { data, loading, error }] = useScanCloudAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScanCloudAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScanCloudAccountMutation,
    ScanCloudAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScanCloudAccountMutation,
    ScanCloudAccountMutationVariables
  >(ScanCloudAccountDocument, options);
}
export type ScanCloudAccountMutationHookResult = ReturnType<
  typeof useScanCloudAccountMutation
>;
export type ScanCloudAccountMutationResult =
  Apollo.MutationResult<ScanCloudAccountMutation>;
export type ScanCloudAccountMutationOptions = Apollo.BaseMutationOptions<
  ScanCloudAccountMutation,
  ScanCloudAccountMutationVariables
>;
export const UpdateCloudAccountDocument = gql`
  mutation updateCloudAccount($input: UpdateCloudAccountInput!) {
    updateCloudAccount(input: $input) {
      id
      name
      description
      environment
      regionsStatuses {
        region
        status
        details
      }
      changeDetails {
        updatedAt
        updatedBy
      }
    }
  }
`;
export type UpdateCloudAccountMutationFn = Apollo.MutationFunction<
  UpdateCloudAccountMutation,
  UpdateCloudAccountMutationVariables
>;

/**
 * __useUpdateCloudAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCloudAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCloudAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCloudAccountMutation, { data, loading, error }] = useUpdateCloudAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCloudAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCloudAccountMutation,
    UpdateCloudAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCloudAccountMutation,
    UpdateCloudAccountMutationVariables
  >(UpdateCloudAccountDocument, options);
}
export type UpdateCloudAccountMutationHookResult = ReturnType<
  typeof useUpdateCloudAccountMutation
>;
export type UpdateCloudAccountMutationResult =
  Apollo.MutationResult<UpdateCloudAccountMutation>;
export type UpdateCloudAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateCloudAccountMutation,
  UpdateCloudAccountMutationVariables
>;
export const CreateCustomActionDocument = gql`
  mutation createCustomAction($input: CreateActionInput!) {
    createAction(input: $input) {
      id
      name
      description
      color
      type
      scope
    }
  }
`;
export type CreateCustomActionMutationFn = Apollo.MutationFunction<
  CreateCustomActionMutation,
  CreateCustomActionMutationVariables
>;

/**
 * __useCreateCustomActionMutation__
 *
 * To run a mutation, you first call `useCreateCustomActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomActionMutation, { data, loading, error }] = useCreateCustomActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomActionMutation,
    CreateCustomActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomActionMutation,
    CreateCustomActionMutationVariables
  >(CreateCustomActionDocument, options);
}
export type CreateCustomActionMutationHookResult = ReturnType<
  typeof useCreateCustomActionMutation
>;
export type CreateCustomActionMutationResult =
  Apollo.MutationResult<CreateCustomActionMutation>;
export type CreateCustomActionMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomActionMutation,
  CreateCustomActionMutationVariables
>;
export const DeleteCustomActionDocument = gql`
  mutation deleteCustomAction($id: UUID!) {
    removeAction(id: $id)
  }
`;
export type DeleteCustomActionMutationFn = Apollo.MutationFunction<
  DeleteCustomActionMutation,
  DeleteCustomActionMutationVariables
>;

/**
 * __useDeleteCustomActionMutation__
 *
 * To run a mutation, you first call `useDeleteCustomActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomActionMutation, { data, loading, error }] = useDeleteCustomActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomActionMutation,
    DeleteCustomActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomActionMutation,
    DeleteCustomActionMutationVariables
  >(DeleteCustomActionDocument, options);
}
export type DeleteCustomActionMutationHookResult = ReturnType<
  typeof useDeleteCustomActionMutation
>;
export type DeleteCustomActionMutationResult =
  Apollo.MutationResult<DeleteCustomActionMutation>;
export type DeleteCustomActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomActionMutation,
  DeleteCustomActionMutationVariables
>;
export const GetCustomActionDocument = gql`
  query getCustomAction($customActionId: UUID!) {
    action(id: $customActionId) {
      id
      name
      description
      color
      type
      scope
      typeProperties {
        __typename
        ... on ActionPropertyAWSLambda {
          lambdaArn
        }
        ... on ActionPropertyAzureFunction {
          accessKeyId
          functionMethod
          functionUrl
        }
        ... on ActionPropertyScript {
          s3FileUrl
        }
        ... on ActionPropertySSMDocument {
          documentId
        }
        ... on ActionPropertyWebhook {
          webhookUrl
          webhookMethod
          payload
          headers {
            key
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetCustomActionQuery__
 *
 * To run a query within a React component, call `useGetCustomActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomActionQuery({
 *   variables: {
 *      customActionId: // value for 'customActionId'
 *   },
 * });
 */
export function useGetCustomActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomActionQuery,
    GetCustomActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomActionQuery, GetCustomActionQueryVariables>(
    GetCustomActionDocument,
    options
  );
}
export function useGetCustomActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomActionQuery,
    GetCustomActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomActionQuery,
    GetCustomActionQueryVariables
  >(GetCustomActionDocument, options);
}
export type GetCustomActionQueryHookResult = ReturnType<
  typeof useGetCustomActionQuery
>;
export type GetCustomActionLazyQueryHookResult = ReturnType<
  typeof useGetCustomActionLazyQuery
>;
export type GetCustomActionQueryResult = Apollo.QueryResult<
  GetCustomActionQuery,
  GetCustomActionQueryVariables
>;
export const GetCustomActionsDocument = gql`
  query getCustomActions($page: Int, $limit: Int, $sort: ActionSortInput) {
    actions(page: $page, limit: $limit, sort: $sort) {
      result {
        id
        name
        color
        description
        scope
        type
        synchronous
        parameterDefinitions {
          name
          required
          description
          allowedValues
          defaultValue
          type
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetCustomActionsQuery__
 *
 * To run a query within a React component, call `useGetCustomActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomActionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCustomActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomActionsQuery,
    GetCustomActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomActionsQuery, GetCustomActionsQueryVariables>(
    GetCustomActionsDocument,
    options
  );
}
export function useGetCustomActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomActionsQuery,
    GetCustomActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomActionsQuery,
    GetCustomActionsQueryVariables
  >(GetCustomActionsDocument, options);
}
export type GetCustomActionsQueryHookResult = ReturnType<
  typeof useGetCustomActionsQuery
>;
export type GetCustomActionsLazyQueryHookResult = ReturnType<
  typeof useGetCustomActionsLazyQuery
>;
export type GetCustomActionsQueryResult = Apollo.QueryResult<
  GetCustomActionsQuery,
  GetCustomActionsQueryVariables
>;
export const GetCustomActionPlansDocument = gql`
  query getCustomActionPlans($page: Int, $limit: Int, $sort: PlanSortInput) {
    plans(page: $page, limit: $limit, sort: $sort) {
      result {
        name
        planActions {
          action {
            ... on Action {
              name
              id
            }
          }
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetCustomActionPlansQuery__
 *
 * To run a query within a React component, call `useGetCustomActionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomActionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomActionPlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetCustomActionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomActionPlansQuery,
    GetCustomActionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomActionPlansQuery,
    GetCustomActionPlansQueryVariables
  >(GetCustomActionPlansDocument, options);
}
export function useGetCustomActionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomActionPlansQuery,
    GetCustomActionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomActionPlansQuery,
    GetCustomActionPlansQueryVariables
  >(GetCustomActionPlansDocument, options);
}
export type GetCustomActionPlansQueryHookResult = ReturnType<
  typeof useGetCustomActionPlansQuery
>;
export type GetCustomActionPlansLazyQueryHookResult = ReturnType<
  typeof useGetCustomActionPlansLazyQuery
>;
export type GetCustomActionPlansQueryResult = Apollo.QueryResult<
  GetCustomActionPlansQuery,
  GetCustomActionPlansQueryVariables
>;
export const UpdateCustomActionDocument = gql`
  mutation updateCustomAction($input: UpdateActionInput!) {
    updateAction(input: $input) {
      id
      name
      description
      type
      scope
      typeProperties {
        __typename
        ... on ActionPropertyAWSLambda {
          lambdaArn
        }
        ... on ActionPropertyAzureFunction {
          accessKeyId
          functionUrl
          functionMethod
        }
        ... on ActionPropertyScript {
          s3FileUrl
        }
        ... on ActionPropertySSMDocument {
          documentId
        }
        ... on ActionPropertyWebhook {
          webhookUrl
          webhookMethod
          payload
          headers {
            key
            value
          }
        }
      }
    }
  }
`;
export type UpdateCustomActionMutationFn = Apollo.MutationFunction<
  UpdateCustomActionMutation,
  UpdateCustomActionMutationVariables
>;

/**
 * __useUpdateCustomActionMutation__
 *
 * To run a mutation, you first call `useUpdateCustomActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomActionMutation, { data, loading, error }] = useUpdateCustomActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomActionMutation,
    UpdateCustomActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustomActionMutation,
    UpdateCustomActionMutationVariables
  >(UpdateCustomActionDocument, options);
}
export type UpdateCustomActionMutationHookResult = ReturnType<
  typeof useUpdateCustomActionMutation
>;
export type UpdateCustomActionMutationResult =
  Apollo.MutationResult<UpdateCustomActionMutation>;
export type UpdateCustomActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomActionMutation,
  UpdateCustomActionMutationVariables
>;
export const GetEventActionReportDocument = gql`
  query getEventActionReport($eventId: UUID!, $eventActionId: UUID!) {
    eventActionReport(eventId: $eventId, eventActionId: $eventActionId) {
      eventId
      eventActionId
      name
      actionName
      status
      startTime
      endTime
      output
      resources {
        resourceId
        name
        status
        result {
          installed {
            name
            id
            version
          }
          updated {
            name
            id
            version
          }
          removed {
            name
            id
            version
          }
          available {
            name
            id
            version
          }
          failed {
            name
            id
            version
          }
          output
          outputUri
        }
      }
    }
  }
`;

/**
 * __useGetEventActionReportQuery__
 *
 * To run a query within a React component, call `useGetEventActionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventActionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventActionReportQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      eventActionId: // value for 'eventActionId'
 *   },
 * });
 */
export function useGetEventActionReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventActionReportQuery,
    GetEventActionReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventActionReportQuery,
    GetEventActionReportQueryVariables
  >(GetEventActionReportDocument, options);
}
export function useGetEventActionReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventActionReportQuery,
    GetEventActionReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventActionReportQuery,
    GetEventActionReportQueryVariables
  >(GetEventActionReportDocument, options);
}
export type GetEventActionReportQueryHookResult = ReturnType<
  typeof useGetEventActionReportQuery
>;
export type GetEventActionReportLazyQueryHookResult = ReturnType<
  typeof useGetEventActionReportLazyQuery
>;
export type GetEventActionReportQueryResult = Apollo.QueryResult<
  GetEventActionReportQuery,
  GetEventActionReportQueryVariables
>;
export const GetEventActionReportsDocument = gql`
  query getEventActionReports($eventId: UUID!, $eventActionId: UUID!) {
    eventActionReports(eventId: $eventId, eventActionId: $eventActionId) {
      attempt
      report {
        eventId
        eventActionId
        name
        actionName
        status
        startTime
        endTime
        output
        resources {
          resourceId
          name
          status
          result {
            installed {
              name
              id
              version
            }
            updated {
              name
              id
              version
              previousVersion
            }
            removed {
              name
              id
              version
            }
            available {
              name
              id
              version
            }
            failed {
              name
              id
              version
            }
            output
            outputUri
          }
        }
      }
    }
  }
`;

/**
 * __useGetEventActionReportsQuery__
 *
 * To run a query within a React component, call `useGetEventActionReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventActionReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventActionReportsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      eventActionId: // value for 'eventActionId'
 *   },
 * });
 */
export function useGetEventActionReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventActionReportsQuery,
    GetEventActionReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventActionReportsQuery,
    GetEventActionReportsQueryVariables
  >(GetEventActionReportsDocument, options);
}
export function useGetEventActionReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventActionReportsQuery,
    GetEventActionReportsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventActionReportsQuery,
    GetEventActionReportsQueryVariables
  >(GetEventActionReportsDocument, options);
}
export type GetEventActionReportsQueryHookResult = ReturnType<
  typeof useGetEventActionReportsQuery
>;
export type GetEventActionReportsLazyQueryHookResult = ReturnType<
  typeof useGetEventActionReportsLazyQuery
>;
export type GetEventActionReportsQueryResult = Apollo.QueryResult<
  GetEventActionReportsQuery,
  GetEventActionReportsQueryVariables
>;
export const EventResourceStatusChangedDocument = gql`
  subscription eventResourceStatusChanged($input: StatusChangedInput!) {
    eventResourceStatusChanged(input: $input)
  }
`;

/**
 * __useEventResourceStatusChangedSubscription__
 *
 * To run a query within a React component, call `useEventResourceStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventResourceStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventResourceStatusChangedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventResourceStatusChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventResourceStatusChangedSubscription,
    EventResourceStatusChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    EventResourceStatusChangedSubscription,
    EventResourceStatusChangedSubscriptionVariables
  >(EventResourceStatusChangedDocument, options);
}
export type EventResourceStatusChangedSubscriptionHookResult = ReturnType<
  typeof useEventResourceStatusChangedSubscription
>;
export type EventResourceStatusChangedSubscriptionResult =
  Apollo.SubscriptionResult<EventResourceStatusChangedSubscription>;
export const EventActionStatusChangedDocument = gql`
  subscription eventActionStatusChanged($input: StatusChangedInput!) {
    eventActionStatusChanged(input: $input)
  }
`;

/**
 * __useEventActionStatusChangedSubscription__
 *
 * To run a query within a React component, call `useEventActionStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventActionStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventActionStatusChangedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventActionStatusChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventActionStatusChangedSubscription,
    EventActionStatusChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    EventActionStatusChangedSubscription,
    EventActionStatusChangedSubscriptionVariables
  >(EventActionStatusChangedDocument, options);
}
export type EventActionStatusChangedSubscriptionHookResult = ReturnType<
  typeof useEventActionStatusChangedSubscription
>;
export type EventActionStatusChangedSubscriptionResult =
  Apollo.SubscriptionResult<EventActionStatusChangedSubscription>;
export const EventStatusChangedDocument = gql`
  subscription eventStatusChanged($input: StatusChangedInput!) {
    eventStatusChanged(input: $input)
  }
`;

/**
 * __useEventStatusChangedSubscription__
 *
 * To run a query within a React component, call `useEventStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventStatusChangedSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventStatusChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    EventStatusChangedSubscription,
    EventStatusChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    EventStatusChangedSubscription,
    EventStatusChangedSubscriptionVariables
  >(EventStatusChangedDocument, options);
}
export type EventStatusChangedSubscriptionHookResult = ReturnType<
  typeof useEventStatusChangedSubscription
>;
export type EventStatusChangedSubscriptionResult =
  Apollo.SubscriptionResult<EventStatusChangedSubscription>;
export const ApproveEventActionDocument = gql`
  mutation approveEventAction($eventId: UUID!, $actionId: UUID!) {
    approveEventAction(eventId: $eventId, eventActionId: $actionId)
  }
`;
export type ApproveEventActionMutationFn = Apollo.MutationFunction<
  ApproveEventActionMutation,
  ApproveEventActionMutationVariables
>;

/**
 * __useApproveEventActionMutation__
 *
 * To run a mutation, you first call `useApproveEventActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveEventActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveEventActionMutation, { data, loading, error }] = useApproveEventActionMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useApproveEventActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveEventActionMutation,
    ApproveEventActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveEventActionMutation,
    ApproveEventActionMutationVariables
  >(ApproveEventActionDocument, options);
}
export type ApproveEventActionMutationHookResult = ReturnType<
  typeof useApproveEventActionMutation
>;
export type ApproveEventActionMutationResult =
  Apollo.MutationResult<ApproveEventActionMutation>;
export type ApproveEventActionMutationOptions = Apollo.BaseMutationOptions<
  ApproveEventActionMutation,
  ApproveEventActionMutationVariables
>;
export const ChangeResourceExecutionStatusDocument = gql`
  mutation changeResourceExecutionStatus(
    $input: ChangeResourceExecutionStatusInput!
  ) {
    changeResourceExecutionStatus(input: $input)
  }
`;
export type ChangeResourceExecutionStatusMutationFn = Apollo.MutationFunction<
  ChangeResourceExecutionStatusMutation,
  ChangeResourceExecutionStatusMutationVariables
>;

/**
 * __useChangeResourceExecutionStatusMutation__
 *
 * To run a mutation, you first call `useChangeResourceExecutionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeResourceExecutionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeResourceExecutionStatusMutation, { data, loading, error }] = useChangeResourceExecutionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeResourceExecutionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeResourceExecutionStatusMutation,
    ChangeResourceExecutionStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeResourceExecutionStatusMutation,
    ChangeResourceExecutionStatusMutationVariables
  >(ChangeResourceExecutionStatusDocument, options);
}
export type ChangeResourceExecutionStatusMutationHookResult = ReturnType<
  typeof useChangeResourceExecutionStatusMutation
>;
export type ChangeResourceExecutionStatusMutationResult =
  Apollo.MutationResult<ChangeResourceExecutionStatusMutation>;
export type ChangeResourceExecutionStatusMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeResourceExecutionStatusMutation,
    ChangeResourceExecutionStatusMutationVariables
  >;
export const GetEventDocument = gql`
  query getEvent($id: UUID!) {
    event(id: $id) {
      id
      name
      description
      scheduleTimezone
      estimatedEndTime
      status
      startTime
      metadata {
        key
        value
      }
      plan {
        id
        name
        scheduleCron
        scheduleOffset
        scheduleTimezone
        enabled
        planActions {
          id
          runInSequence
          executionPolicy
          order
          skipWindow
          windowDuration
          action {
            ... on Action {
              id
              name
              color
              synchronous
              type
              typeProperties {
                ... on ActionPropertyAWSLambda {
                  lambdaArn
                }
                ... on ActionPropertyAzureFunction {
                  accessKeyId
                  functionMethod
                  functionUrl
                }
                ... on ActionPropertyScript {
                  s3FileUrl
                }
                ... on ActionPropertySSMDocument {
                  documentId
                }
                ... on ActionPropertyWebhook {
                  webhookUrl
                  webhookMethod
                  payload
                  headers {
                    key
                    value
                  }
                }
              }
              parameterDefinitions {
                name
                required
                type
              }
              scope
              changeDetails {
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
            ... on SystemAction {
              id
              name
              scope
              color
              scope
              version
              scope
              parameterDefinitions {
                name
                required
                type
              }
              changeDetails {
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          resourceGroups {
            id
            name
            description
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      actions {
        id
        actionName
        globalState {
          status
          outputUri
          estimatedStartTime
          startTime
          endTime
        }
        attempts {
          id
          attempt
          state {
            eventId
            eventActionId
            status
            outputUri
            estimatedStartTime
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          ...EventActionAttemptResourceStates
        }
        inputParameters {
          key
          value
        }
        action {
          ... on Action {
            id
            name
            type
            color
            scope
            synchronous
            typeProperties {
              ... on ActionPropertyAWSLambda {
                lambdaArn
              }
              ... on ActionPropertyAzureFunction {
                accessKeyId
                functionMethod
                functionUrl
              }
              ... on ActionPropertyScript {
                s3FileUrl
              }
              ... on ActionPropertySSMDocument {
                documentId
              }
              ... on ActionPropertyWebhook {
                webhookUrl
                webhookMethod
                payload
                headers {
                  key
                  value
                }
              }
            }
            parameterDefinitions {
              name
              required
              type
            }
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          ... on SystemAction {
            id
            name
            scope
            color
            scope
            version
            scope
            parameterDefinitions {
              name
              required
              type
            }
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        name
        type
        scope
        order
        windowDuration
        skipWindow
        executionPolicy
        runInSequence
        resourceGroups {
          id
          name
          description
          changeDetails {
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          totalNumberOfResources
        }
        ...EventActionResourceStates
      }
      changeDetails {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
  ${EventActionAttemptResourceStatesFragmentDoc}
  ${EventActionResourceStatesFragmentDoc}
`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export function useGetEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventQuery,
    GetEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<
  typeof useGetEventLazyQuery
>;
export type GetEventQueryResult = Apollo.QueryResult<
  GetEventQuery,
  GetEventQueryVariables
>;
export const GetEventActionsResourceStatesDocument = gql`
  query getEventActionsResourceStates($id: UUID!) {
    event(id: $id) {
      actions {
        id
        resourceStates {
          id
        }
      }
    }
  }
`;

/**
 * __useGetEventActionsResourceStatesQuery__
 *
 * To run a query within a React component, call `useGetEventActionsResourceStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventActionsResourceStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventActionsResourceStatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventActionsResourceStatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventActionsResourceStatesQuery,
    GetEventActionsResourceStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventActionsResourceStatesQuery,
    GetEventActionsResourceStatesQueryVariables
  >(GetEventActionsResourceStatesDocument, options);
}
export function useGetEventActionsResourceStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventActionsResourceStatesQuery,
    GetEventActionsResourceStatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventActionsResourceStatesQuery,
    GetEventActionsResourceStatesQueryVariables
  >(GetEventActionsResourceStatesDocument, options);
}
export type GetEventActionsResourceStatesQueryHookResult = ReturnType<
  typeof useGetEventActionsResourceStatesQuery
>;
export type GetEventActionsResourceStatesLazyQueryHookResult = ReturnType<
  typeof useGetEventActionsResourceStatesLazyQuery
>;
export type GetEventActionsResourceStatesQueryResult = Apollo.QueryResult<
  GetEventActionsResourceStatesQuery,
  GetEventActionsResourceStatesQueryVariables
>;
export const GetEventsDocument = gql`
  query getEvents(
    $page: Int
    $limit: Int
    $sort: EventsSortInput
    $filter: EventsFilterInput
  ) {
    events(page: $page, limit: $limit, sort: $sort, filter: $filter) {
      result {
        id
        name
        status
        scheduleTimezone
        estimatedEndTime
        startTime
        plan {
          name
        }
        metadata {
          key
          value
        }
        ...EventActions
        description
        changeDetails {
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
  ${EventActionsFragmentDoc}
`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options
  );
}
export function useGetEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsQuery,
    GetEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options
  );
}
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<
  typeof useGetEventsLazyQuery
>;
export type GetEventsQueryResult = Apollo.QueryResult<
  GetEventsQuery,
  GetEventsQueryVariables
>;
export const GetIncomingEventDocument = gql`
  query getIncomingEvent($id: UUID!) {
    incomingEvent(eventId: $id) {
      id
      name
      scheduleTimezone
      estimatedEndTime
      status
      startTime
      plan {
        id
        name
        scheduleCron
        scheduleOffset
        scheduleTimezone
        enabled
        planActions {
          id
          name
          runInSequence
          executionPolicy
          order
          skipWindow
          windowDuration
          action {
            ... on Action {
              id
              name
              color
              synchronous
              type
              typeProperties {
                ... on ActionPropertyAWSLambda {
                  lambdaArn
                }
                ... on ActionPropertyAzureFunction {
                  accessKeyId
                  functionMethod
                  functionUrl
                }
                ... on ActionPropertyScript {
                  s3FileUrl
                }
                ... on ActionPropertySSMDocument {
                  documentId
                }
                ... on ActionPropertyWebhook {
                  webhookUrl
                  webhookMethod
                  payload
                  headers {
                    key
                    value
                  }
                }
              }
              parameterDefinitions {
                name
                required
                type
              }
              scope
              changeDetails {
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
            ... on SystemAction {
              id
              name
              scope
              color
              scope
              version
              scope
              parameterDefinitions {
                name
                required
                type
              }
              changeDetails {
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          resourceGroups {
            id
            name
            description
            totalNumberOfResources
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      changeDetails {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

/**
 * __useGetIncomingEventQuery__
 *
 * To run a query within a React component, call `useGetIncomingEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIncomingEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIncomingEventQuery,
    GetIncomingEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIncomingEventQuery, GetIncomingEventQueryVariables>(
    GetIncomingEventDocument,
    options
  );
}
export function useGetIncomingEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingEventQuery,
    GetIncomingEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingEventQuery,
    GetIncomingEventQueryVariables
  >(GetIncomingEventDocument, options);
}
export type GetIncomingEventQueryHookResult = ReturnType<
  typeof useGetIncomingEventQuery
>;
export type GetIncomingEventLazyQueryHookResult = ReturnType<
  typeof useGetIncomingEventLazyQuery
>;
export type GetIncomingEventQueryResult = Apollo.QueryResult<
  GetIncomingEventQuery,
  GetIncomingEventQueryVariables
>;
export const GetIncomingEventsDocument = gql`
  query getIncomingEvents(
    $page: Int
    $limit: Int
    $sort: IncomingEventsSortInput
    $filter: IncomingEventsFilterInput
  ) {
    incomingEvents(page: $page, limit: $limit, sort: $sort, filter: $filter) {
      result {
        id
        name
        status
        scheduleTimezone
        estimatedEndTime
        startTime
        changeDetails {
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
        plan {
          id
          planActions {
            resourceGroups {
              totalNumberOfResources
            }
            action {
              ... on Action {
                name
              }
              ... on SystemAction {
                name
              }
            }
          }
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetIncomingEventsQuery__
 *
 * To run a query within a React component, call `useGetIncomingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetIncomingEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIncomingEventsQuery,
    GetIncomingEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIncomingEventsQuery,
    GetIncomingEventsQueryVariables
  >(GetIncomingEventsDocument, options);
}
export function useGetIncomingEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIncomingEventsQuery,
    GetIncomingEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIncomingEventsQuery,
    GetIncomingEventsQueryVariables
  >(GetIncomingEventsDocument, options);
}
export type GetIncomingEventsQueryHookResult = ReturnType<
  typeof useGetIncomingEventsQuery
>;
export type GetIncomingEventsLazyQueryHookResult = ReturnType<
  typeof useGetIncomingEventsLazyQuery
>;
export type GetIncomingEventsQueryResult = Apollo.QueryResult<
  GetIncomingEventsQuery,
  GetIncomingEventsQueryVariables
>;
export const GetPresignedUrlDocument = gql`
  query getPresignedUrl($url: String!) {
    presignedEventActionOutputUrl(url: $url)
  }
`;

/**
 * __useGetPresignedUrlQuery__
 *
 * To run a query within a React component, call `useGetPresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresignedUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetPresignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPresignedUrlQuery,
    GetPresignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPresignedUrlQuery, GetPresignedUrlQueryVariables>(
    GetPresignedUrlDocument,
    options
  );
}
export function useGetPresignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPresignedUrlQuery,
    GetPresignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPresignedUrlQuery,
    GetPresignedUrlQueryVariables
  >(GetPresignedUrlDocument, options);
}
export type GetPresignedUrlQueryHookResult = ReturnType<
  typeof useGetPresignedUrlQuery
>;
export type GetPresignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetPresignedUrlLazyQuery
>;
export type GetPresignedUrlQueryResult = Apollo.QueryResult<
  GetPresignedUrlQuery,
  GetPresignedUrlQueryVariables
>;
export const RerunEventActionDocument = gql`
  mutation rerunEventAction($input: RerunEventActionInput!) {
    rerunEventAction(input: $input)
  }
`;
export type RerunEventActionMutationFn = Apollo.MutationFunction<
  RerunEventActionMutation,
  RerunEventActionMutationVariables
>;

/**
 * __useRerunEventActionMutation__
 *
 * To run a mutation, you first call `useRerunEventActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRerunEventActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rerunEventActionMutation, { data, loading, error }] = useRerunEventActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRerunEventActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RerunEventActionMutation,
    RerunEventActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RerunEventActionMutation,
    RerunEventActionMutationVariables
  >(RerunEventActionDocument, options);
}
export type RerunEventActionMutationHookResult = ReturnType<
  typeof useRerunEventActionMutation
>;
export type RerunEventActionMutationResult =
  Apollo.MutationResult<RerunEventActionMutation>;
export type RerunEventActionMutationOptions = Apollo.BaseMutationOptions<
  RerunEventActionMutation,
  RerunEventActionMutationVariables
>;
export const RevokeIncomingEventDocument = gql`
  mutation revokeIncomingEvent($eventId: UUID!) {
    revokeIncomingEvent(eventId: $eventId)
  }
`;
export type RevokeIncomingEventMutationFn = Apollo.MutationFunction<
  RevokeIncomingEventMutation,
  RevokeIncomingEventMutationVariables
>;

/**
 * __useRevokeIncomingEventMutation__
 *
 * To run a mutation, you first call `useRevokeIncomingEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeIncomingEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeIncomingEventMutation, { data, loading, error }] = useRevokeIncomingEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useRevokeIncomingEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeIncomingEventMutation,
    RevokeIncomingEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeIncomingEventMutation,
    RevokeIncomingEventMutationVariables
  >(RevokeIncomingEventDocument, options);
}
export type RevokeIncomingEventMutationHookResult = ReturnType<
  typeof useRevokeIncomingEventMutation
>;
export type RevokeIncomingEventMutationResult =
  Apollo.MutationResult<RevokeIncomingEventMutation>;
export type RevokeIncomingEventMutationOptions = Apollo.BaseMutationOptions<
  RevokeIncomingEventMutation,
  RevokeIncomingEventMutationVariables
>;
export const CreateNotificationGroupDocument = gql`
  mutation createNotificationGroup($input: CreateNotificationGroupInput!) {
    createNotificationGroup(input: $input) {
      id
      name
    }
  }
`;
export type CreateNotificationGroupMutationFn = Apollo.MutationFunction<
  CreateNotificationGroupMutation,
  CreateNotificationGroupMutationVariables
>;

/**
 * __useCreateNotificationGroupMutation__
 *
 * To run a mutation, you first call `useCreateNotificationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationGroupMutation, { data, loading, error }] = useCreateNotificationGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotificationGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationGroupMutation,
    CreateNotificationGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateNotificationGroupMutation,
    CreateNotificationGroupMutationVariables
  >(CreateNotificationGroupDocument, options);
}
export type CreateNotificationGroupMutationHookResult = ReturnType<
  typeof useCreateNotificationGroupMutation
>;
export type CreateNotificationGroupMutationResult =
  Apollo.MutationResult<CreateNotificationGroupMutation>;
export type CreateNotificationGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationGroupMutation,
  CreateNotificationGroupMutationVariables
>;
export const DeleteNotificationGroupDocument = gql`
  mutation deleteNotificationGroup($id: UUID!) {
    removeNotificationGroup(id: $id)
  }
`;
export type DeleteNotificationGroupMutationFn = Apollo.MutationFunction<
  DeleteNotificationGroupMutation,
  DeleteNotificationGroupMutationVariables
>;

/**
 * __useDeleteNotificationGroupMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationGroupMutation, { data, loading, error }] = useDeleteNotificationGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationGroupMutation,
    DeleteNotificationGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationGroupMutation,
    DeleteNotificationGroupMutationVariables
  >(DeleteNotificationGroupDocument, options);
}
export type DeleteNotificationGroupMutationHookResult = ReturnType<
  typeof useDeleteNotificationGroupMutation
>;
export type DeleteNotificationGroupMutationResult =
  Apollo.MutationResult<DeleteNotificationGroupMutation>;
export type DeleteNotificationGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationGroupMutation,
  DeleteNotificationGroupMutationVariables
>;
export const GetNotificationGroupDocument = gql`
  query getNotificationGroup($id: UUID!) {
    notificationGroup(id: $id) {
      id
      name
      description
      channels {
        id
        typeProperties {
          ... on EmailChannel {
            email
          }
          ... on WebhookChannel {
            url
            payload
            headers {
              key
              value
            }
            method
            type
          }
        }
      }
      changeDetails {
        updatedAt
        createdAt
        updatedBy
        createdBy
      }
    }
  }
`;

/**
 * __useGetNotificationGroupQuery__
 *
 * To run a query within a React component, call `useGetNotificationGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationGroupQuery,
    GetNotificationGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationGroupQuery,
    GetNotificationGroupQueryVariables
  >(GetNotificationGroupDocument, options);
}
export function useGetNotificationGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationGroupQuery,
    GetNotificationGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationGroupQuery,
    GetNotificationGroupQueryVariables
  >(GetNotificationGroupDocument, options);
}
export type GetNotificationGroupQueryHookResult = ReturnType<
  typeof useGetNotificationGroupQuery
>;
export type GetNotificationGroupLazyQueryHookResult = ReturnType<
  typeof useGetNotificationGroupLazyQuery
>;
export type GetNotificationGroupQueryResult = Apollo.QueryResult<
  GetNotificationGroupQuery,
  GetNotificationGroupQueryVariables
>;
export const GetNotificationGroupPlansDocument = gql`
  query getNotificationGroupPlans($notificationGroupId: UUID!) {
    notificationGroup(id: $notificationGroupId) {
      plans {
        id
        name
      }
    }
  }
`;

/**
 * __useGetNotificationGroupPlansQuery__
 *
 * To run a query within a React component, call `useGetNotificationGroupPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationGroupPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationGroupPlansQuery({
 *   variables: {
 *      notificationGroupId: // value for 'notificationGroupId'
 *   },
 * });
 */
export function useGetNotificationGroupPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationGroupPlansQuery,
    GetNotificationGroupPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationGroupPlansQuery,
    GetNotificationGroupPlansQueryVariables
  >(GetNotificationGroupPlansDocument, options);
}
export function useGetNotificationGroupPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationGroupPlansQuery,
    GetNotificationGroupPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationGroupPlansQuery,
    GetNotificationGroupPlansQueryVariables
  >(GetNotificationGroupPlansDocument, options);
}
export type GetNotificationGroupPlansQueryHookResult = ReturnType<
  typeof useGetNotificationGroupPlansQuery
>;
export type GetNotificationGroupPlansLazyQueryHookResult = ReturnType<
  typeof useGetNotificationGroupPlansLazyQuery
>;
export type GetNotificationGroupPlansQueryResult = Apollo.QueryResult<
  GetNotificationGroupPlansQuery,
  GetNotificationGroupPlansQueryVariables
>;
export const GetNotificationGroupsDocument = gql`
  query getNotificationGroups(
    $page: Int
    $limit: Int
    $sort: NotificationGroupSortInput
  ) {
    notificationGroups(page: $page, limit: $limit, sort: $sort) {
      result {
        id
        name
        description
        channels {
          id
          typeProperties {
            ... on EmailChannel {
              email
            }
            ... on WebhookChannel {
              url
              payload
              headers {
                key
                value
              }
              method
              type
            }
          }
        }
        changeDetails {
          updatedAt
          createdAt
          updatedBy
          createdBy
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetNotificationGroupsQuery__
 *
 * To run a query within a React component, call `useGetNotificationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationGroupsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetNotificationGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationGroupsQuery,
    GetNotificationGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationGroupsQuery,
    GetNotificationGroupsQueryVariables
  >(GetNotificationGroupsDocument, options);
}
export function useGetNotificationGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationGroupsQuery,
    GetNotificationGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationGroupsQuery,
    GetNotificationGroupsQueryVariables
  >(GetNotificationGroupsDocument, options);
}
export type GetNotificationGroupsQueryHookResult = ReturnType<
  typeof useGetNotificationGroupsQuery
>;
export type GetNotificationGroupsLazyQueryHookResult = ReturnType<
  typeof useGetNotificationGroupsLazyQuery
>;
export type GetNotificationGroupsQueryResult = Apollo.QueryResult<
  GetNotificationGroupsQuery,
  GetNotificationGroupsQueryVariables
>;
export const UpdateNotificationGroupDocument = gql`
  mutation updateNotificationGroup($input: UpdateNotificationGroupInput!) {
    updateNotificationGroup(input: $input) {
      id
      name
      description
      channels {
        id
        typeProperties {
          ... on EmailChannel {
            email
          }
          ... on WebhookChannel {
            url
            payload
            headers {
              key
              value
            }
            method
            type
          }
        }
      }
      changeDetails {
        updatedAt
        createdAt
        updatedBy
        createdBy
      }
    }
  }
`;
export type UpdateNotificationGroupMutationFn = Apollo.MutationFunction<
  UpdateNotificationGroupMutation,
  UpdateNotificationGroupMutationVariables
>;

/**
 * __useUpdateNotificationGroupMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationGroupMutation, { data, loading, error }] = useUpdateNotificationGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationGroupMutation,
    UpdateNotificationGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNotificationGroupMutation,
    UpdateNotificationGroupMutationVariables
  >(UpdateNotificationGroupDocument, options);
}
export type UpdateNotificationGroupMutationHookResult = ReturnType<
  typeof useUpdateNotificationGroupMutation
>;
export type UpdateNotificationGroupMutationResult =
  Apollo.MutationResult<UpdateNotificationGroupMutation>;
export type UpdateNotificationGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationGroupMutation,
  UpdateNotificationGroupMutationVariables
>;
export const CreateApiKeyDocument = gql`
  mutation createApiKey($input: CreateApiKeyInput!) {
    createApiKey(input: $input) {
      apiKey {
        id
        name
        owner
        description
        changeDetails {
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
        lastUsedAt
        expiresAt
      }
      apiKeySecret
    }
  }
`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApiKeyMutation,
    CreateApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApiKeyMutation,
    CreateApiKeyMutationVariables
  >(CreateApiKeyDocument, options);
}
export type CreateApiKeyMutationHookResult = ReturnType<
  typeof useCreateApiKeyMutation
>;
export type CreateApiKeyMutationResult =
  Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  CreateApiKeyMutation,
  CreateApiKeyMutationVariables
>;
export const DeleteApiKeyDocument = gql`
  mutation deleteApiKey($apiKeyId: UUID!) {
    removeApiKey(id: $apiKeyId)
  }
`;
export type DeleteApiKeyMutationFn = Apollo.MutationFunction<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      apiKeyId: // value for 'apiKeyId'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteApiKeyMutation,
    DeleteApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteApiKeyMutation,
    DeleteApiKeyMutationVariables
  >(DeleteApiKeyDocument, options);
}
export type DeleteApiKeyMutationHookResult = ReturnType<
  typeof useDeleteApiKeyMutation
>;
export type DeleteApiKeyMutationResult =
  Apollo.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables
>;
export const GetApiKeysDocument = gql`
  query getApiKeys($page: Int, $limit: Int, $sort: ApiKeysSortInput) {
    apiKeys(page: $page, limit: $limit, sort: $sort) {
      result {
        id
        name
        owner
        description
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        lastUsedAt
        expiresAt
        roleBindings(page: 1, limit: 100) {
          pageInfo {
            pages
            count
          }
          result {
            id
            role {
              id
              name
            }
          }
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetApiKeysQuery__
 *
 * To run a query within a React component, call `useGetApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeysQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetApiKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApiKeysQuery,
    GetApiKeysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(
    GetApiKeysDocument,
    options
  );
}
export function useGetApiKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiKeysQuery,
    GetApiKeysQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(
    GetApiKeysDocument,
    options
  );
}
export type GetApiKeysQueryHookResult = ReturnType<typeof useGetApiKeysQuery>;
export type GetApiKeysLazyQueryHookResult = ReturnType<
  typeof useGetApiKeysLazyQuery
>;
export type GetApiKeysQueryResult = Apollo.QueryResult<
  GetApiKeysQuery,
  GetApiKeysQueryVariables
>;
export const UpdateApiKeyDocument = gql`
  mutation updateApiKey($input: UpdateApiKeyInput!) {
    updateApiKey(input: $input) {
      id
      name
      description
      expiresAt
      owner
      roleBindings(page: 1, limit: 100) {
        result {
          id
          role {
            id
            name
          }
        }
      }
      changeDetails {
        updatedAt
        updatedBy
      }
    }
  }
`;
export type UpdateApiKeyMutationFn = Apollo.MutationFunction<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApiKeyMutation,
    UpdateApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApiKeyMutation,
    UpdateApiKeyMutationVariables
  >(UpdateApiKeyDocument, options);
}
export type UpdateApiKeyMutationHookResult = ReturnType<
  typeof useUpdateApiKeyMutation
>;
export type UpdateApiKeyMutationResult =
  Apollo.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = Apollo.BaseMutationOptions<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;
export const GetRoleDocument = gql`
  query getRole($roleId: UUID!) {
    role(id: $roleId) {
      id
      name
      description
      rules {
        resources
        actions
      }
    }
  }
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useGetRoleQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    options
  );
}
export function useGetRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    options
  );
}
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<
  GetRoleQuery,
  GetRoleQueryVariables
>;
export const UpdateRoleDocument = gql`
  mutation updateRole($input: RoleUpdateInput!) {
    updateRole(input: $input) {
      id
      name
      description
      rules {
        resources
        actions
      }
      changeDetails {
        updatedAt
        updatedBy
      }
    }
  }
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    options
  );
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
  Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const CreateRoleDocument = gql`
  mutation createRole($input: RoleInput!) {
    createRole(input: $input) {
      id
    }
  }
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
    CreateRoleDocument,
    options
  );
}
export type CreateRoleMutationHookResult = ReturnType<
  typeof useCreateRoleMutation
>;
export type CreateRoleMutationResult =
  Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const DeleteRoleDocument = gql`
  mutation deleteRole($roleId: UUID!) {
    removeRole(id: $roleId)
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(
    DeleteRoleDocument,
    options
  );
}
export type DeleteRoleMutationHookResult = ReturnType<
  typeof useDeleteRoleMutation
>;
export type DeleteRoleMutationResult =
  Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;
export const GetRolesDocument = gql`
  query getRoles($page: Int, $limit: Int, $sort: RolesSortInput) {
    roles(page: $page, limit: $limit, sort: $sort) {
      result {
        id
        name
        description
        rules {
          resources
          actions
        }
        changeDetails {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options
  );
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRolesQuery,
    GetRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options
  );
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<
  typeof useGetRolesLazyQuery
>;
export type GetRolesQueryResult = Apollo.QueryResult<
  GetRolesQuery,
  GetRolesQueryVariables
>;
export const GetRoleBindingsByUsersDocument = gql`
  query getRoleBindingsByUsers($limit: Int!, $page: Int!) {
    roleBindingsByUsers(page: $page, limit: $limit) {
      result {
        customerId
        type
        subject
        roles {
          id
          name
          description
          rules {
            resources
            actions
          }
          changeDetails {
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetRoleBindingsByUsersQuery__
 *
 * To run a query within a React component, call `useGetRoleBindingsByUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleBindingsByUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleBindingsByUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetRoleBindingsByUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoleBindingsByUsersQuery,
    GetRoleBindingsByUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoleBindingsByUsersQuery,
    GetRoleBindingsByUsersQueryVariables
  >(GetRoleBindingsByUsersDocument, options);
}
export function useGetRoleBindingsByUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoleBindingsByUsersQuery,
    GetRoleBindingsByUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoleBindingsByUsersQuery,
    GetRoleBindingsByUsersQueryVariables
  >(GetRoleBindingsByUsersDocument, options);
}
export type GetRoleBindingsByUsersQueryHookResult = ReturnType<
  typeof useGetRoleBindingsByUsersQuery
>;
export type GetRoleBindingsByUsersLazyQueryHookResult = ReturnType<
  typeof useGetRoleBindingsByUsersLazyQuery
>;
export type GetRoleBindingsByUsersQueryResult = Apollo.QueryResult<
  GetRoleBindingsByUsersQuery,
  GetRoleBindingsByUsersQueryVariables
>;
export const CreateRoleBindingDocument = gql`
  mutation createRoleBinding($input: CreateRoleBindingInput!) {
    createRoleBinding(input: $input) {
      id
      type
      subject
      role {
        id
        name
        description
      }
    }
  }
`;
export type CreateRoleBindingMutationFn = Apollo.MutationFunction<
  CreateRoleBindingMutation,
  CreateRoleBindingMutationVariables
>;

/**
 * __useCreateRoleBindingMutation__
 *
 * To run a mutation, you first call `useCreateRoleBindingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleBindingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleBindingMutation, { data, loading, error }] = useCreateRoleBindingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleBindingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleBindingMutation,
    CreateRoleBindingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRoleBindingMutation,
    CreateRoleBindingMutationVariables
  >(CreateRoleBindingDocument, options);
}
export type CreateRoleBindingMutationHookResult = ReturnType<
  typeof useCreateRoleBindingMutation
>;
export type CreateRoleBindingMutationResult =
  Apollo.MutationResult<CreateRoleBindingMutation>;
export type CreateRoleBindingMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleBindingMutation,
  CreateRoleBindingMutationVariables
>;
export const DeleteRoleBindingDocument = gql`
  mutation deleteRoleBinding($id: ID!) {
    removeRoleBinding(id: $id)
  }
`;
export type DeleteRoleBindingMutationFn = Apollo.MutationFunction<
  DeleteRoleBindingMutation,
  DeleteRoleBindingMutationVariables
>;

/**
 * __useDeleteRoleBindingMutation__
 *
 * To run a mutation, you first call `useDeleteRoleBindingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleBindingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleBindingMutation, { data, loading, error }] = useDeleteRoleBindingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleBindingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleBindingMutation,
    DeleteRoleBindingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRoleBindingMutation,
    DeleteRoleBindingMutationVariables
  >(DeleteRoleBindingDocument, options);
}
export type DeleteRoleBindingMutationHookResult = ReturnType<
  typeof useDeleteRoleBindingMutation
>;
export type DeleteRoleBindingMutationResult =
  Apollo.MutationResult<DeleteRoleBindingMutation>;
export type DeleteRoleBindingMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleBindingMutation,
  DeleteRoleBindingMutationVariables
>;
export const CreatePlanDocument = gql`
  mutation createPlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      id
    }
  }
`;
export type CreatePlanMutationFn = Apollo.MutationFunction<
  CreatePlanMutation,
  CreatePlanMutationVariables
>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanMutation,
    CreatePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(
    CreatePlanDocument,
    options
  );
}
export type CreatePlanMutationHookResult = ReturnType<
  typeof useCreatePlanMutation
>;
export type CreatePlanMutationResult =
  Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanMutation,
  CreatePlanMutationVariables
>;
export const DeletePlanDocument = gql`
  mutation deletePlan($id: UUID!) {
    removePlan(id: $id)
  }
`;
export type DeletePlanMutationFn = Apollo.MutationFunction<
  DeletePlanMutation,
  DeletePlanMutationVariables
>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePlanMutation,
    DeletePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(
    DeletePlanDocument,
    options
  );
}
export type DeletePlanMutationHookResult = ReturnType<
  typeof useDeletePlanMutation
>;
export type DeletePlanMutationResult =
  Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<
  DeletePlanMutation,
  DeletePlanMutationVariables
>;
export const GenerateDatesFromCronDocument = gql`
  mutation generateDatesFromCron($input: GenerateDatesFromCronInput!) {
    generateDatesFromCron(input: $input) {
      dates
      validationMsg
      error
    }
  }
`;
export type GenerateDatesFromCronMutationFn = Apollo.MutationFunction<
  GenerateDatesFromCronMutation,
  GenerateDatesFromCronMutationVariables
>;

/**
 * __useGenerateDatesFromCronMutation__
 *
 * To run a mutation, you first call `useGenerateDatesFromCronMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDatesFromCronMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDatesFromCronMutation, { data, loading, error }] = useGenerateDatesFromCronMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateDatesFromCronMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateDatesFromCronMutation,
    GenerateDatesFromCronMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateDatesFromCronMutation,
    GenerateDatesFromCronMutationVariables
  >(GenerateDatesFromCronDocument, options);
}
export type GenerateDatesFromCronMutationHookResult = ReturnType<
  typeof useGenerateDatesFromCronMutation
>;
export type GenerateDatesFromCronMutationResult =
  Apollo.MutationResult<GenerateDatesFromCronMutation>;
export type GenerateDatesFromCronMutationOptions = Apollo.BaseMutationOptions<
  GenerateDatesFromCronMutation,
  GenerateDatesFromCronMutationVariables
>;
export const GetPlanDocument = gql`
  query getPlan($planId: UUID!) {
    plan(id: $planId) {
      id
      name
      description
      enabled
      scheduleCron
      scheduleOffset
      scheduleTimezone
      scheduleExecutionDates
      changeDetails {
        updatedAt
        updatedBy
        createdAt
        createdBy
      }
      metadata {
        key
        value
      }
      planActions {
        id
        order
        name
        windowDuration
        skipWindow
        executionPolicy
        resourceGroups {
          id
          name
          description
          totalNumberOfResources
          changeDetails {
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        inputParameters {
          key
          value
        }
        runInSequence
        action {
          ... on Action {
            id
            name
            description
            color
            type
            scope
            synchronous
            typeProperties {
              __typename
              ... on ActionPropertyAWSLambda {
                lambdaArn
              }
              ... on ActionPropertyAzureFunction {
                accessKeyId
                functionMethod
                functionUrl
              }
              ... on ActionPropertyScript {
                s3FileUrl
              }
              ... on ActionPropertySSMDocument {
                documentId
              }
              ... on ActionPropertyWebhook {
                webhookUrl
                webhookMethod
                payload
                headers {
                  key
                  value
                }
              }
            }
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            parameterDefinitions {
              name
              required
              type
            }
          }
          ... on SystemAction {
            id
            name
            scope
            color
            scope
            version
            scope
            parameterDefinitions {
              name
              required
              description
              allowedValues
              defaultValue
              type
            }
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        notificationGroups {
          id
          notificationGroup {
            id
            name
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          triggerEvent
          inAdvance
          scope
        }
      }
      notificationGroups {
        id
        triggerEvent
        inAdvance
        notificationGroup {
          id
          name
          description
          changeDetails {
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      }
    }
  }
`;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useGetPlanQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(
    GetPlanDocument,
    options
  );
}
export function useGetPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(
    GetPlanDocument,
    options
  );
}
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<
  GetPlanQuery,
  GetPlanQueryVariables
>;
export const GetPlansDocument = gql`
  query getPlans($page: Int, $limit: Int, $sort: PlanSortInput) {
    plans(page: $page, limit: $limit, sort: $sort) {
      result {
        id
        name
        description
        enabled
        scheduleCron
        scheduleTimezone
        scheduleExecutionDates
        scheduleOffset
        changeDetails {
          updatedAt
          updatedBy
          createdAt
          createdBy
        }
        metadata {
          key
          value
        }
        planActions {
          id
          action {
            ... on Action {
              id
            }
            ... on SystemAction {
              id
            }
          }
        }
        notificationGroups {
          notificationGroup {
            id
            name
            description
          }
        }
        nextExecutionDates
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(
    GetPlansDocument,
    options
  );
}
export function useGetPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlansQuery,
    GetPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(
    GetPlansDocument,
    options
  );
}
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<
  typeof useGetPlansLazyQuery
>;
export type GetPlansQueryResult = Apollo.QueryResult<
  GetPlansQuery,
  GetPlansQueryVariables
>;
export const GetSystemActionsDocument = gql`
  query getSystemActions(
    $page: Int
    $limit: Int
    $sort: SystemActionsSortInput
  ) {
    systemActions(page: $page, limit: $limit, sort: $sort) {
      result {
        id
        name
        color
        description
        version
        scope
        parameterDefinitions {
          name
          required
          description
          allowedValues
          defaultValue
          type
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetSystemActionsQuery__
 *
 * To run a query within a React component, call `useGetSystemActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemActionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetSystemActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSystemActionsQuery,
    GetSystemActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSystemActionsQuery, GetSystemActionsQueryVariables>(
    GetSystemActionsDocument,
    options
  );
}
export function useGetSystemActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSystemActionsQuery,
    GetSystemActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSystemActionsQuery,
    GetSystemActionsQueryVariables
  >(GetSystemActionsDocument, options);
}
export type GetSystemActionsQueryHookResult = ReturnType<
  typeof useGetSystemActionsQuery
>;
export type GetSystemActionsLazyQueryHookResult = ReturnType<
  typeof useGetSystemActionsLazyQuery
>;
export type GetSystemActionsQueryResult = Apollo.QueryResult<
  GetSystemActionsQuery,
  GetSystemActionsQueryVariables
>;
export const TriggerPlanDocument = gql`
  mutation triggerPlan($id: UUID!) {
    triggerPlan(id: $id) {
      id
      plan {
        id
      }
    }
  }
`;
export type TriggerPlanMutationFn = Apollo.MutationFunction<
  TriggerPlanMutation,
  TriggerPlanMutationVariables
>;

/**
 * __useTriggerPlanMutation__
 *
 * To run a mutation, you first call `useTriggerPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerPlanMutation, { data, loading, error }] = useTriggerPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTriggerPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerPlanMutation,
    TriggerPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TriggerPlanMutation, TriggerPlanMutationVariables>(
    TriggerPlanDocument,
    options
  );
}
export type TriggerPlanMutationHookResult = ReturnType<
  typeof useTriggerPlanMutation
>;
export type TriggerPlanMutationResult =
  Apollo.MutationResult<TriggerPlanMutation>;
export type TriggerPlanMutationOptions = Apollo.BaseMutationOptions<
  TriggerPlanMutation,
  TriggerPlanMutationVariables
>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      id
      name
      description
      enabled
      scheduleCron
      scheduleOffset
      scheduleTimezone
      scheduleExecutionDates
      metadata {
        key
        value
      }
      planActions {
        id
        order
        windowDuration
        skipWindow
        executionPolicy
        resourceGroups {
          id
          name
          description
          totalNumberOfResources
          changeDetails {
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        inputParameters {
          key
          value
        }
        runInSequence
        action {
          ... on Action {
            id
            name
            description
            color
            type
            scope
            synchronous
            typeProperties {
              __typename
              ... on ActionPropertyAWSLambda {
                lambdaArn
              }
              ... on ActionPropertyAzureFunction {
                accessKeyId
                functionMethod
                functionUrl
              }
              ... on ActionPropertyScript {
                s3FileUrl
              }
              ... on ActionPropertySSMDocument {
                documentId
              }
              ... on ActionPropertyWebhook {
                webhookUrl
                webhookMethod
                payload
                headers {
                  key
                  value
                }
              }
            }
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            parameterDefinitions {
              name
              required
              type
            }
          }
          ... on SystemAction {
            id
            name
            scope
            color
            scope
            version
            scope
            parameterDefinitions {
              name
              required
              description
              allowedValues
              defaultValue
              type
            }
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        notificationGroups {
          id
          notificationGroup {
            id
            name
            changeDetails {
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          triggerEvent
          scope
        }
      }
      notificationGroups {
        id
        triggerEvent
        inAdvance
        notificationGroup {
          id
          name
        }
      }
      changeDetails {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<
  UpdatePlanMutation,
  UpdatePlanMutationVariables
>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanMutation,
    UpdatePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(
    UpdatePlanDocument,
    options
  );
}
export type UpdatePlanMutationHookResult = ReturnType<
  typeof useUpdatePlanMutation
>;
export type UpdatePlanMutationResult =
  Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanMutation,
  UpdatePlanMutationVariables
>;
export const CreateGroupDocument = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      name
    }
  }
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation deleteGroup($id: UUID!) {
    removeGroup(id: $id)
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    options
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
  Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const GetGroupPlansDocument = gql`
  query getGroupPlans($resourceGroupId: UUID!) {
    group(id: $resourceGroupId) {
      plans {
        id
        name
      }
    }
  }
`;

/**
 * __useGetGroupPlansQuery__
 *
 * To run a query within a React component, call `useGetGroupPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupPlansQuery({
 *   variables: {
 *      resourceGroupId: // value for 'resourceGroupId'
 *   },
 * });
 */
export function useGetGroupPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupPlansQuery,
    GetGroupPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGroupPlansQuery, GetGroupPlansQueryVariables>(
    GetGroupPlansDocument,
    options
  );
}
export function useGetGroupPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupPlansQuery,
    GetGroupPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGroupPlansQuery, GetGroupPlansQueryVariables>(
    GetGroupPlansDocument,
    options
  );
}
export type GetGroupPlansQueryHookResult = ReturnType<
  typeof useGetGroupPlansQuery
>;
export type GetGroupPlansLazyQueryHookResult = ReturnType<
  typeof useGetGroupPlansLazyQuery
>;
export type GetGroupPlansQueryResult = Apollo.QueryResult<
  GetGroupPlansQuery,
  GetGroupPlansQueryVariables
>;
export const GetResourceGroupDocument = gql`
  query getResourceGroup($resourceGroupId: UUID!) {
    group(id: $resourceGroupId) {
      id
      name
      description
      metadata {
        key
        value
      }
      resourceSelectors {
        id
        resource {
          id
          name
          description
          os {
            type
            subType
          }
          autoDiscovery
          fullCloudResourceId
          cloudResourceId
          enabled
          provider
          providerId
          region
          changeDetails {
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          scanned
        }
        tagsExpression
        order
        dynamicResources {
          name
          id
          provider
          os {
            type
            subType
          }
          autoDiscovery
          cloudResourceId
          fullCloudResourceId
          enabled
          provider
          providerId
          region
          changeDetails {
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          scanned
        }
      }
      changeDetails {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

/**
 * __useGetResourceGroupQuery__
 *
 * To run a query within a React component, call `useGetResourceGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceGroupQuery({
 *   variables: {
 *      resourceGroupId: // value for 'resourceGroupId'
 *   },
 * });
 */
export function useGetResourceGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetResourceGroupQuery,
    GetResourceGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResourceGroupQuery, GetResourceGroupQueryVariables>(
    GetResourceGroupDocument,
    options
  );
}
export function useGetResourceGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResourceGroupQuery,
    GetResourceGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetResourceGroupQuery,
    GetResourceGroupQueryVariables
  >(GetResourceGroupDocument, options);
}
export type GetResourceGroupQueryHookResult = ReturnType<
  typeof useGetResourceGroupQuery
>;
export type GetResourceGroupLazyQueryHookResult = ReturnType<
  typeof useGetResourceGroupLazyQuery
>;
export type GetResourceGroupQueryResult = Apollo.QueryResult<
  GetResourceGroupQuery,
  GetResourceGroupQueryVariables
>;
export const GetResourceGroupsDocument = gql`
  query getResourceGroups($page: Int, $limit: Int, $sort: GroupsSortInput) {
    groups(page: $page, limit: $limit, sort: $sort) {
      result {
        id
        name
        resourceSelectors {
          id
          resource {
            id
            name
          }
          tagsExpression
          order
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetResourceGroupsQuery__
 *
 * To run a query within a React component, call `useGetResourceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceGroupsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetResourceGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetResourceGroupsQuery,
    GetResourceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetResourceGroupsQuery,
    GetResourceGroupsQueryVariables
  >(GetResourceGroupsDocument, options);
}
export function useGetResourceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResourceGroupsQuery,
    GetResourceGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetResourceGroupsQuery,
    GetResourceGroupsQueryVariables
  >(GetResourceGroupsDocument, options);
}
export type GetResourceGroupsQueryHookResult = ReturnType<
  typeof useGetResourceGroupsQuery
>;
export type GetResourceGroupsLazyQueryHookResult = ReturnType<
  typeof useGetResourceGroupsLazyQuery
>;
export type GetResourceGroupsQueryResult = Apollo.QueryResult<
  GetResourceGroupsQuery,
  GetResourceGroupsQueryVariables
>;
export const UpdateGroupDocument = gql`
  mutation updateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      name
      description
      metadata {
        key
        value
      }
      resourceSelectors {
        id
        resource {
          id
          name
          description
          os {
            type
            subType
          }
        }
        tagsExpression
        order
        dynamicResources {
          name
          id
          provider
          os {
            type
            subType
          }
        }
      }
      changeDetails {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const DeleteResourceDocument = gql`
  mutation deleteResource($removeResourceId: UUID!) {
    removeResource(id: $removeResourceId)
  }
`;
export type DeleteResourceMutationFn = Apollo.MutationFunction<
  DeleteResourceMutation,
  DeleteResourceMutationVariables
>;

/**
 * __useDeleteResourceMutation__
 *
 * To run a mutation, you first call `useDeleteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceMutation, { data, loading, error }] = useDeleteResourceMutation({
 *   variables: {
 *      removeResourceId: // value for 'removeResourceId'
 *   },
 * });
 */
export function useDeleteResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResourceMutation,
    DeleteResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteResourceMutation,
    DeleteResourceMutationVariables
  >(DeleteResourceDocument, options);
}
export type DeleteResourceMutationHookResult = ReturnType<
  typeof useDeleteResourceMutation
>;
export type DeleteResourceMutationResult =
  Apollo.MutationResult<DeleteResourceMutation>;
export type DeleteResourceMutationOptions = Apollo.BaseMutationOptions<
  DeleteResourceMutation,
  DeleteResourceMutationVariables
>;
export const GetResourceDocument = gql`
  query getResource($resourceId: UUID!) {
    resource(id: $resourceId) {
      id
      name
      provider
      providerId
      description
      cloudResourceId
      fullCloudResourceId
      autoDiscovery
      enabled
      region
      scanned
      tags {
        key
        value
      }
      metadata {
        key
        value
      }
      cloudMetadata {
        key
        value
      }
      os {
        type
        subType
        version
      }
      access {
        ... on SSMAccess {
          instanceId
        }
      }
      state {
        status
        error
        updatedAt
        ssmMetadata {
          key
          value
        }
      }
      latestInventory {
        status
        error
        inventoryUri
        availablePackagesUri
        createdAt
      }
      changeDetails {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

/**
 * __useGetResourceQuery__
 *
 * To run a query within a React component, call `useGetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useGetResourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetResourceQuery,
    GetResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResourceQuery, GetResourceQueryVariables>(
    GetResourceDocument,
    options
  );
}
export function useGetResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResourceQuery,
    GetResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetResourceQuery, GetResourceQueryVariables>(
    GetResourceDocument,
    options
  );
}
export type GetResourceQueryHookResult = ReturnType<typeof useGetResourceQuery>;
export type GetResourceLazyQueryHookResult = ReturnType<
  typeof useGetResourceLazyQuery
>;
export type GetResourceQueryResult = Apollo.QueryResult<
  GetResourceQuery,
  GetResourceQueryVariables
>;
export const GetResourcesDocument = gql`
  query getResources(
    $limit: Int!
    $page: Int!
    $sort: ResourcesSortInput
    $filter: ResourceFilterInput
  ) {
    resources(page: $page, limit: $limit, sort: $sort, filter: $filter) {
      result {
        ...Resource
      }
      pageInfo {
        pages
        count
      }
    }
  }
  ${ResourceFragmentDoc}
`;

/**
 * __useGetResourcesQuery__
 *
 * To run a query within a React component, call `useGetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetResourcesQuery,
    GetResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetResourcesQuery, GetResourcesQueryVariables>(
    GetResourcesDocument,
    options
  );
}
export function useGetResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResourcesQuery,
    GetResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetResourcesQuery, GetResourcesQueryVariables>(
    GetResourcesDocument,
    options
  );
}
export type GetResourcesQueryHookResult = ReturnType<
  typeof useGetResourcesQuery
>;
export type GetResourcesLazyQueryHookResult = ReturnType<
  typeof useGetResourcesLazyQuery
>;
export type GetResourcesQueryResult = Apollo.QueryResult<
  GetResourcesQuery,
  GetResourcesQueryVariables
>;
export const GetResourcesByTagsDocument = gql`
  query getResourcesByTags($limit: Int, $page: Int, $tagsExpression: String!) {
    resourcesByTags(
      page: $page
      limit: $limit
      tagsExpression: $tagsExpression
    ) {
      result {
        name
        id
        provider
        os {
          type
          subType
          version
        }
        state {
          status
          ssmMetadata {
            key
            value
          }
        }
      }
      pageInfo {
        pages
        count
      }
    }
  }
`;

/**
 * __useGetResourcesByTagsQuery__
 *
 * To run a query within a React component, call `useGetResourcesByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesByTagsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      tagsExpression: // value for 'tagsExpression'
 *   },
 * });
 */
export function useGetResourcesByTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetResourcesByTagsQuery,
    GetResourcesByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetResourcesByTagsQuery,
    GetResourcesByTagsQueryVariables
  >(GetResourcesByTagsDocument, options);
}
export function useGetResourcesByTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetResourcesByTagsQuery,
    GetResourcesByTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetResourcesByTagsQuery,
    GetResourcesByTagsQueryVariables
  >(GetResourcesByTagsDocument, options);
}
export type GetResourcesByTagsQueryHookResult = ReturnType<
  typeof useGetResourcesByTagsQuery
>;
export type GetResourcesByTagsLazyQueryHookResult = ReturnType<
  typeof useGetResourcesByTagsLazyQuery
>;
export type GetResourcesByTagsQueryResult = Apollo.QueryResult<
  GetResourcesByTagsQuery,
  GetResourcesByTagsQueryVariables
>;
export const ResourcesSearchDocument = gql`
  query resourcesSearch(
    $input: ResourcesSearchInput!
    $page: Int
    $limit: Int
    $sort: ResourcesSortInput
  ) {
    resourcesSearch(input: $input, page: $page, limit: $limit, sort: $sort) {
      ...ResourcePage
    }
  }
  ${ResourcePageFragmentDoc}
`;

/**
 * __useResourcesSearchQuery__
 *
 * To run a query within a React component, call `useResourcesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useResourcesSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourcesSearchQuery,
    ResourcesSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourcesSearchQuery, ResourcesSearchQueryVariables>(
    ResourcesSearchDocument,
    options
  );
}
export function useResourcesSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourcesSearchQuery,
    ResourcesSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourcesSearchQuery,
    ResourcesSearchQueryVariables
  >(ResourcesSearchDocument, options);
}
export type ResourcesSearchQueryHookResult = ReturnType<
  typeof useResourcesSearchQuery
>;
export type ResourcesSearchLazyQueryHookResult = ReturnType<
  typeof useResourcesSearchLazyQuery
>;
export type ResourcesSearchQueryResult = Apollo.QueryResult<
  ResourcesSearchQuery,
  ResourcesSearchQueryVariables
>;
export const UpdateResourceDocument = gql`
  mutation updateResource($input: UpdateResourceInput!) {
    updateResource(input: $input) {
      id
      name
      description
      changeDetails {
        updatedAt
        updatedBy
      }
    }
  }
`;
export type UpdateResourceMutationFn = Apollo.MutationFunction<
  UpdateResourceMutation,
  UpdateResourceMutationVariables
>;

/**
 * __useUpdateResourceMutation__
 *
 * To run a mutation, you first call `useUpdateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceMutation, { data, loading, error }] = useUpdateResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResourceMutation,
    UpdateResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResourceMutation,
    UpdateResourceMutationVariables
  >(UpdateResourceDocument, options);
}
export type UpdateResourceMutationHookResult = ReturnType<
  typeof useUpdateResourceMutation
>;
export type UpdateResourceMutationResult =
  Apollo.MutationResult<UpdateResourceMutation>;
export type UpdateResourceMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourceMutation,
  UpdateResourceMutationVariables
>;
