/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import { Message, Text, theme } from "@nordcloud/gnui";
import { EventStatus } from "~/generated/graphql";
import { useEvent } from "../../EventsDetails/EventProvider";

export function RevokeIncomingEventMessage() {
  const { event } = useEvent();

  const isMessageVisible = event?.status === EventStatus.Revoked;

  return (
    <When condition={isMessageVisible}>
      <Message
        color={theme.color.support.orange}
        borderColor={theme.color.support.orange}
        image="warning"
        css={{
          marginBottom: theme.spacing.spacing02,
        }}
      >
        <div>
          <Text
            mb={theme.spacing.spacing01}
            weight="medium"
            color={theme.color.support.orange}
          >
            Event revoked
          </Text>
          <Text color={theme.color.text.text02}>
            This event has been Revoked by user.
          </Text>
        </div>
      </Message>
    </When>
  );
}
