/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { When } from "react-if";
import styled from "styled-components";
import { BrickLoader, Text, theme } from "@nordcloud/gnui";
import { convertSkipWindow } from "~/utils/convertSkipWindow";
import { DisplayExecutionPolicy } from "~/views/events/components/DisplayExecutionPolicy";
import { DisplayBooleanProperties } from "~/views/events/components/DisplayResourceOperation";
import { DisplayWindowDuration } from "~/views/events/components/DisplayWindowDuration";
import { convertMinutesToTime } from "~/views/events/helpers";
import { SystemActionNames } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlan } from "~/views/plans/PlanDetails/PlanProvider";

export function GeneralSettings() {
  const { planAction } = usePlan();

  const isDelay = planAction?.action?.name === SystemActionNames.DELAY;

  if (planAction === undefined) {
    return <BrickLoader height="12.6rem" />;
  }

  if (isDelay) {
    return (
      <Wrap>
        <Headline />
        <Text size="sm" tag="div">
          Action Delay
        </Text>
        <Text data-testid="text-action-delay" mb={theme.spacing.spacing01}>
          {convertMinutesToTime(Number(planAction?.windowDuration))}
        </Text>
      </Wrap>
    );
  }

  return (
    <Wrap>
      <Headline />
      <DisplayExecutionPolicy policy={planAction?.executionPolicy} />
      <DisplayWindowDuration windowDuration={planAction?.windowDuration} />
      <DisplayBooleanProperties
        title="Resource Operation"
        condition={planAction?.runInSequence}
        options={["Sequenced", "Simultaneous"]}
      />
      <When condition={planAction?.skipWindow}>
        <Text size="sm" tag="div">
          Ignore Window
        </Text>
        <Text data-testid="text-ignore-window" mb={theme.spacing.spacing01}>
          {convertSkipWindow(planAction?.skipWindow)}
        </Text>
      </When>
    </Wrap>
  );
}

function Headline() {
  return <Text weight="medium">General Action Settings</Text>;
}

const Wrap = styled.div`
  min-height: 13rem;
`;
