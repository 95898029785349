/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Option } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { TimeUnits } from "../../types";

export const buildOptions = (arr?: string[]): Option[] => {
  return (
    arr?.map((i: string) => ({
      label: i ?? "",
      value: i ?? "",
    })) ?? []
  );
};

export function convertStringToTimeUnit(value: string): TimeUnits | undefined {
  return TimeUnits[value as keyof typeof TimeUnits];
}
