/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { Box, FlexContainer, Text, theme } from "@nordcloud/gnui";
import { SelectedAction } from "../../types";
import { useStepState } from "../StepProvider";
import { ActionView } from "./ActionView";
import { AddActionWrapper } from "./AddAction";
import { DraggableActions } from "./DraggableActions";
import { ActionWrap } from "./DraggableActions/ActionWrap";

type Props = {
  nextStep?: () => void;
};

export function ActionsView({ nextStep }: Props) {
  const { stepState } = useStepState();
  const prepareActions = stepState.actions?.map(mapAction);

  const prepareActionBatch = stepState.actionBatch?.map(mapAction);

  const getActionsBasedOnBatchId = aggregateByBatchId(prepareActionBatch ?? []);

  return (
    <Row>
      <Col data-testid="actions-list" xs={4} sm={8} md={2} lg={3}>
        <FlexContainer direction="column" justifyContent="flex-start">
          <Text width="100%" align="left">
            Actions
          </Text>
          {getActionsBasedOnBatchId.map((item, index) => {
            return (
              <ActionWrap key={index} name={item[0].batchName} isBatch>
                <DraggableActions
                  actionBatch
                  actions={item}
                  nextStep={nextStep}
                />
              </ActionWrap>
            );
          })}
          <DraggableActions actions={prepareActions} nextStep={nextStep} />
          <AddActionWrapper severity="low" />
        </FlexContainer>
      </Col>
      <Col data-testid="action-details" xs={4} sm={8} md={6} lg={9}>
        <Box boxStyle="grey" innerSpacing="spacing04">
          <FlexContainer alignItems="stretch" gap={theme.spacing.spacing04}>
            <ActionView {...{ nextStep }} />
          </FlexContainer>
        </Box>
      </Col>
    </Row>
  );
}

const mapAction = (action: SelectedAction) => ({
  ...action,
  id: action.id ?? "",
  listId: action.listId ?? "",
  name: action.name,
  description: "",
  color: action.color ?? "",
  actionType: action.actionType,
});

const aggregateByBatchId = (dataArray: SelectedAction[]) => {
  const batchMap = dataArray.reduce((acc, obj) => {
    const batchId = obj.batchId;
    const updatedBatch = acc.get(batchId) ? [...acc.get(batchId), obj] : [obj];
    return new Map(acc).set(batchId, updatedBatch);
  }, new Map());

  return Array.from(batchMap.values());
};
