/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  FlexContainer,
  SelectButton,
  Spacer,
  Text,
  theme,
} from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { useQueryState } from "~/hooks";
import {
  QueryGetEvents,
  eventTypeOptions,
  monthBegin,
  monthEnd,
  periodOptions,
  todayEnd,
  weekBegin,
  weekEnd,
  yearBegin,
  yearEnd,
} from "./constants";
import { DateRangeSelector } from "./DateRangeSelector";
import { StyledMultiSelect } from "./DateRangeSelector/styles";
import { EventType, Period } from "./types";
import { defaultRangeLabel, getRangeLabel } from "./utils";

type Props = {
  handleDateChange: (
    startTime: string,
    endTime: string,
    period: Period
  ) => void;
  handleEventTypeChange: (eventType: EventType) => void;
};

export function EventsFilters({
  handleDateChange,
  handleEventTypeChange,
}: Props) {
  const [viewPeriod, setViewPeriod] = useState<Period>(Period.week);
  const [eventType, setEventType] = useState<EventType>();
  const [dateLabel, setRangeLabel] = useState(defaultRangeLabel);

  const { queryState, updateQueryState } = useQueryState<QueryGetEvents>();

  useEffect(() => {
    setViewPeriod(queryState.viewPeriod);
    setEventType(queryState.eventsType);
  }, [queryState.eventsType, queryState.viewPeriod]);

  const handleApply = (startDate?: string, endTime?: string) => {
    setRangeLabel(
      getRangeLabel({
        startDate: startDate ?? defaultRange.startDate,
        endDate: endTime ?? defaultRange.endDate,
      })
    );
    handleDateChange(
      dayjs(startDate ?? defaultRange.startDate).format(dateFormat.fullDate),
      dayjs(endTime ?? defaultRange.endDate)
        .endOf("day")
        .format(dateFormat.fullDate),
      Period.range
    );
  };

  const handleSetFilters = (value: Period) => {
    if (value === Period.week) {
      handleDateChange(weekBegin, weekEnd, Period.week);
    }
    if (value === Period.month) {
      handleDateChange(monthBegin, monthEnd, Period.month);
    }
    if (value === Period.year) {
      handleDateChange(yearBegin, yearEnd, Period.year);
    }
  };

  const defaultRange = {
    startDate: weekBegin,
    endDate: weekEnd,
  };

  const handleDateRangeClick = useCallback(() => {
    updateQueryState({
      ...queryState,
      viewPeriod: Period.range,
    });
  }, [queryState, updateQueryState]);

  const handleEventTypeClick = (type: EventType) => {
    handleEventTypeChange(type);
  };

  const handleClear = () => {
    setRangeLabel(defaultRangeLabel);
    handleDateChange(weekBegin, todayEnd, Period.week);
  };

  return (
    <>
      <Box boxStyle="lightGrey" spacing="spacing04">
        <FlexContainer grow={1} alignItems="center">
          <Text tag="span" mr={theme.spacing.spacing04}>
            Show events:
          </Text>
          <StyledMultiSelect size="small">
            {eventTypeOptions.map((type) => {
              return (
                <SelectButton
                  key={type.value}
                  name={type.value}
                  value={type.value}
                  labelText={type.label}
                  isActive={eventType === type.value}
                  onClick={() => handleEventTypeClick(type.value)}
                />
              );
            })}
          </StyledMultiSelect>
          <Spacer width={theme.spacing.spacing08} />
          <Text tag="span" mr={theme.spacing.spacing04}>
            View by:
          </Text>
          <StyledMultiSelect size="small">
            {periodOptions.map((period) => {
              return (
                <SelectButton
                  key={period.value}
                  name={period.value}
                  value={period.value}
                  labelText={period.label}
                  isActive={viewPeriod === period.value}
                  onClick={() => {
                    handleSetFilters(period.value);
                  }}
                />
              );
            })}
          </StyledMultiSelect>
          <Spacer width={theme.spacing.spacing03} />
          <StyledMultiSelect size="small">
            <DateRangeSelector
              isActive={viewPeriod === Period.range}
              period={viewPeriod}
              label={dateLabel}
              onApply={handleApply}
              onClear={handleClear}
              onClick={handleDateRangeClick}
              range={defaultRange}
            />
          </StyledMultiSelect>
        </FlexContainer>
      </Box>
    </>
  );
}
