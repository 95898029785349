/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { RowSelectionState } from "@tanstack/table-core";
import dayjs from "dayjs";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import {
  Box,
  FlexContainer,
  theme,
  ExtendedTooltip,
  Text,
} from "@nordcloud/gnui";
import {
  Event,
  EventActionFragment,
  ExecutionStatus,
  useEventStatusChangedSubscription,
  useGetEventLazyQuery,
} from "~/generated/graphql";
import { TooltipText } from "~/components/Styled/TooltipText";
import { TableWrapper } from "~/components/Tables";
import { NameWithLink } from "~/components/Tables/cells/NameWithLink";
import { dateFormat } from "~/constants";
import { useToggle } from "~/hooks";
import { ROUTES } from "~/routing/routes";
import { isNotEmpty } from "~/tools";
import { RefetchOptions, convertMinutesToTime } from "~/views/events/helpers";
import { GridColumns } from "../styles";
import { ApproveEvent } from "./ApproveEvent/ApproveEvent";
import { eventActionsColumns } from "./EventActionsColumns";
import { ExpandIcon } from "./styles";
import { TooltipIconStatus } from "./TooltipIconStatus";

type Props = {
  event: Event;
};

export function EventItem({ event }: Props) {
  const [getData, { data }] = useGetEventLazyQuery({
    variables: { id: event.id },
    fetchPolicy: "network-only",
  });

  const eventUpdated = data?.event ?? event;

  const [isExpanded, toggleIsExpanded] = useToggle();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data: eventStatusData } = useEventStatusChangedSubscription({
    variables: {
      input: {
        id: event.id,
      },
    },
    onData: getData,
  });

  const endTimeTZ = dayjs
    .tz(event?.estimatedEndTime ?? "", event?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHour);

  const startTimeTZ = dayjs
    .tz(event?.startTime ?? "", event?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHour);

  const duration = dayjs(event?.estimatedEndTime).diff(
    event?.startTime,
    "minutes"
  );

  const approveActions = eventUpdated?.actions?.filter(
    (eventAction) =>
      eventAction.globalState?.status === ExecutionStatus.ApprovalRequired
  );
  return (
    <Box spacing="spacing02" boxStyle="lightGrey" data-testid="event-row">
      <Row data-testid={event?.name}>
        <Col>
          <GridColumns>
            <FlexContainer justifyContent="center" alignItems="center">
              <When
                condition={isNotEmpty(
                  event?.plan?.planActions ?? eventUpdated?.actions ?? []
                )}
              >
                <ExpandIcon
                  name="chevronDown"
                  title="chevronDown"
                  isExpanded={isExpanded}
                  onClick={toggleIsExpanded}
                />
              </When>
            </FlexContainer>
            <TooltipIconStatus
              status={
                eventStatusData?.eventStatusChanged ?? eventUpdated?.status
              }
              actionLength={approveActions?.length}
              type="Event"
              actions={eventUpdated?.actions ?? []}
            />
            <NameWithLink
              name={event?.name ?? ""}
              link={ROUTES.events.generateDetails(event?.id ?? "")}
            />
            <FlexContainer alignItems="center">
              <ExtendedTooltip
                caption={
                  <>
                    Plan Time: {startTimeTZ}
                    <br />
                    Timezone: {event?.scheduleTimezone}
                  </>
                }
              >
                <TooltipText
                  size="md"
                  tag="span"
                  mr={theme.spacing.spacing01}
                  weight="regular"
                  color={theme.color.text.text01}
                >
                  {dayjs(event?.startTime).format(dateFormat.dayMonthYearHour)}
                </TooltipText>
              </ExtendedTooltip>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <ExtendedTooltip
                caption={
                  <>
                    Plan Time: {endTimeTZ}
                    <br />
                    Timezone: {event?.scheduleTimezone}
                  </>
                }
              >
                <TooltipText
                  size="md"
                  tag="span"
                  mr={theme.spacing.spacing01}
                  weight="regular"
                  color={theme.color.text.text01}
                >
                  {dayjs(event?.estimatedEndTime).format(
                    dateFormat.dayMonthYearHour
                  )}
                </TooltipText>
              </ExtendedTooltip>
            </FlexContainer>
            <FlexContainer alignItems="center">
              <Text
                size="md"
                tag="span"
                mr={theme.spacing.spacing01}
                weight="regular"
                color={theme.color.text.text01}
              >
                {convertMinutesToTime(duration)}
              </Text>
            </FlexContainer>
            <If condition={isNotEmpty(approveActions ?? [])}>
              <Then>
                <FlexContainer>
                  <ApproveEvent
                    eventId={event?.id}
                    hasIcon
                    actions={approveActions}
                    refetchQueries={RefetchOptions.EVENTS}
                  />
                </FlexContainer>
              </Then>
              <Else>
                <div />
              </Else>
            </If>
          </GridColumns>
          <When condition={isExpanded}>
            <TableWrapper<EventActionFragment>
              loading={false}
              data={eventUpdated?.actions ?? []}
              columns={eventActionsColumns({ refreshEvent: getData })}
              rowSelection={rowSelection}
              selectedRowsHandler={() => null}
              setRowSelection={setRowSelection}
              stripped
            />
          </When>
        </Col>
      </Row>
    </Box>
  );
}
