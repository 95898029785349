/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import {
  EventActionFragment,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { dateFormat } from "~/constants";
import { isNotEmpty, isNotNil } from "~/tools";
import { DateRange } from "./types";

export const defaultRangeLabel = "Date Range";

export function getRangeLabel(
  range?: DateRange,
  startDate?: string,
  endDate?: string
) {
  if (isNotNil(startDate) && isNotNil(endDate)) {
    return `${dayjs(startDate).format(dateFormat.shortDate)} - ${dayjs(
      endDate
    ).format(dateFormat.shortDate)}`;
  }

  if (range?.startDate) {
    const rangeTo =
      range.endDate === undefined
        ? ""
        : dayjs(range.endDate).format(dateFormat.datePickerShortDate);

    return `${dayjs(range.startDate).format(
      dateFormat.datePickerShortDate
    )} - ${rangeTo}`;
  }

  return defaultRangeLabel;
}

export function getResourceStatusesCount(
  resourceStates: EventActionFragment["resourceStates"],
  statusesToCheck: ResourceExecutionStatus[]
): number | undefined {
  return resourceStates && isNotEmpty(resourceStates ?? [])
    ? sum(
        statusesToCheck.map(
          (resourceStatus) =>
            resourceStates.filter((state) => state.status === resourceStatus)
              .length
        )
      )
    : undefined;
}

export function getTotalResourceCount(
  resourceGroups: EventActionFragment["resourceGroups"]
): number | undefined {
  return isNotEmpty(resourceGroups ?? [])
    ? sum(
        resourceGroups?.map((rg) => {
          return rg?.totalNumberOfResources ?? 0;
        })
      )
    : undefined;
}

function sum(arr: number[] | undefined): number | undefined {
  return arr?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
}

export const addOpacityToHexColor = (hex: string, opacity: number): string =>
  `rgba(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(
    hex.slice(3, 5),
    16
  )}, ${parseInt(hex.slice(5, 7), 16)}, ${opacity})`;
