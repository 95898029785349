/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CronExpression } from "cron-parser";
import dayjs from "dayjs";

export const removePastDates = (dates: string[]) =>
  dates.filter((date) => dayjs(date).isAfter(dayjs()));

export function getNextDate(interval: CronExpression, format: string) {
  return dayjs(interval.next().toString()).format(format);
}
