/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

type Params = {
  isData: boolean;
  loading: boolean;
  isError: boolean;
};

export function downloadButtonState({
  isData,
  loading,
  isError,
}: Params): string {
  if (loading) {
    return "loading";
  }
  if (isData) {
    return "success";
  }
  if (isError) {
    return "error";
  }
  return "download";
}

type ColSizes = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
};
export function getActionSettingsColGridSizes(
  isResourceScope: boolean
): ColSizes {
  if (isResourceScope) {
    return { xs: 4, sm: 4, md: 4, lg: 4 };
  }
  return { xs: 8, sm: 8, md: 12, lg: 12 };
}
