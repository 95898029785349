/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useReducer } from "react";
import { Else, If, Then, When } from "react-if";
import { v4 } from "uuid";
import {
  Button,
  FlexContainer,
  MultipleSelect,
  SelectButton,
  Sidebar,
  theme,
} from "@nordcloud/gnui";
import { PlanActionType } from "~/generated/graphql";
import { LoaderWrap } from "~/components/Loader";
import { PlanField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { useStepState } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/StepProvider";
import { SelectedAction } from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";
import { ActionItem } from "../ActionItem";
import { AddActionBatchSidebarFooter, ActionBatchModal } from "./components";
import { actionsType, defaultState } from "./constant";
import { createNewAction } from "./helpers";
import { ExecutionMethod, State } from "./types";
import { useGetAllActions } from "./useGetAllActions";

type Props = {
  buttonSeverity?: "low";
  executionMethod: ExecutionMethod;
};

export function AddActionBatch({ buttonSeverity, executionMethod }: Props) {
  const [state, updateState] = useReducer(
    (data: State, partialData: Partial<State>) => ({
      ...data,
      ...partialData,
    }),
    defaultState
  );

  const { stepState, updateStepState } = useStepState();
  const { setPlanData, setOrderOfSelectedAction } = usePlanWizard();

  const { loading, customActions, systemActions } = useGetAllActions();

  const selectActions = (action: SelectedAction) => {
    const selectedActions = state.selectedActions.map(({ id }) => id);
    const actionId = action.id;
    if (selectedActions.includes(actionId)) {
      updateState({
        selectedActions: state.selectedActions.filter(
          ({ id }) => id !== actionId
        ),
      });
    } else {
      updateState({ selectedActions: [...state.selectedActions, action] });
    }
  };

  const applyActions = (actions: SelectedAction[], batchName?: string) => {
    const newActions = actions.map((actionItem) => createNewAction(actionItem));

    if (executionMethod === ExecutionMethod.batch) {
      const batchId = v4();
      const newActionBatch = newActions.map((action) => ({
        ...action,
        batchName: batchName,
        batchId: batchId,
      }));
      updateStepState({
        selectedAction: newActions[newActions.length - 1],
        actionBatch: [...(stepState.actionBatch ?? []), ...newActionBatch],
      });
    }

    if (executionMethod === ExecutionMethod.single) {
      updateStepState({
        selectedAction: newActions[newActions.length - 1],
        actions: [...(stepState.actions ?? []), ...newActions],
      });
    }

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(stepState.actions ?? []), ...newActions],
      },
    }));

    setOrderOfSelectedAction?.((prevState) =>
      stepState.actions?.length === 0 ? 0 : prevState + 1
    );

    updateState({ selectedActions: [], isSideBarOpen: false });
  };

  const applyActionBatch = () => {
    if (executionMethod === ExecutionMethod.batch) {
      updateState({ isModalOpen: true });
    }
  };

  const actionsList = (actions: SelectedAction[]) =>
    actions?.map((action) => {
      const actionItem = {
        id: action.id ?? "",
        name: action.name,
        description: action.description ?? "",
        color: action.color,
        actionsType: action.actionType,
      };

      return (
        <ActionItem
          key={action.id}
          item={actionItem}
          isCheckbox
          isSelected={state.selectedActions
            .map(({ id }) => id)
            .includes(action.id ?? "")}
          onMouseDown={() => selectActions(action)}
        />
      );
    });

  return (
    <>
      <Button
        onClick={() => updateState({ isSideBarOpen: true })}
        mt={theme.spacing.spacing01}
        severity={buttonSeverity}
      >
        <When condition={executionMethod === ExecutionMethod.single}>
          Add Actions
        </When>
        <When condition={executionMethod === ExecutionMethod.batch}>
          Add Action Batch
        </When>
      </Button>
      <Sidebar
        title="Select Actions within the Batch"
        isOpen={state.isSideBarOpen}
        onClick={() => updateState({ isSideBarOpen: false })}
        footer={
          <AddActionBatchSidebarFooter
            state={state}
            clear={() => updateState({ selectedActions: [] })}
            onSubmit={
              executionMethod === ExecutionMethod.batch
                ? applyActionBatch
                : applyActions
            }
          />
        }
      >
        <LoaderWrap inContent loading={loading}>
          <FlexContainer mb={theme.spacing.spacing05}>
            <MultipleSelect size="small">
              {actionsType.map((action) => (
                <SelectButton
                  key={action.value}
                  name={action.value}
                  value={action.value}
                  labelText={action.label}
                  onClick={() => updateState({ actionType: action.value })}
                  isActive={state.actionType === action.value}
                />
              ))}
            </MultipleSelect>
          </FlexContainer>
          <If condition={state.actionType === PlanActionType.Custom}>
            <Then>{actionsList(customActions)}</Then>
            <Else>{actionsList(systemActions)}</Else>
          </If>
        </LoaderWrap>
      </Sidebar>
      <When condition={executionMethod === ExecutionMethod.batch}>
        <ActionBatchModal {...{ state, updateState, applyActions }} />
      </When>
    </>
  );
}
