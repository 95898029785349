/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Input, Label, Spacer, theme } from "@nordcloud/gnui";
import { FormGroup, stringRequired } from "~/components/Forms";
import { useUpdateCloudAccount } from "~/views/accounts/hooks";
import { CloudAccountFields } from "~/views/accounts/hooks/useUpdateCloudAccount/types";

export const schema = z.object({
  externalId: stringRequired("External ID"),
  arn: stringRequired("Role ARN"),
});

export function AwsForm() {
  const { handleSubmit, register, formState, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      externalId: "",
      arn: "",
    },
  });

  const [updateCloudAccount, loading] = useUpdateCloudAccount({
    onSuccess: () => {
      close();
      reset();
    },
  });

  const onSubmit = (data: CloudAccountFields) => updateCloudAccount(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup error={formState.errors["externalId"]}>
        <Label name="Provide Role external ID" htmlFor="externalId" />
        <Input
          id="externalId"
          placeholder="Provide External ID"
          {...register("externalId")}
        />
      </FormGroup>
      <FormGroup error={formState.errors["arn"]}>
        <Label name="Provide Role ARN" htmlFor="arn" required />
        <Input
          id="arn"
          placeholder="e.g. arn:aws:iam::123456789012:role/NordcloudMCServiceRole"
          {...register("arn")}
        />
      </FormGroup>
      <Spacer height={theme.spacing.spacing02} />
      <Button initialState={loading ? "loading" : "success"}>Apply</Button>
    </form>
  );
}
