/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import {
  EventActionAttempt,
  EventActionAttemptReport,
  EventActionResourceReport,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { isNonEmpty } from "~/tools";
import { useGetEventActionReports } from "~/views/events/hooks/";
import { useReportState } from "./ReportProvider";
import { FilterType } from "./types";

type Props = {
  eventId: string;
  eventActionId: string;
  attempt?: EventActionAttempt;
};

export type ExtendedEventActionResource = EventActionResourceReport & {
  installed?: string[];
  available?: string[];
  removed?: string[];
  updated?: string[];
  failed?: string[];
};

type QueryFilterFields = (keyof ExtendedEventActionResource)[];

const queryFilterFields: QueryFilterFields = [
  "resourceId",
  "name",
  "installed",
  "available",
  "removed",
  "updated",
  "failed",
];

const filterByQuery = (fields: QueryFilterFields, query?: string) => {
  if (!query) {
    return () => true;
  }

  return (resource: ExtendedEventActionResource) =>
    fields.some((field) => {
      return String(resource[field]?.toString().toLowerCase())?.includes(query);
    });
};

const filterByStatus = (status?: FilterType) => {
  if (!status?.length) {
    return () => true;
  }

  switch (status) {
    case FilterType.ALL:
      return () => true;

    case FilterType.INSTALLED:
      return (resource: ExtendedEventActionResource) =>
        isNonEmpty(resource?.installed ?? []);

    case FilterType.UPDATED:
      return (resource: ExtendedEventActionResource) =>
        isNonEmpty(resource?.updated ?? []);

    case FilterType.AVAILABLE:
      return (resource: ExtendedEventActionResource) =>
        isNonEmpty(resource?.available ?? []);

    case FilterType.REMOVED:
      return (resource: ExtendedEventActionResource) =>
        isNonEmpty(resource?.removed ?? []);

    case FilterType.FAILED:
      return (resource: ExtendedEventActionResource) =>
        isNonEmpty(resource?.failed ?? []);

    default:
      return () => true;
  }
};

type FilterReportResourcesArgs = {
  resources: EventActionResourceReport[];
  filter: FilterType;
  query?: string;
  attempt?: number;
};

const filterReportDataByAttempt = (
  data?: EventActionAttemptReport[],
  attemptValue?: EventActionAttempt
) => {
  if (attemptValue?.attempt === undefined) {
    return data;
  }
  return data?.filter((item) => item?.attempt === attemptValue?.attempt);
};

export function useGetFilteredReport({
  eventId,
  eventActionId,
  attempt,
}: Props) {
  const {
    data: reportData,
    loading,
    error,
  } = useGetEventActionReports({
    eventId,
    eventActionId,
  });
  const {
    reportState: { filterQuery, searchQuery },
    updateReportState,
  } = useReportState();

  useEffect(() => {
    const filtered = filterReportDataByAttempt(reportData, attempt);

    if (filtered) {
      const resources: EventActionResourceReport[] = filtered
        ?.map((item) => item.report.resources)
        .flat()
        .map((item) => {
          return {
            ...item,
            name: item?.name ?? "",
            resourceId: item?.resourceId ?? "",
            status: item?.status ?? ResourceExecutionStatus.Error,
            result: item?.result ?? {
              output: "",
              outputUri: "",
              installed: [],
              available: [],
              removed: [],
              updated: [],
              failed: [],
            },
          };
        });
      updateReportState({ resources: resources });
    }
  }, [attempt, reportData, updateReportState]);

  function filterReportResources({
    resources,
    filter,
    query,
  }: FilterReportResourcesArgs) {
    return resources
      .filter(filterByStatus(filter))
      .filter(filterByQuery(queryFilterFields, query));
  }

  const filteredData = filterReportDataByAttempt(reportData, attempt);

  const flatResults: ExtendedEventActionResource[] =
    filteredData
      ?.map((item) => item.report.resources)
      .flat()
      .map((item) => {
        return {
          ...item,
          name: item?.name ?? "",
          resourceId: item?.resourceId ?? "",
          status: item?.status ?? ResourceExecutionStatus.Error,
          result: item?.result ?? {
            output: "",
            outputUri: "",
            installed: [],
            available: [],
            removed: [],
            updated: [],
            failed: [],
          },
          installed: item?.result.installed?.map((i) => i.name),
          available: item?.result.available?.map((i) => i.name),
          removed: item?.result.removed?.map((i) => i.name),
          updated: item?.result.updated?.map((i) => i.name),
          failed: item?.result.failed?.map((i) => i.name),
        };
      }) ?? [];

  const reportResources = filterReportResources({
    resources: flatResults,
    filter: filterQuery,
    query: searchQuery,
    attempt: attempt?.attempt,
  });

  return { reportResources, loading, error };
}
