/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { BrickLoader } from "@nordcloud/gnui";
import { ErrorWrap, BreadcrumbsBox, LoaderWrap } from "~/components";
import { NoData } from "~/components/NoData";
import { ROUTES } from "~/routing/routes";
import { RevokeIncomingEventMessage } from "../components/RevokeIncomingEvent/RevokeIncomingEventMessage";
import { RefetchOptions } from "../helpers";
import { useGetIncomingEvent } from "../hooks/useGetIncomingEvent/useGetIncomingEvent";
import { EventInfo } from "./components";
import { EventActions } from "./components/EventActions";
import { EventCtxProvider } from "./EventProvider";
import { IncomingEventDetailsTab } from "./Tabs/Details/IncomingEventDetailsTab";
import { Tabs } from "./Tabs/Tabs";

type Props = {
  eventId: string;
};

export function IncomingEventDetails({ eventId }: Props) {
  const { event, loading, error } = useGetIncomingEvent({
    eventId,
  });

  const title = event?.name ?? eventId;

  const breadcrumbs = [
    { label: "Home", uri: "/" },
    { label: "Events", uri: ROUTES.events.index },
    { label: title, uri: "" },
  ];

  return (
    <ErrorWrap
      error={error}
      Component={() => <NoData hasIcon message={error?.message} />}
    >
      <EventCtxProvider value={{ event }}>
        <LoaderWrap
          Component={() => <BrickLoader height="14rem" />}
          loading={loading}
          viewBox="0 0 10 4"
        >
          <Row>
            <Col>
              <BreadcrumbsBox title={title} customBreadcrumbs={breadcrumbs}>
                <EventActions
                  eventId={event?.id ?? ""}
                  status={event?.status}
                  size="large"
                  refetchQueries={RefetchOptions.INCOMING_EVENT}
                />
              </BreadcrumbsBox>
            </Col>
          </Row>
          <RevokeIncomingEventMessage />
          <Row>
            <Col>
              <EventInfo />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                detailsTab={<IncomingEventDetailsTab status={event?.status} />}
              />
            </Col>
          </Row>
        </LoaderWrap>
      </EventCtxProvider>
    </ErrorWrap>
  );
}
