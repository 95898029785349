/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Unless, When } from "react-if";
import styled from "styled-components";
import { Button, FlexContainer, Spacer, Text, theme } from "@nordcloud/gnui";

type Props = {
  isBatch?: boolean;
  name?: string;
  children: React.ReactNode;
};

export function ActionWrap({ isBatch, name, children }: Props) {
  return (
    <>
      <When condition={isBatch}>
        <ActionBatch active>
          <ActionBatchHeader alignItems="center" gap={theme.spacing.spacing02}>
            <Text weight="medium" css={{ marginRight: "auto" }}>
              {name}
            </Text>
            <Button icon="trash" severity="low" />
            <Button icon="plus" severity="low" />
          </ActionBatchHeader>
          {children}
        </ActionBatch>
      </When>
      <Unless condition={isBatch}>{children}</Unless>
      <Spacer height={theme.spacing.spacing02} />
    </>
  );
}

type ActionWrapProps = {
  active?: boolean;
};

const ActionBatch = styled.div<ActionWrapProps>`
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing02}
    ${theme.spacing.spacing01};
  margin-left: ${theme.spacing.spacing05};
  margin-right: ${theme.spacing.spacing03};
  border: 1px solid ${theme.color.border.border01};
  background-color: ${({ active }) =>
    active ? theme.color.background.ui04 : theme.color.background.ui03};
  width: calc(100% - ${theme.spacing.spacing05});
  border-radius: ${theme.radius.md};
  transition: ${theme.transition};

  &:hover {
    background-color: ${theme.color.background.ui04};
    border-color: ${theme.color.background.ui05};
  }
`;
const ActionBatchHeader = styled(FlexContainer)`
  padding: ${theme.spacing.spacing02} ${theme.spacing.spacing04};
`;
