/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { Else, If, Then } from "react-if";
import styled from "styled-components";
import { Box, Button, FlexContainer, theme } from "@nordcloud/gnui";
import { Group } from "~/generated/graphql";
import { NoData } from "~/components";
import { ResourceGroupItem } from "~/components/resourceGroup/ResourceGroupItem";
import { isNotEmpty, isNotNil } from "~/tools";
import { useGetAllResourceGroups } from "~/views/resourceGroups/hooks";
import { PlanField } from "../../constants";
import { usePlanWizard } from "../../PlanProvider";
import { useStepState } from "../StepProvider";
import { AddResourceGroup } from "./AddResourceGroup";

export function ActionResourceGroups() {
  const { stepState, updateStepState } = useStepState();
  const { setPlanData } = usePlanWizard();

  const { getGroups, data, refetch } = useGetAllResourceGroups();

  const resourceGroups = stepState.selectedAction?.resourceGroupIds
    ?.map((id) => data.find((group) => group.id === id))
    .filter(Boolean);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleRemove = (id: string) => {
    const action = {
      ...stepState.selectedAction,
      resourceGroupIds: stepState.selectedAction?.resourceGroupIds?.filter(
        (groupId) => id !== groupId
      ),
    };

    const actions = stepState.actions?.map((act) => {
      if (act.listId === stepState.selectedAction?.listId) {
        return action;
      } else {
        return act;
      }
    });

    updateStepState({
      selectedAction: action,
      actions,
    });

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: actions,
      },
    }));
  };

  return (
    <FlexContainer css={{ flex: 1 }} direction="column">
      <Box mb={theme.spacing.spacing04}>
        <AddResourceGroup refetch={refetch} />
      </Box>
      <If
        condition={isNotEmpty(stepState.selectedAction?.resourceGroupIds ?? [])}
      >
        <Then>
          <Wrap>
            {resourceGroups?.filter(isNotNil).map((resourceGroup) => {
              return (
                <ResourceGroupItem
                  key={resourceGroup.id}
                  resourceGroup={resourceGroup as Group}
                  actions={() => (
                    <Button
                      severity="low"
                      size="md"
                      icon="trash"
                      onClick={() => handleRemove(resourceGroup.id)}
                    />
                  )}
                />
              );
            })}
          </Wrap>
        </Then>
        <Else>
          <NoData
            hasIcon
            customCss={{ flexDirection: "column" }}
            message={
              <FlexContainer
                justifyContent="center"
                direction="column"
                css={{ textAlign: "center" }}
              >
                There are no resource groups in the action. <br />
                Please add resource groups.
              </FlexContainer>
            }
          />
        </Else>
      </If>
    </FlexContainer>
  );
}

const Wrap = styled.div`
  width: 100%;
  & > :not(:first-child) {
    margin-top: ${theme.spacing.spacing04};
  }
`;
