/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { Box, ExtendedTooltip, Spacer, Text, theme } from "@nordcloud/gnui";
import { TooltipText } from "~/components";
import { dateFormat } from "~/constants";
import { convertMinutesToTime } from "~/views/events/helpers";

type Props = {
  timeDetails: {
    scheduleTimezone?: string;
    startTime?: string;
    estimatedEndTime?: string;
  };
};

export function TimeDetails({ timeDetails }: Props) {
  const startTimeTZ = dayjs
    .tz(timeDetails?.startTime ?? "", timeDetails?.scheduleTimezone ?? "")
    .format(dateFormat.dayMonthYearHour);

  const endTimeTZ = dayjs
    .tz(
      timeDetails?.estimatedEndTime ?? "",
      timeDetails?.scheduleTimezone ?? ""
    )
    .format(dateFormat.dayMonthYearHour);

  const duration = dayjs(timeDetails?.estimatedEndTime).diff(
    timeDetails?.startTime,
    "minutes"
  );

  return (
    <Box boxStyle="lightGrey" css={{ flex: 1 }}>
      <div data-testid="event-start-time">
        <Text tag="div" size="sm" color={theme.color.text.text02}>
          Start Time
        </Text>
        <ExtendedTooltip
          caption={
            <>
              Event Time: {startTimeTZ}
              <br />
              Timezone: {timeDetails?.scheduleTimezone}
            </>
          }
          hideTimeout={0}
        >
          <TooltipText
            size="md"
            tag="span"
            mr={theme.spacing.spacing01}
            weight="regular"
            color={theme.color.text.text01}
            css={{ textDecorationStyle: "initial" }}
          >
            {dayjs(timeDetails?.startTime).format(dateFormat.dayMonthYearHour)}
          </TooltipText>
        </ExtendedTooltip>
      </div>
      <Spacer height={theme.spacing.spacing04} />
      <div data-testid="event-end-time">
        <Text tag="div" size="sm" color={theme.color.text.text02}>
          End Time
        </Text>
        <ExtendedTooltip
          caption={
            <>
              Event Time: {endTimeTZ}
              <br />
              Timezone: {timeDetails?.scheduleTimezone}
            </>
          }
          hideTimeout={0}
        >
          <TooltipText
            size="md"
            tag="span"
            mr={theme.spacing.spacing01}
            weight="regular"
            color={theme.color.text.text01}
            css={{ textDecorationStyle: "initial" }}
          >
            {dayjs(timeDetails?.estimatedEndTime).format(
              dateFormat.dayMonthYearHour
            )}
          </TooltipText>
        </ExtendedTooltip>
      </div>
      <Spacer height={theme.spacing.spacing04} />
      <div data-testid="event-duration">
        <Text tag="div" size="sm" color={theme.color.text.text02}>
          Event Duration
        </Text>
        <Text tag="div">{convertMinutesToTime(duration)}</Text>
      </div>
    </Box>
  );
}
