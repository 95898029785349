/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { getDroppableCss } from "~/components/Styled";
import { reorder, useComponentsSelector } from "~/hooks";
import { isEmpty } from "~/tools";
import { Item } from "~/types";
import { PlanField } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import { usePlanWizard } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanProvider";
import { useStepState } from "~/views/plans/PlanCreate/components/PlanCreateWizard/PlanSettingsForms/StepProvider";
import { DraggableActionList } from "./DraggableActionsList";

type Props = {
  actions?: Item[];
  nextStep?: () => void;
  actionBatch?: boolean;
};

export function DraggableActions({ actions, nextStep, actionBatch }: Props) {
  const { items, handleDragEnd, handleDelete, handleShowSearch } =
    useComponentsSelector([]);

  const { updateStepState } = useStepState();
  const { setPlanData, setOrderOfSelectedAction } = usePlanWizard();

  useEffect(() => {
    if (actions) {
      handleShowSearch(actions);
    }
  }, [actions, handleShowSearch]);

  const handleDrag = (result: DropResult) => {
    handleDragEnd(result);

    const newActions = reorder(
      actions ?? [],
      result.source.index,
      result.destination?.index ?? -1
    );
    if (actionBatch) {
      updateStepState({
        actionBatch: newActions,
      });
    } else {
      updateStepState({
        actions: newActions,
      });
    }

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(newActions ?? [])],
      },
    }));
    if (result.destination?.index !== undefined) {
      setOrderOfSelectedAction?.(result.destination?.index);
    }
  };

  const handleDeleteActions = (item: Item) => {
    const filteredActions = actions?.filter(
      (action) => action.listId !== item.listId
    );
    if (actionBatch) {
      updateStepState({
        actionBatch: filteredActions,
      });
    } else {
      updateStepState({
        actions: filteredActions,
      });
    }

    setPlanData((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.PLAN_SETTINGS]: {
        planActions: [...(filteredActions ?? [])],
      },
    }));
    handleDelete(item);
  };

  if (isEmpty(items.SELECTED_ITEMS)) {
    return null;
  }

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable droppableId={"SELECTED_ITEMS"}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            css={getDroppableCss(true, snapshot.isDraggingOver)}
          >
            <DraggableActionList
              items={items["SELECTED_ITEMS"]}
              onDelete={handleDeleteActions}
              nextStep={nextStep}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
