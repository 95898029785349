/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import styled from "styled-components";
import { SVGIcon, theme } from "@nordcloud/gnui";

export const ExpandIcon = styled(SVGIcon)<{ isExpanded: boolean }>`
  display: flex;
  margin: ${theme.spacing.spacing03};
  cursor: pointer;
  transition: transform 0.1s linear;
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(0deg)" : "rotate(-90deg)"};
  flex-shrink: 0;
`;
