/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useState } from "react";
import { Tab, Tabs } from "@nordcloud/gnui";
import { EventAction } from "~/generated/graphql";
import { TabIcon } from "~/views/plans/PlanDetails/Tabs/DetailsTab/components/ActionBox/components/TabIcon";
import { ActionDetails } from "./ActionDetails";
import { GeneralActionSettings } from "./GeneralActionSettings";

type Props = {
  eventAction?: EventAction;
  planId?: string;
};

export function ActionSettingsSection({ eventAction, planId }: Props) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs step={tab} handleTab={setTab}>
        <Tab
          label={
            <span>
              <TabIcon caption="Action Details" icon="eventLog" />
            </span>
          }
          width="auto"
        >
          <ActionDetails eventAction={eventAction} />
        </Tab>
        <Tab
          label={
            <span>
              <TabIcon caption="General Action Settings" icon="settings" />
            </span>
          }
          width="auto"
        >
          <GeneralActionSettings eventAction={eventAction} planId={planId} />
        </Tab>
      </Tabs>
    </>
  );
}
