/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Col, Row } from "react-awesome-styled-grid";
import { When } from "react-if";
import { Box, BrickLoader, theme } from "@nordcloud/gnui";
import {
  EventAction,
  EventActionAttempt,
  EventActionAttemptResourceStatesFragment,
  EventActionResourceState,
  EventActionResourceStatesFragment,
  EventActionScope,
} from "~/generated/graphql";
import { LoaderWrap } from "~/components";
import { EventResourceGroupSection } from "~/components/resourceGroup/EventResourceGroupSection";
import { isNotEmpty } from "~/tools";
import { ResourceGroupsStates } from "~/views/events/EventsDetails/types";
import { getActionSettingsColGridSizes } from "../utils";
import { ActionSettingsSection } from "./components/ActionSettingsSection";
import { EventResourceHeader } from "./components/EventResourceHeader";

type Props = {
  eventAction: EventAction;
  resourceStates?:
    | EventActionResourceStatesFragment["resourceStates"]
    | EventActionAttemptResourceStatesFragment["resourceStates"];
  planId?: string;
  attempt?: EventActionAttempt;
};

export function EventActionBox({
  eventAction,
  resourceStates,
  planId,
  attempt,
}: Props) {
  const states = resourceStates?.reduce(
    (result: ResourceGroupsStates, state: EventActionResourceState) => {
      const rgId = state.resourceGroupId;
      return {
        ...result,
        [rgId]: result[rgId] ? [...result[rgId], state] : [state],
      };
    },
    {}
  );

  const isResourceScope = eventAction.scope === EventActionScope.Resource;
  const settingsCol = getActionSettingsColGridSizes(isResourceScope);

  return (
    <Row>
      <When condition={isResourceScope}>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={8}
          css={{ paddingRight: theme.spacing.spacing01 }}
        >
          <Box boxStyle="grey" innerSpacing="spacing00">
            <LoaderWrap
              loading={states === undefined}
              Component={() => <BrickLoader height="28rem" />}
            >
              <When condition={isNotEmpty(resourceStates ?? [])}>
                <EventResourceHeader
                  eventAction={eventAction}
                  attempt={attempt}
                />
              </When>
              <EventResourceGroupSection
                states={states}
                eventAction={eventAction}
              />
            </LoaderWrap>
          </Box>
        </Col>
      </When>
      <Col
        xs={settingsCol.xs}
        sm={settingsCol.sm}
        md={settingsCol.md}
        lg={settingsCol.lg}
      >
        <Box innerSpacing="spacing00" css={{ height: "100%" }}>
          <ActionSettingsSection eventAction={eventAction} {...{ planId }} />
        </Box>
      </Col>
    </Row>
  );
}
