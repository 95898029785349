/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Else, If, Then, When } from "react-if";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  ExtendedTooltip,
  FlexContainer,
  SVGIcon,
  Table,
  Text,
  theme,
} from "@nordcloud/gnui";
import {
  EventActionResourceState,
  ResourceExecutionStatus,
} from "~/generated/graphql";
import { ROUTES } from "~/routing/routes";
import { getFirstItem, isNotNil } from "~/tools";
import { EventResourceStateItem } from "./EventResourceStateItem";
import { getResourceStatus } from "./utils";

type Props = {
  states: EventActionResourceState[];
  resourceGroupStatuses?: ResourceExecutionStatus[];
  eventActionEndTime?: string;
  eventId: string;
};

export function EventResourceGroupItem({
  states,
  resourceGroupStatuses,
  eventActionEndTime,
  eventId,
}: Props) {
  const resourceGroupId =
    getFirstItem(states).resourceGroup?.id ??
    getFirstItem(states).resourceGroupId;

  const resourceGroupName = getFirstItem(states).resourceGroup?.name ?? "";

  return (
    <Box innerSpacing="spacing00">
      <StyledBox padding={theme.spacing.spacing03}>
        <FlexContainer css={{ width: "100%" }}>
          <ExtendedTooltip
            caption={
              <>
                {getResourceStatus(resourceGroupStatuses).description}
                <When condition={isNotNil(eventActionEndTime)}>
                  <div>{eventActionEndTime}</div>
                </When>
              </>
            }
            status={getResourceStatus(resourceGroupStatuses).tooltipColor}
            position="start"
          >
            <SVGIcon
              name={getResourceStatus(resourceGroupStatuses).icon}
              color={getResourceStatus(resourceGroupStatuses).color()}
              css={{ minWidth: theme.spacing.spacing04 }}
            />
          </ExtendedTooltip>
          <Text tag="div" weight="medium" ml={theme.spacing.spacing03}>
            <If condition={isNotNil(getFirstItem(states).resourceGroup)}>
              <Then>
                <Link
                  to={`${ROUTES.resourceGroups.generateDetails(
                    resourceGroupId
                  )}`}
                >
                  {resourceGroupName}
                </Link>
                <Text tag="div" size="sm" color={theme.color.text.text02}>
                  {resourceGroupId}
                </Text>
              </Then>
              <Else>
                <ExtendedTooltip
                  caption={"Resource group has been deleted"}
                  position="start"
                >
                  <Text size="md" color={theme.color.text.text02}>
                    {resourceGroupId}
                  </Text>
                </ExtendedTooltip>
              </Else>
            </If>
          </Text>
        </FlexContainer>
      </StyledBox>
      <Table>
        <Table.tbody>
          {states?.map((state) => (
            <EventResourceStateItem
              key={state.id}
              state={state}
              eventId={eventId}
            />
          ))}
        </Table.tbody>
      </Table>
    </Box>
  );
}

const StyledBox = styled(Box)`
  border-bottom: 2px solid ${theme.color.border.border01};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;
