/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { v4 } from "uuid";
import { ActionParameterDefinition, KeyValue } from "~/generated/graphql";
import { defaultActionSettingsValues } from "~/views/plans/PlanCreate/components/PlanCreateWizard/constants";
import {
  FormField,
  SelectedAction,
} from "~/views/plans/PlanCreate/components/PlanCreateWizard/types";

export function extractInputParameters(
  parameterDefinitions?: ActionParameterDefinition[]
): KeyValue[] {
  const parametersWithDefaultValue =
    parameterDefinitions?.filter((param) => param.defaultValue) ?? [];

  const keyValuePairs = parametersWithDefaultValue.map((param) => ({
    key: param.name,
    value: param.defaultValue ?? "",
  }));

  return keyValuePairs.filter((param) => param.value.length !== 0);
}

export const createNewAction = (actionItem: SelectedAction) => {
  const inputParameters = extractInputParameters(
    actionItem?.parameterDefinitions
  );
  const actionSettingsValues = {
    ...defaultActionSettingsValues,
    [FormField.INPUT_PARAMETERS]: inputParameters,
  };
  return {
    ...actionSettingsValues,
    ...actionItem,
    listId: v4(),
    actionName: actionItem.name,
  };
};
