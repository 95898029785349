/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { FieldErrors } from "react-hook-form";
import { When } from "react-if";
import styled from "styled-components";
import { FlexContainer, Text, theme } from "@nordcloud/gnui";

export const Wrap = styled(FlexContainer)`
  position: relative;
  width: 100%;
  margin-bottom: ${theme.spacing.spacing05};
  gap: ${theme.spacing.spacing04};
  > div {
    width: 100%;
  }
`;
const TextWrap = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(83%);
`;

type Props = {
  error?: FieldErrors;
};

export function ErrorText({ error }: Props) {
  return (
    <When condition={error !== undefined}>
      <TextWrap>
        <Text size="sm" color="danger" tag="span">
          {error?.message?.toString()}
        </Text>
      </TextWrap>
    </When>
  );
}
